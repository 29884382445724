import React, { useEffect, useState } from 'react'
import Close_indow from "../images/Close_indow.png"
import Close from "../images/close_01.svg"
import User_Locked from "../images/user_login.svg"
import User from "../images/User.png"
import Lock from "../images/Lock.png"
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import axios from 'axios'
import { BASE_HREF, BASE_URL, lan } from '../config'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getPostData, getUserData, setCurrentProduct } from '../Redux/ActionCreater'
import Register from './Register'
import ForgetPass from './ForgetPass'

function Login() {
	const [state, setState] = useState([])
	const [cities, setCities] = useState([])
	const [data, setData] = useState("");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		user: "",
		pass: ""
	})
	const { userData } = useSelector(
		(state) => ({
			userData: state.dataReducer.userData,

		}),
		shallowEqual
	);
	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log(formData);
		// let regExp=/^[a-zA-Z0-9.-_]+@[a-zA-Z0-9.-_]+\.[A-Za-z]+$/;
		if (formData.user == "" || !/^[a-z0-9_\-]+$/.test(formData.user)) {
			return toast.error(lan.username_error)
		}
		if (formData.pass === "") {
			return toast.error(lan.pass_error)
		}
		await axios({
			url: BASE_URL + "/api/user/login",
			method: "POST",
			data: {
				"username": formData.user,
				"password": formData.pass
			},
		}).then(res => {
			if (res.data.status === 200) {
				res.data.data.sellerIcon=res.data.sellerIcon
				localStorage.setItem('login_data', JSON.stringify({...res.data.data,activeCredit:res.data.activeCredit}));
				localStorage.setItem('access_token', res.data.token);
				// setUserData(res.data.data)
				dispatch(getUserData({...res.data.data,activeCredit:res.data.activeCredit}));
				dispatch(setCurrentProduct(""));
				toast.success("Uspješno ste se prijavili!");
				$(".btnClose").click()
				dispatch(getPostData({preData:[]}));
			} else {
				toast.error("Pogrešno korisničko ime ili lozinka!");
			}
		})
	}
	// let fetchData=async()=>{
	// 	try {
	// 		await axios({
	// 			url: BASE_URL + "/api/vehicle/post/list",
	// 			method: "POST",
	// 			data:{
	// 				"loggedin_user_id":JSON.parse(localStorage.getItem("login_data"))?._id
	// 			}
	// 		  }).then(res=>{
	// 			if(res.data.status===200){
	// 				setProducts(res.data.data)
	// 			}else{
	// 				toast.error(res.data.message);
	// 			}
	// 		  })
	// 	} catch (error) {
	// 		console.log(error)
	// 	}
	// }
	function toggleModal() {
		var modal = document.querySelector(".modalRegister");
		modal.classList.toggle("show-modal");
	}
	const fetchState = async () => {
		try {
			await axios({
				method: "get",
				url: `${BASE_URL}/api/state/list`
			}).then(res => {
				if (res.data.status === 200) {
					setState(res.data.data)
				}
			})
		} catch (error) {
			console.log(error)
		}
	}
	const fetchCities = async () => {
		try {
			await axios({
				method: "get",
				url: `${BASE_URL}/api/city/list`
			}).then(res => {
				if (res.data.status === 200) {
					setCities(res.data.data)
				}
			})
		} catch (error) {
			console.log(error)
		}
	}
	function windowOnClick(event) {
		var modalThree = document.querySelector(".fogotPassModal");
		if (event.target === modalThree) {
			toggleModalPass();
		}
	}

	function toggleModalPass() {
		var modalThree = document.querySelector(".demmoModal");
		modalThree.classList.toggle("show-forgot");
	}
	window.addEventListener("click", windowOnClick);

	useEffect(() => {
		$(".selerDelrTopbtm li button, .fogotPassModalLink").click(function () {
			$("body").addClass("workingFrm");
		});

		$(".closeButtonTwo, .closeButtonfogot").click(function () {
			$("body").removeClass("workingFrm");
		});
	  },[])
  return (
	<>
	
    <div class="modal dashbordModal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close">
					<img src={Close_indow} alt="" />
					<img src={Close} alt="" />
				</button>
			</div>
			<div class="modal-body">
				<div class="usermodal">
								<form onSubmit={handleSubmit}>
									<div class="usermodaltop">
										<div class="userLoked">
											<img src={User_Locked} alt="" />
										</div>
										<div class="frmData">
											<i><img src={User} alt="" /></i>
											<input onFocus={(e)=>{e.target.placeholder=""}} onBlur={(e)=>{if(e.target.value==="")e.target.placeholder=lan.username}} name="user" onChange={onChange} value={formData.user} type="text" placeholder={lan.username} />
										</div>
										<div class="frmData">
											<i><img src={Lock} alt="" /></i>
											<input onFocus={(e)=>{e.target.placeholder=""}} onBlur={(e)=>{if(e.target.value==="")e.target.placeholder=lan.password}} name="pass" onChange={onChange} value={formData.pass} type="password" placeholder={lan.password} />
										</div>
										<div class="loginBtn">
											<input type="submit" value={lan.login} />
											<em><a href={BASE_HREF} class="fogotPassModalLink" onClick={(e) => { e.preventDefault(); toggleModalPass() }}>{lan.forgot_pass}</a></em>
										</div>
									</div>
									<div class="usermodalbtm">
										<em>{lan.dont_account}</em>
										{/* <figure>
											<img src={Add_User_Male} alt="" />
										</figure> */}
										<a href="" onClick={(e) => { e.preventDefault(); toggleModal(); fetchCities() }} class="registerBtn trigger">{lan.register_login}</a>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ForgetPass toggleModalPass={toggleModalPass} />
			<Register cities={cities} state={state} toggleModal={toggleModal} />

			{/* <ToastContainer style={{ zIndex: "99999" }} /> */}

		</>
	)
}

export default Login