import React from 'react'
import Close_indow from "../../images/Close_indow.png"
import Close from "../../images/close_01.svg"
import srtfltr from "../../images/srtfltr.svg"
import $ from "jquery";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getPostData, setSortField, setSortValue, setloading } from '../../Redux/ActionCreater';
import { lan } from '../../config';

export default function SortSec({searchData}) {
  const { sortField,sortvalue,filters,hasMore} = useSelector(
		(state) => ({
			sortField: state.dataReducer.sortField,
			sortvalue: state.dataReducer.sortvalue,
      hasMore: state.dataReducer.hasMore,
			pageNo: state.dataReducer.pageNo,
			filters: state.dataReducer.filters,
      
		}),
		shallowEqual
	);
  const dispatch = useDispatch();
  const clickHandle=(val="",type="")=>{
		dispatch(setloading(true))
    dispatch(setSortField(val));
    dispatch(setSortValue(type));
    dispatch(getPostData({...filters,pageNo:0,hasMore:hasMore,preData:[],sort_field:val,sort_value:type,searchData:searchData}))
    $("#sortbtn").click();
  }
    return (

       <div className="modal dashbordModal fade" id="sortmodal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <button type="button" id="sortbtn" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
          <img src={Close_indow} alt />
          <img src={Close} alt />
        </button>
      </div>
      <div className="modal-body">
        <div className="blankpnl sortpnl">
          <figure>
            <img src={srtfltr} alt />
          </figure>
          <h4>{lan.sort_by}</h4>
          <div className="allopfiltr">
            <div className="customRadio ">
              <input type="radio" name="power" onClick={()=>{clickHandle()}} defaultValue={3} id="option-1" defaultChecked={sortField===""&&sortvalue===""?true:false} />
              <label htmlFor="option-1" className={sortField===""&&sortvalue===""?"option option-1 checked":"option option-1"}>{lan.random}</label>
            </div><br />
            <div className="customRadio ">
              <input type="radio" name="power" onClick={()=>{clickHandle("price",1)}} defaultValue={3} id="option-2" defaultChecked={sortField==="price"&&sortvalue===1?true:false} />
              <label htmlFor="option-2" className={sortField==="price"&&sortvalue===1?"option option-2 checked":"option option-2"}>{lan.price_l_h}</label>
            </div>
            <div className="customRadio ">
              <input type="radio" name="power" onClick={()=>{clickHandle("price",-1)}} defaultValue={3} id="option-3" defaultChecked={sortField==="price"&&sortvalue===-1?true:false} />
              <label htmlFor="option-3" className={sortField==="price"&&sortvalue===-1?"option option-3 checked":"option option-3"}>{lan.price_h_l}</label>
            </div><br />
            <div className="customRadio ">
              <input type="radio" name="power" onClick={()=>{clickHandle("mileage",1)}} defaultValue={3} id="option-4"  defaultChecked={sortField==="mileage"&&sortvalue===1?true:false}/>
              <label htmlFor="option-4" className={sortField==="mileage"&&sortvalue===1?"option option-4 checked":"option option-4"}>{lan.mileage_l_h}</label>
            </div>
            <div className="customRadio ">
              <input type="radio" name="power" onClick={()=>{clickHandle("mileage",-1)}} defaultValue={3} id="option-5"  defaultChecked={sortField==="mileage"&&sortvalue===-1?true:false}/>
              <label htmlFor="option-5" className={sortField==="mileage"&&sortvalue===-1?"option option-5 checked":"option option-5"}>{lan.mileage_h_l}</label>
            </div><br />
            <div className="customRadio ">
              <input type="radio" name="power" onClick={()=>{clickHandle("year",1)}} defaultValue={3} id="option-6"  defaultChecked={sortField==="year"&&sortvalue===1?true:false}/>
              <label htmlFor="option-6" className={sortField==="year"&&sortvalue===1?"option option-6 checked":"option option-6"}>{lan.year_l_h}</label>
            </div>
            <div className="customRadio ">
              <input type="radio" name="power" onClick={()=>{clickHandle("year",-1)}} defaultValue={3} id="option-7" defaultChecked={sortField==="year"&&sortvalue===-1?true:false} />
              <label htmlFor="option-7" className={sortField==="year"&&sortvalue===-1?"option option-7 checked":"option option-7"}>{lan.year_h_l}</label>
            </div><br />
            <div className="customRadio ">
              <input type="radio" name="power" onClick={()=>{clickHandle("_id",-1)}} defaultValue={3} id="option-8"  defaultChecked={sortField==="_id"&&sortvalue===-1?true:false}/>
              <label htmlFor="option-8" className={sortField==="_id"&&sortvalue===-1?"option option-8 checked":"option option-8"}>{lan.listing_new}</label>
            </div>
            <div className="customRadio ">
              <input type="radio" name="power" onClick={()=>{clickHandle("_id",1)}} defaultValue={3} id="option-9" defaultChecked={sortField==="_id"&&sortvalue===1?true:false} />
              <label htmlFor="option-9" className={sortField==="_id"&&sortvalue===1?"option option-9 checked":"option option-9"}>{lan.listing_old}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        
    )
}
