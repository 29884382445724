import React from 'react'
import ActiveListings from '../Home/ActiveListings'
import Header from '../../partial/Header'
import Sidebar from '../../partial/Sidebar'

function InactiveList() {
  return (
    <>
    <Header page={"inactivelist"} />
    <Sidebar/>
    <ActiveListings status={false} finish={false}/>
    </>
  )
}

export default InactiveList