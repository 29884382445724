import React, { useEffect, useState } from 'react'
import Header from '../../partial/Header'
import Sidebar from '../../partial/Sidebar'
import Ads from './Ads'
import ProductSec from './ProductSec'
import FilterRight from './FilterRight'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import $ from "jquery";
import { setPreProduct } from '../../Redux/ActionCreater'
import { Helmet } from 'react-helmet'
function Home() {
  const [products, setProducts] = useState("");
  // const [mode, setMode] = useState("");
  const dispatch = useDispatch();
  const { mode,currentProduct } = useSelector(
		(state) => ({
			mode: state.dataReducer.mode,
			currentProduct: state.dataReducer.currentProduct,

		}),
		shallowEqual
	);
//   useEffect(() => {
//   console.log(currentProduct)
// if(currentProduct!==""){
//   var ele = document.getElementById(currentProduct); 
//   console.log(ele)  
// window.scrollTo(ele.offsetLeft,ele.offsetTop);
// }
    
//   }, [])

useEffect(()=>{
  $(".modal-backdrop").hide()
  $("body").css("overflow","auto");
  $("body").removeClass("openMenu");
  // dispatch(setPreProduct([]))
},[])
  return (
    <>
  
      <Header page={"home"} />
      <Sidebar />
      {/* <SearchSec/> */}
      
      {mode==""&&<Ads />}
      {mode==""&&<ProductSec />}
      {/* {mode=="active"&&<ActiveListings status={true} finish={false}/>}
      {mode=="inActive"&&<ActiveListings status={false} finish={false}/>}
      {mode=="finish"&&<ActiveListings status={false} finish={true}/>} */}
      <FilterRight />
    </>
  )
}

export default Home