import React from 'react'
import userSmall from "../../images/private_seller_grey.svg"
import grayHeart from "../../images/heart_grey.svg"
import colorHeert from "../../images/heart_y.svg"
import smallDealer from "../../images/car_dealer_grey.svg"
import { BASE_URL, lan } from '../../config';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import yr from "../../images/yr.svg"
import dsl from "../../images/dsl.svg"
import milg from "../../images/milg.svg"
import auto from "../../images/auto.svg"
import lcn from "../../images/lcn.svg"
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setCurrentProduct, setPreProduct } from '../../Redux/ActionCreater';

function SellerListing({ id, data, setSellerPost, sellerPost, setStage }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const options = {
    loop: true,
    margin: 5,
    height: 600,
    items: "auto",
    autoplay: false,
    autoplaySpeed: 1200,
    rewind: false,
    lazyLoad: true,
    // navRewind: true,
    autoplayTimeout: 6000,
    navSpeed: 2000,
    dragEndSpeed: 2000,
    dots: false,
    nav: false
  };

  const { preProduct } = useSelector(
    (state) => ({
      preProduct: state.dataReducer.preProduct,
    }),
    shallowEqual
  );

  const wishlistClick = async (e, item) => {
    e.preventDefault();
    let token = localStorage.getItem("access_token");
    if (token == null || token == "") {
      toast.dismiss();
      return toast.error(lan.login_continue_msg)
    }
    try {
      await axios({
        url: BASE_URL + "/api/wishlist/add-remove",
        method: "POST",
        headers: {
          authorization: token
        },
        data: {
          "post_id": item._id,
          "is_favourite": !item.wishlistData.is_favourite,

        },
      }).then(res => {
        if (res.data.status === 200) {
          sellerPost.map((i, idx) => {
            if (i._id === item._id) {
              let arr = sellerPost
              arr[idx].wishlistData.is_favourite = !item.wishlistData.is_favourite
              setSellerPost([...arr])
            }
          })
          item.wishlistData.is_favourite ? toast.success(lan.wishlist_add_msg) :
            toast.success(lan.wishlist_remove_msg);
        } else {
          toast.error(res.data.message);
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const clickProduct = (e, item) => {
    e.preventDefault();
    let arr = preProduct
    arr.push(`/${data.user_id.username}/${id}/${item.make.make_name}-${item.model.model_name}`)
    dispatch(setPreProduct(arr));
    dispatch(setCurrentProduct("")) ;
    // navigate(`/${data.user_id.username}/${item.list_id}/${item.make?.make_name}-${item.model?.model_name}`)
    if(item.make){
      navigate(`/${data.user_id.username}/${item.list_id}/${item.make.make_name}-${item.model.model_name}`)
    }else{
      navigate(`/${data.user_id.username}/${item.list_id}/${item.name.replaceAll(" ","-")}`)
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const clickSeeAll = () => {
    setStage("seller"); 
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <>
      <div class="productSec list-view-filter">
        <div class="container">
          <div class="row">
            {sellerPost && sellerPost.length > 0 && sellerPost.map(item => {
              return item.list_id !== id && <div class="col-lg-3">
                <div class="productbx">
                  <div class="productbxtop">
                    <div class="productbxtopimg">
                      <a href="" class="userSmall"><img src={item.user?.seller_type==="private_seller"?userSmall:smallDealer} alt="" /></a>
                      {item.image && JSON.parse(item.image).length > 0 &&
                        <img onClick={(e) => {
                          clickProduct(e, item)
                        }}
                          src={`${BASE_URL}/uploads/post/${JSON.parse(item.image)[0]}`} alt=""
                        />}
                      <a href="" onClick={(e) => { e.preventDefault(); wishlistClick(e, item) }} class="grayHeart">{item.wishlistData?.is_favourite ? <img src={colorHeert} alt="" /> : <img src={grayHeart} alt="" />}</a>
                      {item.selling_rent==="Rent"&&<h5 className='rentBtn'>RENT</h5>}
                      {item.new_used==="Novo"&&<h5 className='rentBtn newBtn'>NOVO</h5>}
                    </div>
                    <div class="productbxtoprtlst" onClick={(e) => { clickProduct(e, item) }}>
                      <ul>
                        <li><span><img src={yr} /></span> {item.year}</li>
                        <li><span><img src={dsl} /></span> {item.fuel?item.fuel:"BENZIN"}</li>
                        <li><span><img src={milg} /></span> {item.mileage}</li>
                        <li className='trnmbl'><span><img src={auto} /></span> {item.transmission}</li>
                        <li><span><img src={lcn} /></span> {item.location.city_name}</li>
                      </ul>
                    </div>
                  </div>
                  <div class="productbxbtm" onClick={(e) => { clickProduct(e, item) }}>
                    <h3>{item.name}</h3>
                    <strong><span>KM</span> {item.price}</strong>
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      </div>
      
    </>




  )
}

export default SellerListing