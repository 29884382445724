import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPostData, getUserData, setMode, setPreProduct, setloading } from '../../Redux/ActionCreater';
import { toast } from 'react-toastify';
import axios from 'axios';
import { BASE_URL, lan } from '../../config';
import Header from '../../partial/Header';
import Sidebar from '../../partial/Sidebar';
import Account from "../../images/private_seller_black.svg"
import Frame from "../../images/car_dealer_black.svg"
import filter_clear_all from "../../images/filter_clear_all_black.svg"

function SaveSeller() {
    const [data,setData]=useState([])
    let login_data = JSON.parse(localStorage.getItem("login_data"));
    let token = localStorage.getItem("access_token");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { preProduct } = useSelector(
		(state) => ({
			preProduct: state.dataReducer.preProduct,
		}),
		shallowEqual
	);

    const logOut=()=>{
        // setUserData("");
        dispatch(setloading(false))
        dispatch(getUserData(""));
        localStorage.removeItem("login_data");
        localStorage.removeItem("access_token");
        toast.error(lan.login_continue_msg);
        dispatch(getPostData({preData:[]}));
        dispatch(setMode(""))
        navigate("/")
    }

    let fetchData=async()=>{
        try {
            dispatch(setloading(true))
            await axios({
                url: BASE_URL + "/api/save-seller-list",
                method:"post",
                headers: {
                    authorization: token
                },
                data: {
					"loggedin_user_id": JSON.parse(localStorage.getItem("login_data"))?._id
				}
            }).then(res=>{
                if(res.data.status===200){
                    setData(res.data.data);
                    setTimeout(() => {	
                      dispatch(setloading(false))
                    }, 1000);
                }else{
                  dispatch(setloading(false))
                }
            })
        } catch (error) {
            console.log(error.response.status)
            dispatch(setloading(false))
            if(error.response.status===401){
              logOut()
            }
        }
    }

    const clickBox=(item)=>{
        let arr = preProduct
		arr.push(`/saved-sellers`)
		dispatch(setPreProduct(arr));
		navigate("/" + item?.dealer_id?.username);
    }

    let saveSeller=async(e,item)=>{
      e.preventDefault();
      let token = localStorage.getItem("access_token");
      if (token == null || token == "") {
        toast.dismiss();
        return toast.error(lan.login_continue_msg)
      }
      try {
        await axios({
          url: BASE_URL + "/api/save-dealer/add-remove",
          method: "POST",
          headers: {
            authorization: token
          },
          data: {
            "dealer_id": item.dealer_id._id,
            "is_favourite": false,
  
          },
        }).then(res => {
          if (res.data.status === 200) {
            data.map((i, idx) => {
              if (i._id === item._id) {
                let arr = data
                arr.splice(idx, 1);
                setData([...arr])
              }
            })
            toast.dismiss();
              toast.success(lan.dealer_remove_msg);
          } else {
            toast.error(res.data.message);
          }
        })
      } catch (error) {
        console.log(error)
                  if(error.response?.status===401){
                      logOut()
                    }
      }
    }

    useEffect(()=>{
        fetchData();
    },[])
  return (
    <>
    <Header page={"saved-sellers"} />
    <Sidebar/>
    <div class="listingDtils CAR_DEALERSldrSec mblmarg">
				<div class="container">
    <div class="activeListing">
			<div class="col-lg-12 col-md-6" style={{margin:"auto"}}>
				<div class="ACTIVE_LISTINGS" style={{background:"#fff",color:"#ccc"}}>
					<h3 style={{color:"#000"}}>{lan.saved_deales}</h3>
				</div>
			</div>
		</div>
</div>
</div>

<div className="productSec list-view-filter">
  <div className="container">
    <div className="row">
        {console.log(data.length)}
        {data&&data.length>0&&data.map((item)=>{
            return  <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="productbx saveseller"  >
            <img onClick={()=>clickBox(item)} src={item?.dealer_id?.seller_type=="private_seller"?Account:Frame} alt="" />
            <h5 onClick={()=>clickBox(item)}>{item?.dealer_id.username?item?.dealer_id?.username:item?.dealer_id?.full_name}</h5>
            <a href="" onClick={(e)=>{saveSeller(e,item)}}  class="grayHeart dlrTrs"><img src={filter_clear_all} alt="" /></a>
           </div>
          </div>
        })}
        {data&&data.length===0&&<div class="viditext" style={{marginTop:"50px",fontWeight:"500"}} >{lan.no_data}...</div>}
    </div>
  </div>
</div>
    </>
  )
}

export default SaveSeller