import React from 'react'
function Map({resetSelection, filterData, setFilterData }) {
    const toggleClass = (e, reg) => {
		resetSelection()
        // e.target.classList.toggle("active");
        let arr = [...filterData.location.state]
        const index = arr.indexOf(reg);
        if (index > -1) {
            arr.splice(index, 1);
        } else {
            arr.push(reg)
        }
        setFilterData({ ...filterData, location: {...filterData.location,value:[],state:arr} })
    }
    return (
        <>
            {/* <?xml version="1.0" encoding="utf-8"?> */}
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="168 -77.8 947.8 947.8" style={{ enableBackground: 'new 168 -77.8 947.8 947.8' }} xmlSpace="preserve">
                <path onClick={(e) => toggleClass(e, "mostarska")} className={filterData.location.state.includes("mostarska")?"st0 region_sec active":"st0 region_sec"} data-id="region-1" d="M864.7,579.7c-0.9-0.4-1.6-1.1-2.2-1.8c-0.9,0.5-2,0.9-3.4,0.9h-0.2l-3.5-0.2l-1.3-2.6c-0.1-0.1-0.3-0.3-0.5-0.4c-0.4-0.3-0.8-0.6-1.3-1c-0.1-0.1-0.3-0.2-0.4-0.3c-1.2-0.8-4-2.7-4.6-6.5l-0.5-0.2
	c-1.3-0.7-2.8-1.5-4.2-2.9c-0.1-0.1-0.2-0.2-0.3-0.3c-2.2,0-3.7-1.3-4.4-1.8c-0.4-0.3-0.8-0.7-1.2-1c-0.9-0.8-1.7-1.5-2.2-1.6
	c-0.9-0.2-1.6-0.5-2.3-0.8c-0.5,0.1-0.9,0.2-1.4,0.2c-0.7,0-1.4-0.1-2-0.3c-0.2,0.1-0.4,0.1-0.5,0.2c-0.2,0.1-0.5,0.1-0.7,0.2
	c-1,0.3-2.3,0.8-4.1,0.8c-1.5,0-2.9-0.3-4.3-1c-1.9-0.9-3.6-1.9-5.3-2.9c-0.6,0.7-1.2,1.1-1.9,1.5l0.9,1.7l-5.4,2.6
	c-0.4,0.2-0.7,0.3-1.1,0.5c-0.5,0.2-1,0.5-1.3,0.6c-0.3,0.2-0.6,0.4-0.9,0.5c-1.3,2.6-4.2,4.2-6.9,4.2c-1.6,0-3-0.5-4.2-1.5
	c-1.1-0.9-2.7-1.3-5.3-1.7c-1.1-0.2-2.2-0.4-3.4-0.7c-4-1-5.2-3.7-5.4-5.9c-1.1-0.8-2.4-1.5-3.7-2.2l-0.8-0.5
	c-2.1-1-3.3-2.4-3.8-4.3c-0.2-0.7-0.2-1.4-0.2-2.1c-0.8-1.2-1.2-2.5-1.2-3.6c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1,1.2-0.7,2.5-1.7,4
	l-0.1,0.2c-1.2,1.8-3.1,2.9-5.2,2.9c-1.7,0-2.9-0.7-3.7-1.2c-0.7-0.3-1.5-0.5-2.2-0.8c-1.2-0.4-2.3-0.8-3.5-1.2
	c-1.3-0.4-4.2-1.4-5.5-4.5c-0.4-1.1-0.8-2.7-0.2-4.6c-1.1-3.4-0.1-6.7,2.5-8.5c0.1-0.1,0.5-0.4,0.8-0.7c1-0.9,2.5-2.3,4.8-3.1
	c-0.1-0.2-0.1-0.4-0.2-0.6c-0.5-1.4-1.1-3-1.3-4.9c-1-1.2-2.5-3.6-1.6-6.7c1-3.8,4.2-5,5.4-5.4c0,0,0,0,0.1,0
	c2.1-1.6,4.6-2.5,7.4-2.5c1.9,0,3.9,0.4,5.9,1.2c0.5,0.2,0.9,0.4,1.4,0.6h0.1c-0.2-0.9-0.3-1.7-0.3-2.3c0-0.1,0-0.3,0-0.4l-3-3.2
	l1.2-1.5c-1.1-0.6-1.9-1.4-2.4-1.9c-1.9-2-2.2-4.2-2.2-5.5c-2.2-3-2.4-5.6-1.8-7.7c-0.3-0.3-0.6-0.8-1.1-1.3
	c-0.3-0.3-0.6-0.6-0.9-0.8c-0.4-0.3-0.7-0.7-1.1-1c-0.9,0.4-1.8,0.6-2.8,0.6s-1.9-0.2-2.7-0.6c-2.6-1.1-4.4-3.3-5-6
	c-0.6-2.6-0.1-5.3,1.6-7.6c0,0,0,0-0.1,0c-4.7-1.4-6.9-4.9-8.4-7.2l-0.3-0.5c-0.3-0.5-0.5-0.9-0.8-1.2c-1.7,2-4,2.3-5.1,2.3h-0.2
	c-1.7,0-3.3-0.6-4.6-1.5c-1.6,1.2-3.4,1.9-5.1,1.9c-1,0-2-0.2-2.9-0.6c-3.3-1.5-5.2-4-5.5-6.6l0,0c-0.2-0.1-0.6-0.4-0.9-0.6
	c-0.3-0.2-0.7-0.4-1-0.6l-5.4-3.5l4.1-4.9c0.2-0.3,0.5-0.5,0.7-0.8c-0.1-0.1-0.2-0.3-0.2-0.4c-0.7-1.3-1.6-2.7-2.7-4.1
	c-1.8-2.5-3.9-5.4-5-9.1c-0.1-0.2-0.2-0.5-0.3-0.7c-1.7,1.6-3.9,2.5-6.3,2.5c-0.4,0-0.8,0-1.2-0.1c-0.2,0-0.4,0-0.6-0.1
	c-1.8-0.1-7.1-0.5-9.1-6.3c-0.4-1-0.8-2.1-1.3-3.2c-0.5-1.3-1.1-2.6-1.6-4c0-0.1-0.1-0.2-0.1-0.2c-0.4-0.7-1.2-1.9-1.5-3.6
	c-1.9-0.6-3.5-1.8-4.6-3.4c-0.2-0.3-0.4-0.7-0.6-1c-0.7,0.2-1.4,0.4-2.3,0.4c-1.3,0-2.6-0.4-3.8-1.2l-0.1-0.1
	c-0.4-0.3-0.8-0.6-1.1-0.9l-0.9,0.4l-3.3,7.7l-4.1-2.1c-0.8,0.6-2.1,1.6-3.8,2c-1.1,2.9-4,4.9-6.9,5.3c-1.4,2.1-3.6,3-5.6,3
	c-1.9,0-3.6-0.7-5.2-2.2c-1.7-1.5-3.9-2.5-6.6-3.5l-0.2-0.1c-0.8-0.3-2.3-0.8-3.6-1.9c-0.2,0-0.5,0-0.7,0c-0.9,0-2.5-0.2-4-1.2
	c-0.9,0.2-1.9,0.4-3,0.4c-0.7,0-1.3,0-1.9-0.1c-0.2,0-0.5,0-0.7,0c-0.9,0.4-1.8,0.6-2.7,0.6c-0.6,0-1.5-0.1-2.5-0.5
	c-1.8,1.2-3.6,1.9-5.4,1.9c-2.2,0-5.3-0.9-7.8-4.9c-0.5,0-0.9,0.1-1.4,0.1c-1.2,0-2.2-0.2-3.2-0.6l0,0c-0.5,0-1.4,0.4-2.2,0.7
	c-0.2,0.1-0.4,0.1-0.6,0.2c-1.5,3-3.6,5.3-6.5,6.9c0.7,2.2,0.8,4.2,0.2,5.9c0.4,1.3,0.4,2.4,0.4,3.1c0,0.1,0,0.2,0,0.3
	c0,0.4,0,0.8,0,1.2l0,0c0.6,1.4,1.5,3.7,0.5,6.3c-0.8,2.3-2.6,3.5-4,4.3c-1.1,2.3-3.3,3.8-6,3.8c-0.8,0-1.4-0.1-1.9-0.2h-0.2
	c-2.5-0.4-4.3-1.8-5.6-4.3v-0.1c-0.2-0.3-0.3-0.5-0.4-0.6h-0.2c-0.8,0.1-1.8,0.3-3,0.3c-1.4,0-2.6-0.3-3.7-0.9
	c-0.2-0.1-0.7-0.2-1.1-0.3c-1.8-0.4-4.7-1.1-7-3.8c0,0-0.1-0.1-0.2-0.1c-0.8-0.8-3.3-3.1-2.7-6.9c0-0.3-0.6-1.4-0.9-2
	c-1.3-2.5-1.9-5-2.3-7c-0.1-0.6-0.2-1.3-0.3-1.9c-0.1-0.5-0.1-1.2-0.2-1.6c-1.2-0.9-2.5-1.8-3.7-2.8c-0.2,0.1-0.4,0.1-0.6,0.2
	c-0.6,0.2-1.1,0.4-1.5,0.5c-1.2,0.6-2.3,0.9-3.4,0.9c-0.5,0-1.6-0.1-2.8-0.6c-1.1,0.5-2.2,0.8-3,1c-0.3,0.1-0.6,0.2-0.9,0.2
	c-1.2,1.3-2.9,2-4.7,2c-1.3,0-2.7-0.4-3.8-1.2c-0.1,0-0.3,0-0.6,0h-1.2c-3.5,0-5.8-1.4-7.1-3.3c-0.6,0.3-1.1,0.6-1.6,0.9
	c-1,0.5-1.9,1-2.4,1.5c-1.2,1.1-2.8,1.7-4.3,1.7h-1.2l-1.3-0.6c-0.4-0.2-1-0.5-1.4-0.6l-0.9,0.5h-1.5c-1.2,0-2.9-0.4-4.4-2.1
	c-0.7-0.6-1.3-0.8-1.6-0.9c-3.7-0.2-5.3-2.7-5.8-3.8c-0.4-0.8-0.9-1.7-1.3-2.6c-1.6-2.9-3.6-6.6-3.7-11.3c0-0.1,0-0.3,0-0.4
	c-2-0.9-3.9-1.7-5.3-1.7c-0.2,0-0.3,0-0.5,0c-0.8,0.1-1.8,0.2-2.8,0.2c-4,0-7.1-1.7-9.2-5.1c-0.1-0.2-0.5-0.6-0.7-0.9
	c-1-1.1-2.5-2.8-3.1-5.2c-0.1-0.3-0.2-0.4-0.2-0.5c0,0,0,0-0.1-0.1c-0.9,0.3-1.9,0.5-2.9,0.5c-3.2,0-5.5-1.7-6.9-2.8
	c-0.5-0.4-1-0.8-1.6-1.2c-0.8-0.7-1.9-1.5-2.4-1.7c-5-1.6-7.9-5-9.8-7.9c-0.9,0.3-1.7,0.7-2.5,1.2c1.5,4.2-0.2,7.7-0.8,8.9
	c-2.2,4.5-5.8,6.8-8.7,8.8c-0.3,0.2-0.7,0.4-1,0.7c-1.9,1.3-3.7,1.9-5.6,1.9c-0.9,0-1.8-0.2-2.7-0.5c-0.7,0.7-1.8,1.5-3.1,2.1
	c2,1,4.3,1.8,5,4.2c0.7,2.1,1.7,3.6,3.8,4.4c0.4,0.1,0.8,0.5,1,0.8c1.8,2.9,4.8,4.6,7,7c2.3,2.5,4.8,4.4,8,5.8c1.5,0.7,3,1.8,4,3
	c1.5,1.9,3,4.1,2.4,6.8c-0.5,2-0.6,3.7,0.8,5.4c0.4,0.4,1,1.2,0.6,1.8c-1,1.8,0.1,2.4,1.4,3.3c1.1,0.7,3,2.1,2.9,2.7
	c-0.6,2.1,0.9,1.9,1.8,2.3c1.5,0.6,3.1,0.9,4.5,1.6c2.5,1.2,5.6,1.4,7,4.3c0.9,1.8,1.9,3.5,2.6,5.3c0.4,1,0.9,1.5,1.7,2.1
	c1.4,1.2,3.2,1,4.6,1.8c3.6,1.9,5.6,5.8,9.5,7.3c0.1,0,0.2,0.2,0.3,0.3c2,3.7,4.5,7.1,5.2,11.4c0.1,0.8,0.2,1.8,1,2.4
	c3.9,3.3,6.4,7.9,10.3,11.2c1.6,1.4,2.6,3.5,4.1,4.8c1.9,1.6,2.5,4.1,4.8,5.5c3.3,2,6.7,3.7,10.1,5.4c2.5,1.3,4,3.8,6.3,5.3
	c0.9,0.6,1.5,1.2,2.5,1.1c2-0.2,3.6,0.7,5,1.8c1.7,1.3,3.2,2.8,4.8,4.3c1.8,1.8,5,1.9,7.2,0.5c0.9-0.6,1.6-2,3-1.7
	c3.6,0.7,7.4,0.7,10.6,2.7c1.2,0.8,2.3,1.5,3.5,2.2c2.9,1.6,5.8,6.8,4.9,9.4c-1.3,3.6-2.1,7.4-5,10.4c-0.5,0.5-1.9,1.3-1.3,2.4
	c1.3,2.4,1.6,4.9,2.1,7.5c1.4,8,7.4,13.8,10.4,21.1c1.1,2.7,3,5.1,4.2,7.8c0.5,1.1,0.9,2.8,1.8,3.1c2.8,1.2,5.3,4.2,8.9,2.1
	c0.2-0.1,0.6,0.1,0.8,0.2c6.2,2.2,10.3,7.7,8.6,14.9c-0.4,1.6,0.1,3.1,2.1,3.5c1.6,0.3,2.8,1.1,4,2c2.1,1.5,3.5,4.1,6.7,3.8
	c0.3,0,0.7,0.5,1,0.9c2.9,3.3,7,4.5,10.9,6.1c0.6,0.3,1.6,0.2,2,0.6c2.3,2.3,5.1,3.5,8,4.7c2.5,1.1,4.7,2.8,7.1,4.2
	c0.3,0,0.6-0.2,0.7,0.3c0.9-0.6,0.9,0.2,1.1,0.7c1.7,2.1,4.9,3.3,4.2,6.9c0,0.2,0.2,0.4,0.3,0.6c1.5,2.9,3.2,5.7,4.7,8.6
	c2.3,4.6,5.7,8.9,5.7,14.5c0,0.5,0.3,0.9,0.6,1.1c3.3,1.8,0.5,2-0.7,2.5c-0.3,0.1-0.5,0.3-0.8,0.4c-2.5,1-4.9,2.3-7.5,2.9
	c-1.4,0.3-2.6,0.7-3.7,1.6c-0.6,0.4-1.2,1-1.8,1.2c-3.2,0.8-6.1,2.2-8.5,4.4c1.2,1.3,2.2,2.9,4.4,2.4c0.2-0.1,0.7,0.1,0.8,0.2
	c0.8,2.5,2.3,4.3,5,5c0.1,0,0.4,0.7,0.7,1.1c0.6,0.8,1,1.8,1.7,2.5c0.9,1,1.8,1.7,2.6-0.2c0.7-1.7,1.6-3.3,2.4-4.9
	c1.9-3.7,4.4-4.2,7.5-1.6c0.4,0.4,0.8,0.8,1.2,0.1c0.7-1.1,1.8-0.8,2.8-0.8c5,0.1,8.8,2.9,12.2,6.1c0.9,0.8,1.4,0.9,1.9-0.3
	c0.6-1.3,1.2-2.5,1.9-3.7c1-1.8,2-2,3.7-1.1c2.8,1.4,4.7,3.5,6.5,6c0.7,0.9,1.2,1.5,0.4,2.5c-0.6,0.9-0.6,1.6,0.2,2.7
	c1.6,2,3.7,3.7,4.1,6.6c0.2,1.4,1.1,3,3.4,2.7c1-0.1,2,0.7,2.3,1.4s-0.9,1.1-1.5,1.5c-1,0.6-1.2,1.7-0.8,2.5c0.5,1,0.5,2.8,2.2,2.6
	c3.6-0.4,6.6,2.6,10.3,1.9c0.2,0,0.5,0.3,0.7,0.5c2,1.6,3.9,3.6,6.1,4.6c2.2,1,3.2,2.4,3.5,4.6c0,0.4,0,0.8,0,1.1
	c0.2,1.3,2.6,3,3.8,2.5c2.9-1.1,5.2-0.1,7.1,1.9c2.7,2.8,5.6,5.4,9.3,7c2.1,0.9,3.3,3.2,2.5,5.3c-0.7,1.8,0.1,2.5,1.5,2.9
	c3.6,0.9,7.2,1.8,8.5,6c0.2,0.6,0.6,1.1,1,1.7c0.2,0.4,0.7,1,0.9,0.8c1.1-1.4,2.7-0.4,4-0.9c1.8-0.6,2.9,0.5,3.5,1.6
	c1.9,3.5,4.4,6.5,7.7,8.9c0.8,0.6,1.9,1.2,1.9,2.6c0,0.8,0.4,1.5,1.1,2.1c0.5-0.9,1.7-0.5,2.2-1.5c1.1-2,4.3-1.5,4.9-4.1
	c0-0.1,0.2-0.1,0.2-0.1c1.2,1,3,0.4,4.2,1.2c0.8,0.5,1.2,0.5,1.8-0.1s1-1.6,1.8-1.5c2.6,0.3,5.2,0.6,7.7,1.4c2.9,0.8,4.1,3.5,5.4,6
	c0.5,0.9,0.9,2.4,2.5,1.5c1.5-0.8,2.5,0.1,3.2,1.1c2,2.8,5.2,4.4,7,7.7c0.4-1.8,1.3-2.5,2.9-2.9c1.3-0.2,2.4,0.2,3.5,0.1
	c2.4-0.2,2.6-2.5,3.5-4c2-3.3,6.3-3.9,8.8-1.1c0.8,0.9,1.6,1.3,2.7,0.5c0.9-0.7,1.9-0.9,1.3-2.7c-1.1-3.2-3.5-5.3-5.5-7.9
	c-0.4-0.6-1.3-1-0.8-1.9c0.9-1.4,1.1-3.1,2.9-4c1.2-0.6,1.9-1.5,3.6-1.4c0.9,0,1.9-1.4,3.1-0.3c0.4-0.5,2-0.3,1.2-1.4
	c-1.4-1.8-1.9-4.6-4.7-5.2c-0.3-0.1-0.9-0.2-0.7-0.7c1.1-2.5-2.2-4.9,0.3-7.7c1.5-1.7,1.6-4.5,2.4-6.8c-0.8-0.2-1.4-0.3-2.1,0
	c-2.2,0.9-4.8,0.3-6.1-0.8c-2.6-2.3-6.3-2.7-8.3-5.7c-0.3-0.4-0.6-0.7-1.1-0.9c-2.1-0.8-3-2.7-2.7-5.1c0.1-0.8,0.3-1.8-0.6-2.5
	c-1.2-1-0.7-2.3-0.5-3.5c0-0.2,0.9,0,0.2-0.6c-1.7-1.6-2.5-3.7-3.9-5.4c-0.7-0.9-0.5-1.4,0.2-1.8c1.5-0.7,2.2-2.2,3.6-2.9
	c1-0.5,0.9-1.1,0.1-1.9c-2.4-2.4-1.5-6.3,1.8-7.5c2.1-0.8,2.8-2,1.8-3.9c-0.7-1.4-0.5-2.1,1-2.6c0.5-0.2,1-0.7,1.5-0.8
	c1.3-0.3,1.2-1.1,0.7-2c-1.6-2.7-2.4-5.8-4.4-8.3c-0.6-0.7-0.6-1.7-0.5-2.5c0.1-2.3,0.8-4.7,0.3-6.8c-0.7-2.9,0.2-5.6,0.2-8.4
	c0-1.2,0.7-2.4,2.2-2.6c3.2-0.6,3.9-2.7,3.6-5.6c-0.3-2.3,1-4.1,2.7-5.6c3.2-0.7,6.5-0.7,9.7,0c2.7,0.4,5.3,1.4,8.2,0.1
	c2.5-1.1,4.8-2.7,7.7-2.8c0.6,0,0.8-0.2,0.8-0.8c-0.1-1.6,0.4-3.3-0.5-4.8c-0.8-1.4-1.1-2.9-1.3-4.4c-0.5-4.9-1.9-9.7-2.5-14.6
	c-0.4-3.3-1-6.5,0.2-9.7c0.7-2,1.1-4.2,1.8-6.2c1.1-3.2,1.7-6.6,5.1-8.6c1.8-1.1,3.1-3,4.8-4.3C864.6,580.7,865.6,580.1,864.7,579.7
	z" />
                <path onClick={(e) => toggleClass(e, "sarajevska")} className={filterData.location.state.includes("sarajevska")?"st0 region_sec active":"st0 region_sec"} data-id="region-2" d="M1025.1,447.5c-1.9-0.9-2.5-2.4-2.3-4.5c0.1-0.6-0.4-1.3-0.7-2c-0.1,0-0.1-0.1-0.2-0.1c-1-0.8,0.3-2.4-0.9-2.9
	c-0.9-0.4-2.1-0.2-3.6-0.3c1.7-0.8,4.8,0.9,4-2.7c-2-1.2-4-2.3-5.9-3.5c-0.9-0.5-2.4-1.2-2.4-1.8c-0.1-2.2-0.9-3.6-2.7-4.6
	c-0.3-0.2-0.5-0.5-0.6-0.9c-0.8-3.6-2.3-4.9-6.1-5.2c-0.7-0.1-0.9-0.2-1.1-0.9c-1.6-4.1-1.7-4.1-6.3-4.1c-0.8,0-2.1,0.2-2.1-0.7
	c-0.1-3.1-2.6-5-3.7-7.5c-1-2.3-2.8-3.8-4.7-5.4c-1.6-1.4-4.1-2.5-3.7-5.3c0.2-1.3-0.6-2.1-1.8-2.6s-2.4-1.3-3.6-2
	c1.3-1.6,1.3-2.8-0.9-4c-1.2-0.7-2.8-1.5-2.5-3.7c0.1-0.9-0.5-2.2-2.1-1.9c-1.1,0.2-1.8-0.2-1.9-1.5c-0.1-1.5-0.9-2.5-1.8-3.5
	c-1.6,2.6-4.2,4.1-7.3,4.1c-0.1,0-0.2,0-0.3,0c-1.1,1.7-3,2.7-4.7,2.9h-0.3h-0.4c-2.3,0-5.1-1.5-5.8-4.7c-0.3-0.5-1-1.2-1.9-2
	c-1.6-1.4-2.6-3-3.6-4.4c-0.4-0.6-0.8-1.2-1.1-1.6c-2.1-2.6-3.3-5.2-3.6-8c-3.3-1.5-5.9-3.5-7.8-6c-0.3-0.3-0.5-0.7-0.7-1.1
	l-0.1-0.1c-2.1-1.9-3.4-4.1-4-6.5c-0.8-0.3-1.5-0.7-2.2-1.1c-1.8-0.9-3.4-1.7-4.7-1.7h-0.3c-0.3,0-0.6,0-0.8,0
	c-2.8,0-5.1-1.4-6.3-3.7c-0.3-0.5-0.7-1.6-0.8-3c-0.8-0.7-1.5-1.4-2.1-2.2c-0.6,0.2-1.2,0.2-1.8,0.2c-1.4,0-4.8-0.5-6.7-4.7
	c-0.3-0.5-0.9-1.1-1.6-1.8c-0.6,0.3-1.2,0.6-1.9,0.9c-1.7,0.6-3.3,0.9-4.8,0.9c-2.9,0-5.5-1-7.7-2.9c-0.1-0.1-0.4-0.3-0.6-0.5
	c-0.7,0.2-1.3,0.2-2,0.2c-3.6,0-6.5-2.2-8.3-4.8c-1.2-0.4-2.1-1.1-2.7-1.6c-1.3,1-2.9,1.6-4.5,1.6c-2,0-4-0.9-5.4-2.5
	c-0.2-0.1-0.5-0.2-0.8-0.3c-0.3-0.1-0.7-0.3-1.1-0.5c-0.9,0.7-1.8,1-2.6,1.2l-0.1,0.1c-1.5,2.7-4.2,3.2-5.1,3.4h-0.1
	c-0.7,0.1-1.3,0.2-2,0.2c-4.2,0-8.4-2.4-10.5-5.9c-0.3,0.1-0.7,0.2-1,0.3c-1.3,0.4-3,0.9-4.8,0.9c-0.6,0-1.2,0-1.7-0.1
	c-4.4-0.8-7.9-2.8-10.1-5.9c-1.9-1.1-3.1-2.7-3.9-3.8c-0.2-0.2-0.4-0.6-0.6-0.8c-3.3-0.1-5.9-1.7-7-4.3c-0.3-0.8-0.8-2.4-0.2-4.4
	c-0.7-1.5-1.7-4.1-0.7-7.2c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.7-0.5-1.5-0.7-2.5c-0.3-1.9-0.2-3.5,0.1-4.8c-0.7-0.9-1.1-1.7-1.4-2.3
	l-0.1-0.2c-0.3-0.5-0.4-0.7-1.1-0.9c-2.9-0.8-5.1-2.7-6.6-5.7c-0.9-1.8-1.5-2.4-1.7-2.6c-0.6-0.1-1.1-0.2-1.5-0.4
	c-0.1,0-0.2,0-0.2-0.1c-1,0.6-2.1,1-3.3,1c-0.5,0-1.2-0.1-2-0.3c-1.1,0.9-2.5,1.3-3.9,1.3c-2.6,0-4.9-1.6-5.8-4.1l0,0
	c-0.8-1.5-1-2.8-1-4l-4.6-2.4l6.5-5.5c0.1-0.1,0.2-0.3,0.4-0.4c0.1-0.1,0.1-0.1,0.2-0.2c-0.5-0.3-1-0.7-1.4-1.2
	c-0.5-0.5-0.9-1.1-1.3-1.8c-0.8,0.2-1.7,0.3-2.6,0.3c-2.8,0-5.3-1.1-7.2-2.1c-0.3-0.2-0.6-0.3-0.8-0.5c-3.2-0.5-4.8-2.3-5.5-3.4
	c-0.8-1.2-1.5-3.1-1.1-5.7c-1-1.7-1.3-3.6-0.9-5.5c0.2-1.2,0.8-2.3,1.5-3.2c-0.3-0.7-0.7-1.4-1-2.2c-0.4-0.9-0.8-1.7-1.2-2.6
	c-0.3-0.1-0.5-0.2-0.8-0.3l0,0c-0.3,0.6-0.7,1.3-1.3,2l-0.1,0.1c-1.9,2.4-4.1,3.6-6.7,3.6c-1.2,0-2.5-0.3-3.6-0.9
	c-0.1,0-0.1,0.1-0.2,0.1c-1.6,0.6-3.1,0.9-4.6,0.9c-0.9,0-1.7-0.1-2.5-0.3c-0.9,1.1-2.1,2.1-3.7,2.7c-0.7,1.8-1.8,3-2.4,3.6
	c-0.1,0.1-0.2,0.2-0.3,0.4c-1.2,1.5-3,2.4-4.9,2.4c-3.1,0-7-2.3-8-5.8v-0.2c-1.7,0.9-3.5,1-4.6,1.1c-0.2,0.2-0.6,0.5-0.9,0.7
	c-0.6,0.5-1.2,1-2,1.5c-3.9,2.5-8,2.9-11.5,3.1c-2.1,1.8-4.1,2.2-5.6,2.2c-1.4,0-2.6-0.4-3.6-0.8c-1,0.4-2.2,0.5-3.4,0.5
	c-0.3,0-0.6,0-0.9,0l0,0c0,0.3,0,0.5,0,0.8c0,0.5,0,1.1,0,1.3c1.3,2.1,1.1,4.6,0.1,6.5c1.7,4,0,7.5-0.7,8.8l-2.8,5.5l-5.2-3.3
	l-1.7,2.2l-3.7-1.3c-0.2,0-0.4-0.1-0.7-0.2c-2.2,1.6-4.5,1.6-5.4,1.6h-0.1c-1.5,0.8-3,1-4.2,1.2c-0.1,0-0.1,0-0.2,0
	c-0.7,1.1-2.5,4-6.6,4.3c-0.4,0-0.7,0-1,0c-4.6,0-8-2.7-8.8-6.9c-0.1-0.1-0.8-0.4-1-0.5c-0.2,0.1-0.5,0.2-0.8,0.3l-3.2,1l-1.6-1.5
	c-0.3,0-0.5-0.1-0.9-0.3c-0.8-0.5-1.7-0.1-2.1,1c-0.4,1.3,0.7,0.9,1.2,1.2c0.8,0.5,1.6,0.9,2.6,1.5c-2,1.7-3.2,3.7-3.9,6.1
	c-0.6,2.1-1.7,2.7-3.4,1c-0.5-0.5-1-0.7-1.6-0.5c-3,0.9-5-2-7.7-2c0.2-1.6-1-3.1-0.9-4.3c0.2-1.8,0.4-2.3-1.5-1.8
	c-0.4,0.1-1,0.2-1.3-0.1c-2.3-2-5.2-3.1-7.7-4.8c-2.5-1.8-4.3-1.3-5.8,1.4c-1,1.8-1.5,4-4,4.7c-1.1,0.3-1.3,1.8-0.7,2.7
	c1.5,2.2,1.2,4.3,0.5,6.6c-2.8-3.7-3.9-4.1-5.4-2.3c-1.5-1.8-2.9-3.6-4.5-5.4c-0.4-0.5-0.7-1.3-1.8-0.7c-1,0.5-1.3,1.1-1.2,2.1
	c0.2,2,1.2,3.7,2.4,5.1c0.6,0.7,0.4,0.7,0,1.2c-1.3,1.4-2.7,3.1-2.6,4.7c0.1,2.7,0.8,5.6,1.9,8.1c0.7,1.6,0.2,3.5,1.4,4.9
	c0.1,0.1-0.2,0.6-0.4,0.7c-1.9,1.4-2.7,3.6-4,5.6c2.6,0.5,2.8,3,3.8,4.7c0.7,1.2,1.7,1.8,2.8,2.2c0.9,0.4,1.2-0.7,1.8-1.2
	c1.4-1.2,2.7-0.6,4,0.1c1.4,0.8,0.3,1.8,0.2,2.6c-0.1,0.5-0.4,1,0,1.4c1.6,1.9,0.6,3.1-0.9,4.5c-2.1,2-1.7,3.1,0.7,4.6
	c2,1.2,4.6,1.1,6.4,2.7c1.6,1.4,3.3,2.3,5.4,2.7c1.8,0.3,4,3.5,3.4,5.2c-0.7,1.9,0.1,3.2,1.5,4.1c1.9,1.2,2.8,3,4.1,4.8
	c1.1,1.5,0.9,5.3,4.8,3.4c-0.5,1-0.9,1.7-1.2,2.4c-0.9,1.9,0.1,3.7,2.2,4c1.4,0.2,2.8,2.4,2.1,3.7c-0.8,1.3-0.6,2.3,0,3.6
	c0.7,1.6,1.9,2.1,3.4,2.3c0.9,0.1,2.3,1,1.6,1.5c-1.8,1.4-0.1,1.6,0.5,2c0.9,0.7,2,1.2,3,1.8c0.9,0.5,2.3,1.8,2.1,2.1
	c-1.7,2.4,2.8,3.8,0.9,6.1c1.2,0.8,2.2,2.6,3.9,2.1c2.5-0.7,3.6,0.9,4.8,2.4c0.5,0.6,1.7,1.2,1,2.1c-1.1,1.3,0,1.9,0.6,2.2
	c2.3,1.1,3.8,2.7,4.7,5c0.4,1.1,1.5,2.6,2.7,1.9c2.1-1.2,3.4-0.2,4.8,0.9c0.8,0.7,1.6,0.7,2.5,0.1c2.2-1.4,4-0.7,4.8,1.9
	c1.1,3.7,2,4,4.8,1.6c2.2,3.3,2.1,3.2,1.1,5.2c-0.5,0.9-1.5,0.9-1.9,1.7c1.3-0.5,1.8,0.2,1.3,1.3c-0.5,1.1,0,1.4,0.7,1.9
	c0.9,0.6,1.4,0.1,2-0.5c2.2-2.2,4.7-1.7,6.1,1.1c0.2,0.4-0.1,0.5-0.1,0.8c-0.4,1.6,0.6,3,2.3,3.3c2.7,0.4,3.4,1.1,3.4,3.7
	c0,1.4,1.1,2.2,1.5,3.4c0.8,2.5,2.1,4.8,2.9,7.3c0.9,2.6,3.2,2.3,5.1,2.5c1.8,0.2,3.3-0.8,4.1-2.8c0.3-0.8,0.2-2.7,1.4-2.4
	c1.4,0.3,3.2,0.4,4.2,2c1.1,1.7,2.1,3.5,2.7,5.5c1.4,4.7,5.1,8,7.3,12.2c0.3,0.6,0.5,0.6,0.9,0.3c1.7-1.1,2.4,0,3,1.4
	c1.3,2.7,1.2,2.6-1.5,3.9c-1.2,0.5-2.6,0.6-3.6,1.8c0.9,0.6,1.8,1,2.6,1.7c1,0.9,3,1,2,3.3c-0.3,0.9,1.3,2,2.3,2.5
	c0.8,0.4,1.9-0.3,2.7-1c1.1-1,1.9-2.6,3.8-2.3c0.8,0.1,1.3,0,1.5,1.1c0.2,1.2,1.1,2,2.3,2s1.2-1.1,1.6-1.9c1.5-3.1,1.5-3,4.5-1.5
	c2.2,1.1,3.4,3,4.6,4.9c1.4,2.1,2.6,4.5,5.5,5.3c2,0.6,3.5,2.5,4.4,4.2c0.6,1.1-0.2,3.1-1.3,4.4c-1.5,1.8-1.1,4.4,1,5.3
	c0.8,0.4,1.9-0.4,2.3-1.4c0.8-2,2-1.3,2.6-0.2c1.1,1.9,2.7,3.1,4.3,4.5c1.3,1.3,2.8,3.1,3.3,5.2c0,0.2,0.2,0.5,0.1,0.6
	c-1.9,2-0.3,3.4,0.8,4.8c1,1.2-0.3,2.7,1,4c1,1.1,1.4,1.5,2.2,0c0.4-0.7,0.8-1.7,1.8-1.2c1.2,0.6,0.2,1.4-0.1,2.1
	c-0.2,0.4-0.2,0.8-0.1,1.3c0.6,1.6,2.4,3,0.8,5c1.4,1.5,0.9,3.5,1.4,5.3c0.3,0.9,0,1.8,0.4,2.6c0.9,1.7,0.5,2.4-1.2,3.6
	c-3.2,2.3-5.8,0.4-8.4-0.6c-2.6-1.1-5.6-1.3-7.8,0.6c-1,0.9-2.9,0.7-3.3,2.2c-0.4,1.6,1.8,2.4,1.8,3.6c-0.2,3.6,2.8,6.5,1.9,10.4
	c-0.5,1.9-1.5,2-2.7,2c-2.7-0.2-3.9,2.1-5.7,3.3c-0.7,0.4-0.4,1.7-0.1,2.5c0.3,0.7,0.5,1.5,0.2,1.9c-1,2,0.1,2.5,1.7,3
	c2.2,0.7,4.4,1.6,6.6,2.3c0.5,0.2,1,1.2,1.6,0.3c0.4-0.6,1.3-1.5,0.8-2.2c-1-1.1-0.1-1.6,0.4-2.3c2.9-4,4.6-4.3,8.4-1.3
	c0.6,0.4,1.1,1,1.8,1.3c1.4,0.6,2.4,1.3,1.2,2.9c-0.7,1,0.3,1.4,0.5,1.9c0.5,0.9,1.5,1.5,0.5,2.8c-0.5,0.7,0.4,0.9,0.9,1.2
	c1.9,1,3.7,2.1,5.5,3.3c1.2,0.9,2.5,1.8,2,3.8c-0.2,0.7,0.5,0.9,1.1,1.1c3.7,0.9,7.7,0.9,10.9,3.5c0.8,0.6,2.5-0.4,2.5-1
	c0.1-2,1.8-1.9,2.8-2.6c0.9-0.6,2-1,3-1.5c-0.4-0.7-1.8-1.4-0.7-2.2s1.5-3.1,3.5-2.2c1.6,0.7,1.9,0,2-1.4c0.1-2.8,1-3.2,3.5-1.8
	c2.7,1.6,5.3,3.3,8.2,4.7c2.1,1,3.5,0.1,5.2-0.3c1.2-0.3,2.3-1.1,3.5-0.2c0.4,0.3,0.8,0.2,0.9-0.2c1-2,1.8-0.7,2.7,0.1
	c0.4,0.4,0.9,0.9,1.4,1c2.6,0.4,4.2,2.3,6.1,3.8c0.3,0.2,0.7,0.6,0.9,0.5c2.2-1.2,3,0.8,4.1,1.9c0.9,0.8,1.9,1.4,2.9,2
	c1.6,0.9,3.5,1.6,3.4,3.9c0,2.2,2,2.7,3.2,3.9c1,0.9,2.3,1.5,2.9,2.8c1,0.1,1.3-0.9,1.9-1.4c2.3-2.1,4.3-4.7,7-6.2
	c7.8-4.2,14.6-10.1,22.9-13.4c0.3-0.1,1.2-0.2,0.9-0.7c-0.8-1.4,0-3.5-2.1-4.2c-0.4-0.1-1.1-0.2-1-1c0.1-0.7,0.8-0.8,1.1-0.9
	c1.5-0.3,2.9-1,4.4-1.3c1.4-0.3,2.8-1.2,4.3-0.5c1.4,0.7,2.2,0.1,2.8-0.9c0.9-1.7,2-1.5,2.9-0.2c1.2,1.8,3,3,4.2,4.9
	c0.8,1.2,1.9,2.7,3.5,2.4c1.6-0.4,1.7,0.1,1.7,1.4c0,1.1-0.1,2.2-0.8,3.1c-0.6,0.9-0.8,2-0.6,3c0.5,1.9,0.1,4.2,3.2,4.6
	c1.6,0.2,3.6,0.9,3.9,3.1c0.3,2.2-0.1,4.7,3,5.1c0.1,0,0,0.5,0.2,0.6c0.8,0.8,1.6,1.4,2.8,0.3c1.7-1.6,1-4.7,3.4-5.8
	c0.1,0,0.1-0.3,0-0.4c-0.8-0.9-0.1-1.2,0.6-1.6c0.8-0.4,1.1-1.2,1.2-2c0.1-0.7,0.2-1,0.9-1.3c2-0.8,3.7-2.2,5.9-2.6
	c0.4-0.1,2-0.1,1.4-1.5s0-2.6,0.6-3.8c0.2-0.5,1.1-1.1,0.2-1.6c-1.6-1-2.3-2.7-2.7-4.2c-0.4-1.3-2.2-0.9-2.1-2.1
	c0.1-2-1.3-2.5-2.5-3.3c-1.1-0.8-2.6-1-2.3-3.5c0.2-1.5,0.3-3.1,0.6-4.7c0.2-1.1-0.1-2.7-1.6-3.5c-1.1-0.6-2.1-1.6-3.2-2.4
	c-0.7-0.4-1-0.6-0.6-1.6s0.8-2.3-0.3-3.4c-1.2-1.2-2.3-2.6-2.5-4.5c-0.1-0.6-0.7-1.8-1.4-1c-1.5,1.6-2.4,0.4-3.4-0.4
	c-1.2-0.9-2.2-2-3.8-0.4c-0.6,0.6-2.1,0.5-2.9-0.3c-0.8-0.7,0.2-1.5,0.5-1.9c0.9-1.1,0.8-2.4,0.3-3.2c-1.6-2.2-1-5.1-2.3-7.4
	c-0.7-1.3-1-2.5-2.9-2.3c-2,0.3-0.9,1.7-1,2.6c-0.1,1.2-1,1.1-1.6,1.5c-1,0.7-0.8-0.5-1.1-0.7c-2.4-1.4-0.4-3,0-4.4
	c0.4-1.2,1.3-0.7,2.2-0.4c0.7,0.2,1.6-0.3,1.9-0.8c0.7-1.2,2-1.6,3.1-2.1c1.4-0.6,2.6-1.5,4.2-1.7c0.5-0.1,2.2-0.5,1.4-1.4
	c-2.2-2.5,0.2-4.2,0.6-6.3c0.2-1,1.2-1,1.9-1.3c0.9-0.3,0.8,0.8,1.1,1.2c0.7,0.9,0.6,2.7,2,2.9c2.5,0.3,4.2,1.9,5.1,3.7
	c0.8,1.7,1,4,0.7,6c-0.1,0.7-0.1,1.1,0.7,1.3c0.7,0.2,1.2,1.3,2.1,0.4c0.8-0.9,1.9-1.4,2.6-2.6c0.6-1,2.1-1.6,3.4-1.5
	c0.8,0,1.2,0,1.8-0.5c1.3-0.8,2.2-3.3,4.5-1.5c0.3,0.3,0.7-0.2,1-0.4c1.7-1.2,3.8-1.7,5.1-3.4c0.5-0.7,1.2-0.6,2-0.7
	c1.7-0.3,3.1,1.3,4.4,0.8c4.1-1.4,7.9-0.4,11.8,0.6c1.8,0.5,2.6-0.4,3.7-1.4c1.2-1.1-0.3-1.6-0.5-2.3c-0.2-1.2-0.4-2.4,0-3.5
	c0.3-0.8,0.4-1.6,0.2-2.2c-0.8-2-0.3-3.8,0.3-5.6c0.3-1,0.2-1.6-0.5-2.4c-0.5-0.5-1.3-0.8-1.3-1.7c1.6-0.1,3.2-0.7,4.5-0.6
	c1.9,0,4.3-2.3,6,0.6c0.1,0.3,0.8,0.5,0.9,0.4c2.3-1.7,5.5-0.1,7.8-2c1.4-1.2,3.8-1.2,4.4-3.5c0.2-0.6,1.3-0.5,1.6-0.1
	c0.7,1,3.1,0.8,2,3c-0.2,0.4,0.5,0.4,0.8,0.5c0.8,0.2,1.6,0.3,2.3,0.7c1.3,0.6,1.7,1.4,0.3,2.3c-0.8,0.5-1.5,1-2,1.8
	c1.4,0.8,2.4,0.6,3.3-0.6c0.2-0.1,0.3-0.6,0.6-0.3c0.2,0.2,0,0.6-0.3,0.6c0.1,0.5-0.4,1.5,0.6,1.3s2-0.7,2.7-1.3
	c2.5-2.1,6-2.6,8.7-0.7c1.3,0.9,3.3-0.5,4,1.5c0.2-0.2,0.4-0.4,0.7-0.6c1.8-1.3,3.6-2.7,5.4-4.1c-0.3-0.3-0.5-0.6-0.8-0.8
	c-2.2-1.6-3.2-3.6-1.9-6.3c0.6-1.2,0.4-2-1-2.5c-0.6-0.2-1.2-0.4-0.5-1.2c0.5-0.6,0-1.1-0.3-1.6c-0.5-0.7-0.4-1.5,0.1-1.9
	c0.3-0.3,1,0.1,1.4,0.5c0.7,0.6,1.8,1.3,2.5,0.6s1.5-1.8,0.6-3.1c-0.1-0.2-0.8-0.8-0.1-1c2.6-0.8,2.3-3.5,3.5-5.1
	C1026.1,448.7,1026,447.9,1025.1,447.5z" />
                <path onClick={(e) => toggleClass(e, "tuzlanska")} className={filterData.location.state.includes("tuzlanska")?"st1 region_sec active":"st1 region_sec"} data-id="region-3" d="M963.1,372.2c-0.7,1.5-1.8,2.1-3.5,1.9c-2-0.3-4-0.3-4.3,2.5c0,0.2-0.4,0.4-0.7,0.5c-0.4,0-0.3-0.4-0.4-0.6
	c-0.7-2-2.1-3.3-3.6-4.6c-1.6-1.4-2.6-3.5-4-5.3c-1.5-1.9-2.5-3.9-2.5-6.3c0-2.1-1.4-2.5-3.1-3.3c-2.2-1-4.3-2.4-5.8-4.3
	c-0.5-0.6-0.8-1.3-1.3-1.8c-1.4-1.2-2.4-2.6-2.6-4.5c-0.1-1.2-0.9-2.3-1.9-2.6c-3.6-1.4-6.7-4-10.8-3.8c-0.7,0-1.6,0.2-2-0.6
	c-0.5-0.9,0.4-1.3,0.8-1.9c1-1.1,0.7-1.8-0.7-2c-2.7-0.4-3.8-2.3-4.7-4.5c-0.7-1.6-2.1-1.9-3.3-0.7c-1.5,1.4-2.6,1.4-3.5-0.4
	c-1.3-2.7-3.8-4.1-5.7-6.1c-1.4-1.5-2.1-1.2-2.9,0.7c-0.3,0.8-1.1,1.2-1.8,1.4c-2.5,0.8-4.8,0.9-7-1c-1.5-1.3-3-2.6-5.5-1.8
	c-1.9,0.6-4-1.2-5-3.2c-0.2-0.4-0.4-1.2-1-1.1c-1.4,0.2-2.1-0.7-3.1-1.3c-2-1.4-4-1.8-5.9,0.2c-0.9,0.9-1.9,0.9-2.6,0.1
	c-1.5-1.8-4.2-1.7-5.8-3.6c-0.9-1.1-2.2-0.1-2.8,1c-0.7,1.2-1.4,1.8-2.9,1.2c-1.2-0.5-2.7,0.7-2.3,1.8c0.6,1.6-0.5,1.7-1.4,1.8
	c-2.6,0.5-6-1.4-7-3.8c-1-2.5-1.8-2.5-4.4-2.2c-2.3,0.3-4.6,1.5-6.6,1.2c-2.6-0.5-5.3-1.6-6.9-4.3c-0.1-0.1,0-0.4-0.1-0.4
	c-3.5-0.8-3.9-6-8.2-5.9c-0.8,0-3.7-0.2-1.3-2.6c0.4-0.4-0.2-0.9-0.4-1.4c-0.7-1.6-2-2.9-0.9-5.2c0.6-1.2-0.5-3.2-0.8-4.9
	c-0.3-1.7,0-3,0.9-4.1s0.1-2-0.4-2.1c-1.4-0.4-1.9-1.6-2.4-2.7c-1-1.9-2.5-3.2-4.6-3.8c-1.4-0.4-2.3-1.3-3.1-2.8
	c-1.2-2.3-2.9-5.1-5.6-5.5c-1.8-0.3-4.1-1.6-6,0.3c-0.1,0.1-0.4,0.1-0.6,0c-1.9-1.1-3.5-0.6-5,0.8c-0.4,0.4-1,0.3-1.1-0.1
	c-0.2-0.7-1.1-1.6-0.4-2.2c1.6-1.3,1.5-2.4-0.4-3.3c1.4-1.2,2.1-3.2,4.3-2.1c0.4,0.3,0.7,0.6,1.1,0.9l0,0c0.6,0.7,1.2,0.7,1.8,0l0,0
	c0.7-1.2,0.7-2.4,0-3.6c-1-3-2.8-4.6-6.2-4.7c-1.6,0-2.5-2-2.4-3.3c0-1.2-0.5-1.4-1.2-1.7c-0.7-0.4-1.4-1-2.4-0.1
	c-2.6,2.2-5.3,1.3-8-0.1c-0.6-0.3-1.1-0.9-1.9-1c-2.3-0.1-3-1.4-2.2-3.7c0.3-0.8,0.2-1.3-0.4-1.9c-1.5-1.6-1-3.3,1-4.2
	c0.9-0.4,1-0.5,0.5-1.8c-1.3-3.2-2.9-6.3-4.2-9.5c-0.4-0.9-0.8-1.5-2-1.3c-0.6,0.1-1.1-0.1-1.4-0.8c-0.2-0.4-0.7-0.7-1.1-0.5
	c-1.9,0.7-3.8,1.4-5.8,1.8c-0.7,0.1-0.4,0.7-0.2,1c0.6,0.9-0.1,1.4-0.6,2.1c-1.1,1.4-2.2,2-3.9,0.9c-1.4-0.9-2.9-0.4-4.3,0.2
	c-2,0.8-3.8,0.8-5.6-0.5c-1-0.7-1.7-0.6-2.4,0.6c-1.1,2.1-2.4,4.1-5.4,3.4c-1.3-0.3-0.8,0.9-0.6,1.3c0.8,2.1-0.9,3.1-1.8,4.3
	c-0.5,0.6-2.7-0.6-3-1.6c-0.3-1.1-0.7-2.1-0.5-3.3c0.1-1-0.1-1.9-0.4-2.8c-0.8-2.8-2.5-3.2-4.5-1c-0.6,0.6-1.2,1.2-1.5,1.9
	c-0.7,1.9-2.1,2-3.8,2c-2.1,0.1-3.6,1.9-5.3,3c-2.8,1.8-5.9,2.1-9,2.3c-1.2,0.1-2.3,0.5-3,1.2c-2,1.9-3.5,0.5-5.1-0.5
	c-0.3-0.2-0.5-0.9-0.9-0.3c-1.2,1.9-3.2,1.3-4.9,1.4c-1.4,0.1-2.8,0.1-4.3,0.6c-1.7,0.6-1.3,1.5-1,2.2c1.2,2.6-0.2,5.6,1.5,8
	c0.1,0.2-0.1,0.8-0.3,1c-1.4,1.3-1.2,2.7-0.3,4.1c1,1.6,0.5,3-0.2,4.5c-1.6-1-3.4-1.5-5.2-1.5c-0.9,0-0.9,1.2-1.4,1.9
	c-0.1,0-0.3,0-0.4-0.1c-2.4-2.3-2.7-2.5-4.7,0.1c-1.5,2-3.5,0.5-5,1.6c-1,0.6-2.4,0.7-3.6,1c-1.3,0.2-2.2,0.9-3,2
	c-0.6,0.9-1,2-2.5,2.1c-2.3,0.2-3.7-0.5-4-2.7c-0.4-3.3-3.5-3.4-5.4-4.9c-1.4-1.1-2.4,0.5-3.6,0.9c-1.2-1.2-1.7-2.7-2.3-4.2
	c-0.4-1.1-0.8-1.8-2.2-1.4s-2.1-0.6-1.5-2c0.5-1.5,1.2-2.9,1.7-4.4c0.5-1.6-0.4-1.9-1.6-1.6c-3.5,0.9-6.3-1-6.1-4.6
	c0.1-2.1-0.8-3.6-2-4.9c-0.9-0.9-0.7-1.6,0.1-1.8c1.4-0.4,2.3-0.8,1.3-2.4c-0.7-1.2-1.8-1.8-3.3-1.6c-0.4,0.1-0.7,0.2-1.1,0.3
	c-4,1.6-6.5,0.6-8.3-3.2c-0.3-0.7-0.6-1.4,0.3-1.9c1.1-0.7,1.8-1.7,2.6-2.8c0.6-0.8,1.5-0.2,2.3-0.3c0.2,0.5,0.8,0.5,0.9,0.3
	c0.5-1.6,1-3.2,1.4-4.8c-0.1-1.6-0.8-3.2-1.2-4.7c-0.4-1.4,0.2-2.9-1.8-3.6c-0.3-0.1-0.1-1.4-0.3-2.1c-0.1-0.5,1.2-0.5,0.3-1.1
	c-0.5-0.4-0.9,0.1-1.4,0.3c-1.8,0.9-7.1-1.3-7.8-3.1c-0.3-0.8,0.4-1.1,0.8-1.4c1.8-1.4,2-2.9,1.1-5c-1.2-2.9-0.7-6.3-2-9.3
	c-0.1-0.3-0.1-0.8,0.3-1c1.6-0.7,1.6-1.9,1.6-3.5c0-1.4-0.4-1.8-1.3-2.3c-1-0.6-1.5-1.5-0.7-2.6c0.7-0.9,0.3-2.5,1.8-2.9
	c0.4-0.1,0.4-0.5,0.2-0.7c-1.6-0.8-0.4-2-0.1-2.6c0.5-0.9,1.4,0.3,2.2,0.2c0.6,0.6,1,0.3,0.8-0.3c-0.6-2.8,1.2-2.5,2.9-2.4
	c0.5,0,1,0,1.5,0c0.1,0.1,0.2,0.2,0.3,0.4c0.5,2.6,2.3,3.8,4.6,4.7c1.3,0.5,3.4,0.4,4.1-1.1c0.8-1.5,0.8-1.4,2.3-1.1
	c1.3,0.3,2.4-0.7,3.7-0.8c0.7-0.1,1.5-0.5,2.1-0.2c1.9,1.1,2.7-0.6,3-1.6c0.6-2.2,1.7-3.8,3.3-5.3c0.4-0.4,0.4-1.1,0.5-1.7
	c0.2-1.2,0.5-2.5,2-2.9c0.6-0.2,1-0.8,1.2-1.5c0.4-1.9,0-4,1.1-5.9c0.5-0.8-1.5-1.4-2.2-2.4c-0.7-1.3-2.2-2.1-0.4-3.7
	c0.5-0.5,1.6-1.5-0.2-2.3c-0.6-0.3,0.2-2.2,0.5-2.3c1.8-0.4,2.1-2.2,3.6-2.9c2.3-1,3.8-2.7,4.8-5.1c0.4-0.9,2-1.4,2.4-2.8
	c0.2-0.6,0.6-1.1-0.2-1.6c-1-0.6-0.2-1.5,0-2.2c0.6-1.7,0.8-3.2-0.9-4.3c0.4-0.6,1.4-0.6,1.3-1.4c-0.1-0.9-0.9-0.6-1.4-0.8
	c-0.3-0.1-1-0.4-0.9-0.5c0.7-2.2-1.4-3.1-2.2-4.5c-1.4-2.4-1-5-1.5-7.4c-0.5-2.2,0.8-4.1,2.1-5.9c0.3-0.4,1.1-0.7,0.7-1.2
	c-1-1.3-1.9-2.3-0.5-4c0.3-0.4-0.9-0.8-1.4-1.1c-1-0.5-2-0.9-1.3-2.3c0.3-0.6-0.2-0.9-0.5-1.3c-1.3-1.9-1.5-2.1-0.1-3.7
	c1.2-1.3,1-2.5,0.9-3.8c-0.1-0.9-0.2-1.8,1.1-2.2c-1.5-1.4-2.8-0.4-4.1,0.2c-1.3,0.6-1.6,0.2-1.5-1c0.1-1.5,0-3,0.1-4.5
	c0.2-3.3,2.6-5.2,5.1-6.5c1.3-0.7,1.4-0.9,0.8-2c-0.4-0.7-0.2-1.5-0.2-2.2c2.2,1.2,3.3,3.4,4.9,5.2c4.1,4.6,8.4,5.3,13.5,1.7
	c1.1-0.7,2-1.7,3.3-2c1.4-0.3,0.5-1.5,1.2-2.1c1.8-1.5,3.3-3.1,5.2-4.5c1.6-1.2,2.1-2.6,1.1-4.3c-1.4-2.5-1.4-2.7,1.2-3.8
	c1.3-0.6,2.6-1.2,3.9-1.2c2.6-0.1,2.8-1.8,2.9-3.7c0.1-2,0.6-3.8,2.2-5.3c2.6-2.3,5.2-2.3,7.6,0.4c0.8,1,1.4,1.5,2.8,0.2
	c2-1.9,6.7-0.4,7.8,2.1c0.7,1.5,1.7,2.9,3.1,4c-0.9,0.7-1.8,1.4-2.7,2c-1.8,1.2-0.8,2,0.4,2.8c1.3,0.4,2.6,1,4.1,0.8l0,0
	c-0.1,1.5,0.1,3,1.8,3.6l0,0c-0.2,1.5,1,0.7,1.6,1c2.7-2.2,5.6-1.5,8.2-0.2c2.2,1.1,4.2,1.8,6.3,0.6c2.3-1.4,4.6-3,5.4-5.7
	c0.2-0.7,0.4-1.5-0.7-2c-0.7-0.3-1.2-1-1.6-1.8c-0.9-1.8-1.1-3.1,0.8-4.4c3.4-2.4,6.9-3.9,11.2-2.9c1.1,0.3,1.9,0.7,1.6,2.1
	c-0.2,0.8-0.1,1.6,0.4,2.3c0.6,0.9,0.9,1.9,2.5,1.1c1.1-0.5,2.5-0.6,3.4,1.2c1.2,2.6,3.4,2.9,7.4,2.1c4-0.9,8-1.5,11.9-3
	c2.3-0.9,6,1.6,7,4.6c0.7,1.8,1.6,3.3,3.4,4.1c0.2,0.1,0.4,0.3,0.5,0.5c1.8,3,4.4,4.4,7.9,3.7c0.5-0.1,0.7,0.2,1,0.6
	c0.6,0.6,1.3,1.1,2.2,0.1c0.4-0.4,1.1-0.8,1.8-0.6c0.8,0.3,1.9,1.1,2.3,0.4c0.9-1.6,2.2-2.9,2.6-4.8c0.1-0.6-0.2-0.9-0.8-1.1
	c-1.1-0.5-2.1-1.1-3.1-1.6c1.5-2.8,4.5-2.9,6.1-0.3c0.6,1,1.6,1.5,2.1,2.5c0.5,0.9,0.9,1.9,1.3,2.9c0.7-1.2,2.1-2.2,0.9-4
	c-0.9-1.3-2.6-1.7-3.3-3.3c1.1,0,1.8-1.9,3.4-0.5c1,0.9,2.5,1.2,2.9,2.8c0.2,1,1,1.2,2,1.2c1.2,0,1.3-0.8,1.5-1.5
	c0.2-0.5,0.2-1.2,0.5-1.7c0.7-1.1,1.9-1.8,3-1.1c0.8,0.5,1.8,1.5,0.7,2.9c-1.1,1.3-1.9,2.8-3.5,3.5c-1.1,0.5-1.4,1.2-0.9,2.1
	c0.5,0.8,1.5,0.8,2.3,0.2c1.9-1.6,3.6-0.4,5.2,0.5c0.6,0.3,1.1,1.2,1.7,0.7c0.7-0.6,0.8-1.5,0.2-2.4c-1.3-1.8-1.4-3.8-0.5-5.9
	c0.5-1.1,0.9-2.2,2.1-2.1c1.4,0.1,2.7,0.9,3.3,2.3c0.3,0.9,0.3,1.9,0.5,2.8c0.3,1.1,0,1.7-1.1,2.1c-2.4,0.9-2.5,1.9-0.8,3.6
	c1.7,1.7,3.2,3.6,5.3,4.8c0.4,0.2,0.8,0.5,1.2,0.5c2.7-0.4,3.3,1.6,3.9,3.5c0.6,2.1,1.6,4.2,0.9,6.5c-0.2,0.8-0.2,1.7,1,2.2
	c1.1,0.5,1.2-0.5,1.7-0.9c1.9-1.6,3.8-3.2,6.4-1.2c0.5,0.4,1.4,0.2,2.2,0.2c2.1,0,3.1,1.6,2.4,3.6c-0.1,0.3-0.2,0.6-0.4,0.8
	c-2.8,2.9-1.1,5.4,0.5,8c0.5,0.9,1.4,1.1,2.1,1.4c1.5,0.6,1.2,1.5,0.6,2.4c-1.1,1.5-4,1.9-5.6,0.8c-0.7-0.4-1.2-1.1-1.9-1.5
	c-0.9-0.5-2.2-1.4-2.9,0c-0.5,1.1-1.6,2.6,0.8,3.5c1.1,0.4,1.9,1.6,3,2.2c1,0.6,0.1,1.4-0.6,1.7c-1.3,0.5-0.9,1.2-0.3,1.8
	c2,2,3.5,4.6,5.8,6.4c-0.8,1-0.3,2.1-0.5,3.2c-0.2,1.4,0.4,1.7,1.7,1.7s2.1-0.2,1.9-1.8c-0.1-0.9-0.4-2.1,1.2-1.9
	c2,0.9,3.4,2.5,4.5,4.3c1.5,2.3,3.9,3.6,6.5,2.8c2.1-0.6,4.1-0.4,6.2-0.3c2.4,0.6,4.8,0.7,7.2-0.1h-0.1c1,0,1.9,0.1,2.9,0
	c2.6-0.2,5.3,0.5,7.9-0.4c2.8-1.8,6.2-1.1,9.1-2.3c2-0.8,4.4-0.1,6.3-1.6c0.5-0.4,1.1-0.6,1.3-1.3c0.8-2.9,3.2-4,5.8-4.6
	c2.2-0.5,3.6-1.5,3.9-3.7c0.1-0.8,0.4-1.5,1.2-1.3c2.7,0.7,3.3-1.7,4.6-3.1c0.7-0.8,1.1-2.1,1.9-2.6c1.2-0.6,2.4-1.6,4.1-0.8
	c2,0.9,3.6,1.9,3.9,4.2c0,0.4-0.1,0.8,0,1.1c0.3,0.7,0.7,1.5,1.3,1.9c0.5,0.3,0.9-0.6,1.3-1c1.5-1.4,3.1-2.8,4.6-4.2
	c1.4-1.3,2.8-0.3,4.1,0.2c1.7,0.8,3.1,1.1,5.2,0.4c2.1-0.8,5.1-1.1,7,1.6c0.4,0.5,1.4,0.6,2.1,0.8c1.6,0.4,3,1,4.1,2.2
	c0.7,0.8,1.5,0.4,2.4,0.2c0.8-0.2,1.7-0.6,2.2,0.2s-0.6,1-0.9,1.5c-0.9,1.5-0.3,3.6,1.6,4.4c1.1,0.5,1,0.7,0.3,1.5
	c-1.4,1.5-2.4,3.5-5.7,2.2c1.1,1.5,1.6,2.4,2.3,3.2c0.5,0.5,0.6,1,0.4,1.6c-0.2,0.9-1,0.4-1.5,0.5c-0.3,0.1-0.8,0-1.1-0.2
	c-1.7-0.7-2.9-0.6-2.8,1.7c0,0.5-0.2,1.2-0.5,1.4c-1.3,0.5-3.4,0.6-0.8,2.5c0.3,0.2-0.4,0.7-0.7,0.9c-3.2,2.1-3.2,2.1-0.2,4.2
	c-1,2-4.5,1.1-4.7,4.1c1.3,0.2,2.6,1.6,4.1-0.2c0.4-0.5,1.7-0.4,2,0.6c0.3,1.1-0.2,2.1-1.5,2.3c-1.1,0.2-2.8,0.2-2.8,1
	c0,1.3,1.7,1.4,2.8,1.4c3.2,0.2,4,2.7,4.8,5c0.5,1.3-1,2.4-1.8,3.5c-0.6,0.8-1.1,1.4-1.1,2.5c0,1.5-1,2.7-1.8,3.9
	c-1.3,1.9-2.5,3.9-3.8,5.7c-1.4,2.1-4,5.1-1.8,8.4c0.4,0.6-0.2,0.8-0.4,1c-0.4,0.4-0.7,1.1-1.6,0.6c-1.9-1.2-2.8-0.1-3.2,1.6
	c-0.5,2-1.4,4-1,6.2c0.2,0.9-0.6,1.2-0.8,1.8c-0.2,0.6-0.8,1-0.7,1.8c0.2,1.3-0.5,2.2-1.7,2.5c-2.3,0.5-2.4,2.2-1.7,3.7
	c1,2.3-1.3,3-1.9,4.5c-0.2,0.4-0.5,0-0.7-0.1c-2.2-0.7-4.9,1-5,3.2c0,0.8,0.5,1.7,1.5,2c1.4,0.5,1.9,1.5,1.1,2.9
	c-0.5,0.8-0.6,2-1.3,2.5c-1.7,1.2-1.5,2.2,0,3.3c-0.8,0.6-1.5,1.1-2.1,1.7c-1.1,1-2.4,1.3-3.7,0.7c-2.3-1.1-3.7,0.6-5.3,1.6
	c-0.2,0.1-0.5,0.4-0.4,0.5c1.3,1.9-0.6,2.6-1.2,3.8c-0.5,0.8,0.3,1.1,0.7,1.4c1.2,1.2,2,2.6,2.3,4.4c0.4,1.6,0.8,3.2,1.9,4.6
	c1,1.3,1.7,2.9,0.8,4.7c-0.5,1.1,0.2,2.3,0.6,3.4c0.3,0.8,0.5,1.5,0.6,2.3c0.5,2.8-0.7,4.6-3.1,5.9c-1.5,0.8-3,1.7-4.6,2.6
	c-0.3,1.5-1.3,2-2.7,1.7c-0.9-0.2-1.5,0.2-1.3,1.2c0.2,0.8-0.6,2.5,0.8,2.3c3.2-0.4,2.9,1.4,2.8,3.4c0,0.6-0.6,1.8,0.9,1.6l0,0
	c-0.2,1.4,0.4,2.7,0.8,4.1c0.6,1.9,2.3,2.4,3.8,3c1.8,0.7,2.1,1.7,1.1,3.1c-0.8,1.1-0.4,2.1,0.2,2.8c1.7,2.2,3.6,4.2,5.4,6.3
	c0.2,0.2,0.4,0.4,0.6,0.6c0.8,0.7,0.8,2.7,2.1,2.3c1.6-0.5,3.4-1.1,4.5-2.6c0.4-0.6,1.2-1.6,1.3-1.4c0.7,1.6,3.3,0.9,3.4,3
	c0,1.9,0.5,3.4,2.1,4.7c1.8,1.4,5.5,1.2,6.8-0.6c0.2-0.3,0.3-0.7,0.6-0.9c0.9-0.8,1.2-2.6,2.5-2.5c0.5,0,1.8,0.9,2.5,1.9
	c0.5,0.8,2,1,2.8,0.1c1.2-1.4,2.8-1.1,4.1-0.7c1.4,0.5,1.7,2.1,1.6,3.6c-0.2,1.3,0.4,2.2,1.3,3.1c1.8,1.8,3.1,3.7,2,6.6
	c-0.5,1.2,0.5,2.3,1.8,2.8c0.9,0.4,1.7,0.9,1.3,1.9c-1.5,4.1,0.9,6.4,3.9,8.2c2.5,1.5,4.9,3.3,7.7,4.3c1.6,0.6,1.7,2.7,2.9,3.9
	c1.1,1.1,2.4,1.9,3.6,2.8c0.6,0.5,1.1,0,1.4-0.7c0.5-1.3,1.3-2.5,1.9-3.7c1-2.1,2.1-0.5,3.1-0.1c1.1,0.4,2.5,0.9,1.2,2.6
	c-0.2,0.2-0.3,0.5-0.4,0.8c-1.8,3.7-1.7,4.2,2.2,5.1c0.8,0.2,1.2,0.6,1.3,1.2c0.7,3.7,3.7,5.8,5.8,8.5c0.8,1,3,1.1,4.6,1.1
	c3.3-0.1,4.9,1.8,6.1,4.5c0.4,0.9,0.1,2.6,1.8,2.5c0.9-0.1,1.6-3.4,1.2-4.5c-0.2-0.7-0.7-1.3-0.1-2c0.9-1.2,2.1-1.2,2.7,0.1
	c0.7,1.4,1.6,2.6,1.8,4.2c0.1,1.2,0.8,1.3,1.7,0.7c0.8-0.5,1.6-0.7,2.3,0.2c0.9,1-0.6,1.2-0.7,2c0,0.6-0.7,1.2-1.2,1.7
	c-0.8,0.9-0.7,1.5,0.2,2.4c1,0.9,1.7,2,2.6,3c-0.9,1.1-2,1.7-3.3,2.1c-3.1,0.9-5.6,3.1-8.7,4c-1,0.3-0.6,1-0.1,1.5
	c1.3,1.6,0.9,2.3-1,3.1c-2.3,0.9-4.9,1.8-4.9,5.3c0,1.9-2,2.8-3.7,3c-4.4,0.6-8.8,1.1-13.1-0.7c-1.2-0.5-2.4,0.3-3.6,0.7
	c-2.7,0.7-3.8,0.3-5.2-2.2c-0.7-1.2-1.1-2.1-1.9-0.1c-0.6,1.3-1.4,1.5-2.8,0.7c-4.4-2.4-8.9-4.6-13.5-6.7c-2-1-4.3-0.8-6.4-0.5
	c-3.5,0.6-5.8-0.9-7.9-3.4c-0.5-0.6-1-1.4-2-1.1c-0.9,0.3-1.8,0.3-1.3,1.9s1.3,3.2,0.7,5c-0.4,1.1,0.7,2.1,1.5,2.8
	c1.1,1.1,1.6,2.4,2.3,3.7C963.8,370.4,963.8,371.3,963.1,372.2z" />
                <path onClick={(e) => toggleClass(e, "banjalucka")} className={filterData.location.state.includes("banjalucka")?"st0 region_sec active":"st0 region_sec"} data-id="region-4" d="M666.5,410.8l3.5-8.1c-1.3-1-2.2-2.3-2.8-3.6c-0.5,0.1-1,0.2-1.5,0.2c-1.1,0-2.8-0.3-4.6-1.6
	c-0.7,0.2-1.3,0.3-2,0.3c-3.8,0-6.4-3.1-7.4-5.7c-0.4-1-0.8-1.5-1.8-1.9c-3.1-1.4-4-3.7-4.3-4.9c-0.1-0.3-0.1-0.7-0.1-1.2
	c-0.1,0-0.1,0-0.2,0c-3.2,0-5.2-1.9-6.2-2.8c-0.1-0.1-0.2-0.2-0.3-0.3l-5.2-3.5l2.3-2.8c-0.4-0.8-0.6-1.5-0.8-2.2
	c-0.7-0.4-1.4-0.8-2.1-1.3c-2.3-1.1-3.3-2.6-3.8-4c-1.8-1-3.2-2.4-4-4.4c-0.3-0.8-1.2-2.6-1-5c-1.3-0.8-2.4-1.8-3.2-3.2
	c-0.7-1.2-1-2.5-1-3.8c-0.9-1-1.6-2.2-2.2-3.8c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.3-0.5-0.7-0.7-1c-0.7-1.1-1.2-1.8-1.7-2.1
	c-3.2-2.1-4.8-5.3-4.3-8.8c-2.4-0.6-4.6-1.8-6.7-3.6c-0.2-0.1-0.9-0.3-1.3-0.4c-1.2-0.3-2.7-0.8-4.3-1.7c-1.1-0.6-4.3-2.6-4.8-6.4
	c-0.2-1.7,0.2-3.3,1.2-4.9c-0.6-0.1-1.1-0.2-1.7-0.5c-1.1-0.5-3.8-1.6-5.6-4.6c-0.4-0.8-0.8-1.5-1-2.2l0,0l-7-1.3l4.3-7
	c0.3-0.4,0.5-0.8,0.7-1.3c0.6-1,1.2-2.1,2-3.2c-0.1-0.4-0.1-0.8-0.1-1.1c0-0.2,0-0.4-0.1-0.5c-1.4-3.2-2.3-6.8-2.3-10.2
	c0-2.2,0.8-4.2,1.8-5.7c-0.8-1.6-1.3-3.2-1.5-4.9c-0.3-3.3,1.2-6.1,4.2-7.7c1.1-0.6,2.2-0.8,3.3-0.8c1.5,0,3.6,0.5,5.4,2.9
	c0.8-1.5,2.1-2.6,3.7-3.2c0.1-0.2,0.2-0.5,0.4-0.7c0.2-0.5,0.4-0.9,0.7-1.4c2.6-4.7,6.2-5.4,8.1-5.4c2,0,4,0.7,6,2.1
	c0.8,0.6,1.8,1.1,2.8,1.7s2.2,1.2,3.3,2c0.2,0,0.4,0,0.6,0c1.9,0,3.6,0.7,4.7,2.1c1.6,1.8,1.6,3.9,1.5,5.4c0.4,0.2,0.7,0.3,1,0.5
	c0.3,0.1,0.6,0.3,0.8,0.4c0.4-0.1,0.8-0.1,1.2-0.1c-0.6-1-1.2-2.9-0.4-5.4c0.6-1.9,1.9-3.4,3.5-4.2c-1.1-0.5-2.1-1.3-2.8-2.3
	c-1.1-1.6-1.5-3.7-1-5.8c-1.3-0.5-2.4-1.3-3.3-2.3c-1.9-2-2.9-4.8-2.7-7.8c0,0-0.1-0.2-0.5-0.6c-1-1-1.5-2-1.8-3c-0.1,0-0.1,0-0.2,0
	c-3.1,0-7.3-1.2-10.2-7.2c-2-4.2-0.2-7.6,2.4-9.2c0.1-0.2,0.4-0.5,0.6-0.8l0.4-0.5c0.8-1.1,1.9-1.8,3.1-2.3
	c-0.1-0.2-0.1-0.5-0.2-0.7c-0.2-0.6-0.3-1.1-0.5-1.7c-0.1-0.3-0.1-0.6-0.2-0.8c-0.4-0.4-0.9-0.9-1.2-1.6c-3.5-0.8-8-3-9.3-6.7
	c-0.8-2.1-0.7-4.9,1.7-7c-0.6-1.8-0.8-3.6-1-5.1c-0.1-1.3-0.3-2.4-0.6-3.1c-0.7-1.6-0.8-3.4-0.2-5c0.2-0.5,0.4-1,0.7-1.4
	c-0.7-0.9-1.2-2-1.4-3.1c-0.3-1.8,0-3.6,1-5.1c0.1-0.5,0.3-0.9,0.5-1.3c-0.2-2.5,1.1-4.4,1.6-5.1V174c0.9-1.4,2.2-2.4,3.7-2.8
	c1.4-1.6,3.4-2.4,5.9-2.4c0.4,0,0.8,0,1.2,0h0.3c0.2,0,0.4,0,0.5,0c0.2,0,0.4,0,0.6,0l2.8-0.1l1.6,2.1c0.7,0.8,1.1,1.6,1.4,2.5
	c1-1,2.4-1.8,4.4-1.8c0.5,0,1,0.1,1.4,0.1c0.7-0.3,1.6-0.6,2.7-0.7h0.1c0.3-0.1,0.7-0.2,1.2-0.2c0.7-1.5,1.6-2.9,2.8-4.2
	c0.2-1.2,0.9-3.9,3.2-5.7c0-0.6,0.1-1.2,0.2-1.9c-0.3-0.3-0.5-0.7-0.8-1.1c0,0-0.1-0.1-0.1-0.2c-2.4-3.2-2.1-5.8-1.2-7.7
	c-0.2-0.7-0.2-1.5-0.1-2.3c0.3-2.4,1.5-5,4.1-6.1c0.7-0.8,1.8-2.1,3.6-2.9c0.7-0.3,1.3-0.8,1.7-1.9c0.4-0.9,0.9-1.7,1.4-2.2
	c-0.1-0.9-0.1-2,0.3-3.1l-3.3-2.2l1.8-2.3c-0.2-0.5-0.2-1.1-0.3-1.5c-0.4-0.5-1-1.1-1.4-1.8c-1.6-2.7-1.8-5.3-2-7.3
	c-0.1-0.7-0.1-1.3-0.2-1.8c-0.8-3.5,0.5-6.5,1.7-8.6c-0.1-0.5-0.2-0.9-0.3-1.4c-1.7-1.6-2.2-3.4-2.2-4.9c-0.9-1.5-1.9-3.8-0.9-6.5
	c-0.5-0.3-1-0.7-1.4-1.1c-1.3-1.4-1.9-3.2-1.7-5.4c0-0.7,0-1.3,0.1-2c0-0.8,0-1.7,0.1-2.5c0.3-5,3.3-8,5.7-9.7c0-0.3,0-0.5,0.1-0.7
	v-0.2l0.1-0.9c-2.9-2-5.8-3.9-8.1-6.7c-0.5-0.7-1-1.7-2.1-1.5c-3.6,0.5-5.3-1.9-7.1-4.2c-0.4-0.5-0.5-1-1.2-1.2
	c-1.4-0.3-6.3,1.4-6.7,2.7c-0.7,2.3-1.9,3.4-4.3,3.2c-0.5,0-0.5,0.5-0.7,0.8c-1.8,2.3-1.8,2.3-4.3,0.4c-1.3-1-2.5-2-3.8,0.1
	c-0.5,0.9-1.6,0.8-2.4,0.4c-0.9-0.5-0.7-1.4-0.3-2.2c0.2-0.4,0.3-0.8,0.4-1.3c0.2-1.1-0.1-2.1-1.2-2.4c-1.4-0.4-1.1,0.8-1.3,1.6
	c-0.7,2.8-4.4,4.7-6.9,3.5c-1.2-0.6-2.1-0.3-3.2,0.1c-0.7,0.3-1.4,1.5-2.3,0.4c-0.7-0.9,0.2-1.5,0.7-2.1c1.2-1.6,1.6-3.2,0.6-5
	c-1.8,0.3-2.6,2.2-4.2,2.7c-1.1,1-2.6-0.2-3.7,0.7c-0.2-1.1-1.4-2.1-0.3-3.4c1.7-1.5,1-3.3,0.4-5c-0.3-0.8-1.1-0.9-1.8-1.1
	c-0.9-0.3-0.8,0.6-1.1,1c-0.5,0.9-0.9,1.8-1.4,2.7c-1.6,3-4.2,3.9-7.5,2.7c-2.2-0.2-4.5,0.4-6.7-0.4c-0.7,0-1.3,0-2-0.1
	c-1.8-0.2-4.1-2.8-3.8-4.2c0.6-3,0.3-3.3-2.6-2.9c-1.5,0.2-1.7-0.1-1.3-1.4c0.4-1.3-0.7-2.2-2-1.5c-2.4,1.4-3.5,0.2-4.3-1.8
	c-0.3-0.6-0.6-1.4-1.2-1.5c-0.7,0-1.1,0.8-1.4,1.5c-0.3,0.7-0.5,1.5-0.7,2.3c-0.7,2.3-1.1,4.8-2.7,6.8c0,0.4,0,0.8,0.5,0.8
	c2.4,0,1.1,1.8,1.2,2.7c0.1,1.6-1.2,0.7-1.9,0.9c-1.5,0.4-1.7-0.5-1.6-1.7c0.1-0.6-0.1-1-0.8-0.9c-2.4,1.4-4.9-0.9-7.4,0.1
	c-0.4,0.2-1.2-0.2-1.7-0.4c-0.4-0.1-0.7-0.2-1.1-0.1c-1.5,0.6-2.6-0.4-3.3-1.3c-0.7-0.9-1.5-1.4-2.3-1.9c-3.8-2.4-7.8-4.3-11.8-6.3
	c-2.3-1.1-3.6-3.4-5.9-4.4c-1.6-0.7-2.2-2.2-2.5-3.6c-0.2-1.5-0.9-2.6-2.2-3.6c-0.5,0.8-1.4,1.3-2.2,1.7c-0.7,0.4-1,1.9-2,1
	c-1.1-1,0.1-1.8,0.6-2.7c0.1-0.3,0.2-0.6,0.4-0.8c1.8-1.6,0.6-2.2-0.9-2.7s-2,0-1.7,1.4c0.3,1.9-0.4,3-2.4,3c-1.6,0-1.2-1.4-1.4-2.5
	c-0.2-1,0.7-2.1-0.2-3.1c-2-2.3-2-2.4-5-0.5c0.8,0.5,1.5,1,2.2,1.4c0.9,0.6,1.4,1.3,0.8,2.4s-1.4,0.8-2.2,0.3
	c-0.7-0.4-1.4-0.9-2.4-0.4c-2.5,1.3-2.4,1.3-2.6-1.6c-0.1-1.6,0.3-2.6,1.7-3.4c1.9-1.2,1.9-2,0.2-3.4c-0.6-0.5-1.7-1-1.6-1.9
	c0.2-1.9-1.5-2.3-2.5-2.4c-0.9-0.2-1.8,0.8-1.9,2.3c-0.4,4.5-1.5,5-5.3,3c-0.7-0.4-1.3-0.3-1.7,0.4c-0.4,0.7,0,1,0.6,1.4
	c0.3,0.2,1.3,0.1,0.9,1c-0.3,0.7-0.8,0.7-1.5,0.3c-0.5-0.3-1-0.1-1.4,0.4c-0.4,0.6-0.2,1.2,0.3,1.5c1.2,0.6,0.3,1.4,0.1,1.9
	c-0.3,0.8-0.9,0.3-1.5,0.1c-0.3-0.1-0.9-0.7-1.1-0.1c-0.9,2.2-2,0.4-3.1,0.2c-0.5-0.1-1.1-1.2-1.7-0.2c-0.4,0.7-1,1.6-0.2,2.2
	c1.1,0.8,0.2,1.3-0.2,1.5c-1.6,0.7-0.8,1.6-0.2,2.4c0.8,1.1,1.6,2.2,0.3,3.5c-0.3,0.3-0.3,0.7-0.1,1c0.4,0.8,1.1,1.6-0.1,2.2
	c-1.1,0.6-2.2,2.1-3.7,0.7c-1.3-0.1-2.6-0.2-2.2,1.8c0.3,1.6-0.9,1.3-1.8,1.3s-2,0.3-1.8-1.4c0.1-1.2,0.5-2.6-1.3-3.1
	c-1.5,0.1-2.5-0.7-3.5-1.6c-0.7-0.7-1.5-1.2-2.5-1.2c-1.1,0.1-2-0.3-2.8-1c-1.3-1.1-2.4-0.8-3.8-0.1c-1.4,0.7-2.8,2.4-4,2.2
	c-4.2-0.6-8.2-2-12.2-3.3c-1.9-0.6-3.1-1.9-4.4-3.4c-2.1-2.5-4.6-3.1-6.4-1.5c-3,2.7-6,2.5-9.3,1c-0.6-0.3-1.1-0.9-2-0.3
	c-3.1,2.1-7.8,2.8-7.4,8.1c0,0.1,0,0.3-0.1,0.4c-2.1,1.9-2.5,4.1-2.8,7.1c-0.5,6.8-4.4,11.6-11.2,13.6c-1.8,0.5-3.1,1.7-4.4,2.8
	c-1.2,0.9-1.6,2.1-0.4,3.4c0.4,0.4,0.8,0.9,0.4,1.4c-1,1.3-0.1,3.4-1.9,4.4c-0.2,0.1-0.3,0.6-0.2,0.8c1.5,3.4-0.9,5.7-2.3,8.2
	c0.2,0.6,0.8,0.3,1.2,0.4c0.7,0.2,1.7-0.5,1.9,0.5s0.6,2.3-0.2,2.9c-0.6,0.5-1.8,0.2-2.8,0.1c-0.6,0-0.4-0.7-0.5-1.1
	c-0.1-0.7,0.4-1.5-0.4-2c-1.3,0.8-1.9,1.7-2,3.4s-0.2,3.4-0.4,5.1c-0.4,3.1-1.4,5.1-5.1,4.6c-1.8-0.3-3.3,0.2-5,0.7
	c-1.3,0.4-3.5,0.5-4.4-1.1c-1-1.9-3.4-2.3-4.2-5c-0.9,2.7-2.1,2.9-3.8,1.7c-0.3-0.2-0.8-0.3-1-0.2c-1.9,1.5-2.6-0.2-3.4-1.3
	c-0.4-0.6-0.9-1.4-1.5-1.6c-1.3-0.4-2.1-2.9-4-1c-0.5,0.5-0.8-0.3-0.9-0.8c-0.7-2.7-3.2-4.6-3.7-7.5c-0.2-1.1-0.9-1.9-0.1-3
	c0.1-0.2,0.2-0.3,0-0.5c-2.3-1.6-4.8-2.9-6.7-5c-0.1-0.1-0.5-0.2-0.5-0.2c-1.4,1.9-2.8,0.2-4.2-0.1c-3-0.7-4.1-3.7-6.4-5.2
	c-0.6-0.4-0.2-1.5,0-2.3c0.3-1.9,0.6-3.5-0.8-5.5c-1.8-2.7-5.1-4.4-5.4-8.2c0-0.2-0.5-0.5-0.8-0.6c-1.8-0.7-2.3-1.6-0.3-2.9
	c-2.4-1.1-4.5-1-6.6,0.2c-0.3-0.9,0.3-1.1,0.6-1.4c2.2-2.7,2.2-2.9,0.2-5.5c0-0.1-0.1-0.1-0.1-0.2c0-3.2-2.8-3.2-4.5-4.6
	c-3-2.4-4.9-1.7-7.6,0c-0.8,0.5-1.5,1.1-2.3,1.7c-1,0.7-2.2,1.6-3.2,1.2c-1.3-0.5-0.9-2-0.6-3.2c0-0.1,0.2-0.3,0.2-0.4
	c-0.1-0.8-0.7-1.1-1.2-1.5c-0.7-0.5-0.5,0.4-0.8,0.6c-0.5,0.4-1,0.6-1.4,0.2c-1.5-1.2-2.7-0.4-4,0.4c-0.1,0.7,1.5,0.8,0.7,1.7
	c-0.6,0.8-1.2-0.4-1.7-0.2c-1.4,0.5-3-1-4.3,0.2c-0.9,0.9-1.5,0.6-2.1-0.2c-0.9-1.5-2-0.7-3-0.2c-0.9,0.5-2.2,0.8-1.2,2.4
	c0.4,0.7-0.2,1.5-0.7,2c-0.6,0.7-1.2-0.1-1.7-0.4c-0.9-0.5-1.7-0.3-2.3,0.4c-1.2,1.4-1.5,4-4.2,3.8c-1.7,3.3-4,6.7-3,10.3
	c0.9,3.5,1,7.5,3.4,10.6c0.6,0.7,0.8,1.7,0,2.4c-1.4,1.2-1.6,2.7-1.3,4.3c0.6,3-1.1,4.8-3.1,6.4c-1,0.8-2,1.5-3,2.2
	c-0.6,0.4-1.1,1.2-0.4,1.7c2.5,1.6,1.9,4.4,2.9,6.6c1.3,2.7,0.9,6.1,3.8,8.1c0.7,0.5,1.3,2.1,0.9,2.8c-1,2-0.4,4-0.6,5.9
	c-0.1,1.2-0.7,1.3-1.7,0.9c-2.5-0.9-5.8,1.6-5.1,4.1c0.4,1.5,0,2.3-0.8,3.3c-0.9,1.1-1.7,2-0.9,4c2.1,5.7,5.2,11.1,4.8,17.4
	c-0.1,0.7,0.4,1.2,0.9,1.5c1.7,0.9,2.8,2.5,4.1,3.7c0.5,0.5,1.5,0.9,1.2,1.7c-0.3,1-1.4,0.6-2.2,0.7c-0.3,0-0.6,0.1-0.9,0
	c-3.5-1.3-6.5,0.2-9.4,1.8c-1.5,0.9-2.6,4.2-1.7,5.3c3.6,4,6.4,8.6,9.5,13c0.9,1.2,1.6,2.4,1.7,3.9c0.2,2,1,3.4,3.5,3.6
	c1.4,0.1,3.7,1.1,3.5,3.3c-0.2,2.9,2,3.7,3.7,5c0.7,0.5,1,0.1,1.4-0.6c0.8-1.5,1.5-3,2.4-4.5c0.7-1.2,1.3-1.2,2.4-0.7
	c2.9,1.3,3,1.3,4.1-1.8c0.3-0.7,0.8-1,1.2-1.6c0.7-1.1,1.4-0.6,1.8,0.2c1.8,3.7,4.3,6.9,6.3,10.6c0.9-1.6,2-0.9,3.4-0.5
	c1.7,0.5,0.2,1.3,0.4,1.9c0.2,1.1,0.6,2.1,1.6,2.6c1.6,0.8,2.3,1.8,2.2,3.6c-0.1,1.1,0.2,2.8,1.8,2.8c1.8-0.1,2.4,0.8,3.1,2.1
	c1,1.8,3.4,2.5,5.5,1.8c0.9-0.3,1.8,0.2,2.1,1.1c0.6,2.1,2.6,3.6,2.8,5.9c0,0.4,0.4,1.1,0.6,1.1c2,0,1.3,1.6,1.8,2.6
	c0.8,1.4-0.4,2.1-0.6,3c-0.5,2.4-1.4,4.6-4.4,5c-1.9,0.3-2.4,1.6-1.9,3.6c0.2,0.6,0.8,1.2,0.4,1.9c-1.1,1.9,0.1,3,1.3,3.9
	c1.2,0.9,2.1,2.1,3.8,2.4c4.9,0.9,8.8,3.4,11.5,7.6c0.7,1.1,1.1,2.1,2.4,2.8c0.8,0.4,1.2,1.2,1.5,2.3c0.4,1.9,0.7,3.9,2.5,5.2
	c0.9,0.7,1.3,1.4,0.4,2.6c-1.2,1.5-0.7,4.4,0.7,6.1c0.9,1,1.3,2-0.5,2.6c-0.6,0.2-1.4,0.6-0.5,1.3c0.9,0.7,1.1,1.4,1,2.6
	c-0.2,1.2,0.7,2.2,1.9,2.4c2.1,0.3,2.7,1.9,3.5,3.5c0.4,0.8,0.5,1.6-0.3,1.9c-1.2,0.4-1.5,2.5-3.2,1.5c-0.8-0.4-1.5-0.9-2.1,0.2
	c-0.3,0.4-0.9,0.1-1.4-0.2c-1.2-0.7-1.8,0.5-1.5,1c0.8,1.3,0.9,3.1,2.4,4c0.6,0.3,1,0.2,1.4-0.1c0.9-0.7,1.8-0.5,2.6,0.1
	c2,1.5,4.6,1.9,6.5,3.8c1.2,1.1,3.1,1.6,4.6,3c1.3,1.2,1.1,1.7,0.3,2.8c-1.7,2.5-3.8,4.8-4.2,7.9c-0.3,1.8-2,3.4-0.7,5.4
	c0.8,2.7,0.3,5.4,0.3,8.1c0.7,3.1,3.2,4.9,5.2,7.1c2,2.3,1.7,4.1-1.1,5.1c-2.4,0.8-4.8,1.8-7.2,2.6c-0.6,0.2-1,0.4-0.8,1
	s0.5,0.9,1.4,0.6c1.1-0.4,2.3-0.5,3.4-0.8c3.1-0.8,6.5,0.4,8.5,2.9c3.3,4.2,7.2,8,10.9,11.9c1.5,1.5,2.1,3.9,4.3,4.8
	c0.1,0,0.2,0.5,0.1,0.6c-1.3,1.9-0.3,3.2,1.1,4.6c1,1.1,1.8,2.3,3.4,0.9c0.5-0.4,1-0.4,1.5-0.3c2.2,0.5,4.4,1,6.6,1.5
	c1,0.2,1.7,0.7,1.5,1.9c-0.5,2.5,1.1,4.3,2.2,6.3c0.2,0.4,0.4,0.6,0.8,0.4c2.3-1,4.5,0.1,6.8,0.2c1.5,0,3.8,3.3,3,4.4
	c-1.7,2.4-1.2,5.1-1.5,7.7c-0.2,2.4,0.3,2.7,2.8,2.5c0.4,0,0.9,0.1,1.2,0.3c0.9,0.5,1.6,0.5,2.3-0.3c0.5-0.5,7,0.2,7.5,0.7
	c0,0.1,0.1,0.1,0.1,0.2c0.7,1.6,2,2.8,3.4,3.8c0.6,0.5,0.7,0.8,0.4,1.4c-0.6,1.3-0.2,2.4,0.4,3.6c1.4,2.7,3.2,5,5.2,7.3
	c2.5,0.8,3.3-1.7,4.9-2.6c1.1-0.6,1.7-1,2.8-0.2c1.5,1.2,2.8,0.9,4.4-0.1c3-2,6.1-3.7,7.7-7.2c0.9-1.9,1.2-3.4,0.3-5.2
	c-0.9-1.8-0.1-4.5,1.6-5.5c2.2-1.2,4.4-2.4,6.8-3c1.3-0.3,2.7-0.3,3.6,1.5c1.7,3.2,3.8,5.9,7.6,7.1c2.1,0.7,3.8,2.4,5.6,3.8
	c1.8,1.3,3.4,2.4,5.7,0.9c1.2-0.9,2.1,0.3,3.2,0.8c1.8,1,2.8,2.2,3.3,4.2c0.5,1.8,2.2,3,3.2,4.6c1.6,2.6,3.7,2.6,6.4,2.3
	c3.5-0.5,6.8,1.2,9.9,2.6c1.8,0.8,2.3,3.2,2.4,4.9c0.1,4.5,2.8,7.8,4.5,11.6c0.3,0.7,0.8,0.5,1.3,0.6c2,0.3,3.6,1.2,5,2.5
	c0.1,0.1,0.3,0.4,0.4,0.4c2.2-1.2,4.2-0.3,6.2,0.6c0.1,0.1,0.5-0.1,0.6-0.2c2.4-2.2,5.7-2.9,7.8-5.5c0.7-0.8,1.4-1.2,2.3-0.2
	c0.7,0.8,1.8,1.5,1.9,2.7c0.2,2.1,1.5,2.3,3.1,2.3s3.3-0.1,4.7,0.9c0.5,0.4,1.1,0.3,1.4,0c1.8-2.2,5.3-1.3,7.1-3.7
	c0.9-1.2,2.1-1.1,2.7,0.5c0.4,0.9,0.8,1.1,1.7,0.6c1.1-0.6,2.3-0.9,3.5-1.3c1.3-0.5,2.5-0.8,3.7,0.3c1.5,1.3,3,2.4,4.6,3.6
	c2.4,1.7,2.2,4.5,2.6,6.9c0.4,1.9,0.8,3.7,1.7,5.4c0.9,1.7,1.8,3.4,1.5,5.4c-0.2,1.2,0.9,1.8,1.6,2.6c1.7,2.1,4.3,1.7,6.4,2.7
	c0.9,0.5,2.3,0.1,3.4-0.1c3-0.6,4.8,1,6.2,3.4c0.4,0.6,0.6,1.5,1.6,1.7c1.1,0.2,2.2,0.6,2.3-1.1c0.1-1,0.9-1.2,1.5-1.5
	c2.4-1.1,2.7-1.6,1.6-4c-0.5-1-0.3-1.9-0.3-2.8c-0.1-1.1,0.2-2.3-1-3c-0.6-0.4-0.4-1,0-1.3c1.8-1.5,0.6-3.3,0.1-4.7
	c-0.7-2.5-0.8-3.8,1.5-4.7c3.2-1.3,5.2-3.4,6.3-6.5c0.1-0.4,0.3-0.8,0.8-0.9c2.9-0.8,5.5-2.7,8.7-1.4c0.6,0.3,1.5,0.1,2.2,0.1
	c2.2-0.2,3.6,0.6,4.8,2.6c1.5,2.6,3.4,3.3,6,0.8c0.9-0.8,1.9-1.6,2.9-2.4c0.4,0.7,0.8,1.4,1.3,2c0.3,0.5,0.7,0.8,1.3,0.2
	c0.1-0.1,0.1-0.1,0.2-0.1c2.6-0.8,5.6,1,8-1.3c0.5,0.6,1.4,0.2,1.9,1c0.8,1.2,1.7,1.5,3,0.5c0.6-0.5,1.6-0.6,2,0.6
	c0.4,1.5,1.9,1.9,3.1,2.3c3.1,1.1,6.1,2.4,8.6,4.6c1.7,1.5,2.5,0.4,2.7-1.1c0.3-2.1,1.3-1.9,2.8-1.3c1,0.5,3.1-0.7,3.1-1.9
	c0-1,0.4-1.8,0.8-2.7c0.3-0.6,0.7-0.9,1.2-0.7c2.5,1.2,3.7-1.3,5.5-1.9c0.7-0.3,1.2-1.2,2.1-0.5C666,410.5,666.3,410.6,666.5,410.8z
	" />
            </svg>


        </>
    )
}

export default Map