import React, { useEffect, useState } from 'react'
import logo from "../images/0_mobi_logo.svg"
import userIcon from "../images/29_user_profile_page.svg"
import Query from "../images/30_why_sell_with_us.svg"
import mobiLgo2 from "../images/31_about_us.svg"
import mobiLgo from "../images/Group.svg"
import Price_tag from "../images/33_price.svg"
import Service from "../images/32_user_support.svg"
import Ask_Question from "../images/34_help_yellow.svg"
import grn from "../images/Filled Circle green.svg"
import rd from "../images/Filled Circle red.svg"
import ylw from "../images/Filled Circle yellow.svg"
import coin from "../images/37_credits_yellow.svg"
import add from "../images/39_new_listing.svg"
import Login from './Login'
import WhyUs from './WhyUs'
import {  useNavigate } from 'react-router-dom'
import { BASE_HREF, BASE_URL ,lan} from '../config'
import $ from "jquery";
import { toast } from 'react-toastify'
import { getCmsData, getPostData, getUserData, setCurrentProduct, setModal, setMode } from '../Redux/ActionCreater'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import AddListing from './AddListing'
import CreditModal from './CreditModal'
import Paymentmodal from './Paymentmodal'

function Sidebar() {
	
	const dispatch = useDispatch();
    const navigate = useNavigate();
	const [data,setData]=useState("");
	const [cmsPage,setCmsPage]=useState("");
	// const [cmsData,setCmsData]=useState({})
	const { userData,cmsData} = useSelector(
		(state) => ({
			userData: state.dataReducer.userData,
			cmsData:state.dataReducer.cmsData
		}),
		shallowEqual
	  );
	useEffect(()=>{
		let localUserData=localStorage.getItem("login_data")
		if(localUserData){
			// setData(JSON.parse(localUserData))
			dispatch(getUserData(JSON.parse(localUserData)));
		}
		$(".closeMnu").click(function(){
			$("body").removeClass("openMenu");
		});
	},[])
const logOut=()=>{
	// setUserData("");
	dispatch(setMode(""));
	dispatch(getUserData(""));
	localStorage.removeItem("login_data");
	localStorage.removeItem("access_token");
	dispatch(setCurrentProduct(""));
	toast.success(lan.logout_success);
	dispatch(getPostData({preData:[]}));
	navigate("/")
}


useEffect(()=>{
	setData(userData)
		},[userData])
		useEffect(()=>{
			setCmsPage(cmsData)
				},[cmsData])
  return (
    <>
    <div class="leftPart">
	<div class="leftpartTop">
		<div class="lftprtLgo">
			<a href={BASE_HREF} onClick={(e)=>{e.preventDefault();dispatch(setCurrentProduct(""));navigate("/");dispatch(setMode(""));}}><img src={logo} alt="" /></a>
		</div>		
		<div class="lftuser lftusrpdd">
			
			{data?<div class="media" style={{cursor:"pointer"}}>
				{/* <figure style={{height:"40px"}}><img className='userImg' src={data.profile_pic?`${BASE_URL}/uploads/user/profile_pic/${data.profile_pic}`:userIcon} alt="" /></figure> */}
				<figure style={{height:"40px"}}><img className='userImg' src={userIcon} alt="" /></figure>
				<div class="media-object">
					<h4 onClick={()=>{dispatch(setCurrentProduct(""));navigate(`/profile`)}}>{data.username}</h4>
					{/* <h4>{data.username}</h4> */}
					<strong onClick={logOut}>{lan.logout}</strong>	
				</div>
			</div>:<>
			<button class="userModal" data-bs-toggle="modal" data-bs-target="#exampleModal"></button>
			<div class="media">
				<figure><img src={userIcon} alt="" /></figure>
				<div class="media-object">
					<h4>{lan.user}</h4>
					<strong>{lan.account}</strong>
				</div>
			</div>
			</>}
		</div>
		{data&&data.role?.role_slug==="seller"&&<div class="lftuser lftusrpdd" data-bs-toggle="modal" data-bs-target="#addmodal23">
			<div class="media" style={{cursor:"pointer"}}>
				<figure style={{height:"40px"}}><img className='userImg' src={coin} alt="" /></figure>
				<div class="media-object">
					<h4>{lan.credits}:  {userData.total_credit}</h4>
					<strong>{lan.buy_credit}</strong>
				</div>
			</div>
		</div>}
		{data&&data.role?.role_slug==="seller"&&<div class="lftuser lftusrpdd">
			
			<div class="media" style={{cursor:"pointer"}} data-bs-toggle="modal" data-bs-target="#sortmodal3" onClick={()=>{dispatch(setModal(true))}}>
				<figure style={{height:"40px"}}><img className='userImg' src={add} alt="" /></figure>
				<div class="media-object">
					<h4>{lan.new_listings}</h4>
				</div>
			</div>
			
		</div>}
	</div>
	<div class={data==""||data.role?.role_slug!=="seller"?"leftwhy":"leftwhy adwhy"} onClick={()=>{dispatch(getCmsData("why_sell_with_us"))}}>
		<button class="blankModal" data-bs-toggle="modal" data-bs-target="#blankModalCall"></button>
		<div class="media">
			<figure><img src={Query} alt="" /></figure>
			<div class="media-object">
			<h4>{lan.why}</h4>
				<strong><img src={mobiLgo} alt="" /></strong>
			</div>
		</div>
	</div>
	{data&&data.role?.role_slug==="seller"&&<div class="leftpnlactiv">
		<h6>{lan.selling}</h6>
		<ul>
			<li ><a href="" onClick={(e)=>{e.preventDefault();dispatch(setCurrentProduct(""));navigate("/"+userData.unique_id+"/aktivni-oglasi")}}><svg style={{ marginRight: "8px" }} height="10" width="10">
                                                    <circle cx="5" cy="5" r="5" fill="green" />
                                                </svg>
				{/* <i><img src={grn} alt="" /></i> */}
				{lan.active_listings}
			{/* <span>(3)</span> */}
			</a></li>
			<li ><a href="" onClick={(e)=>{e.preventDefault();dispatch(setCurrentProduct(""));navigate("/"+userData.unique_id+"/neaktivni-oglasi")}}><svg style={{ marginRight: "8px" }} height="10" width="10">
                                                    <circle cx="5" cy="5" r="5" fill="red" />
                                                </svg>{lan.inactive_listings}
			{/* <span>(2)</span> */}
			</a></li>
			<li ><a href="" onClick={(e)=>{e.preventDefault();dispatch(setCurrentProduct(""));navigate("/"+userData.unique_id+"/zavrseni-oglasi")}}><svg style={{ marginRight: "8px" }} height="10" width="10">
                                                    <circle cx="5" cy="5" r="5" fill="#F8B756" />
                                                </svg>{lan.finish_listings}
			{/* <span>(15)</span> */}
			</a></li>
		</ul>
	</div>}
	{data&&data.role?.role_slug==="seller"&&<div class="leftpnlactiv buypnl">
		<h6>{lan.buying}</h6>
		<ul>
			<li><a href={BASE_HREF+"/saved-listings"} onClick={(e)=>{e.preventDefault();dispatch(setCurrentProduct(""));navigate("/saved-listings")}}><svg style={{ marginRight: "8px" }} height="10" width="10">
                                                    <circle cx="5" cy="5" r="5" fill="#F8B756" />
                                                </svg>{lan.saved_listings}</a></li>
			<li><a href="" onClick={(e)=>{e.preventDefault();dispatch(setCurrentProduct(""));navigate("/saved-sellers")}}><svg style={{ marginRight: "8px" }} height="10" width="10">
                                                    <circle cx="5" cy="5" r="5" fill="#F8B756" />
                                                </svg>{lan.saved_deales}</a></li>
		</ul>
	</div>}
	<div class="leftpartbtm">
		<ul>
			<li data-bs-toggle="modal" data-bs-target="#blankModalCall"><a href={BASE_HREF} onClick={(e)=>{e.preventDefault();dispatch(getCmsData("about"))}}><i><img src={mobiLgo2} alt="" /></i>{lan.about}</a></li>
			<li data-bs-toggle="modal" data-bs-target="#blankModalCall"><a href={BASE_HREF} onClick={(e)=>{e.preventDefault();dispatch(getCmsData("pricing"))}}><i><img src={Price_tag} alt="" /></i>{lan.price}</a></li>
			<li data-bs-toggle="modal" data-bs-target="#blankModalCall"><a href={BASE_HREF} onClick={(e)=>{e.preventDefault();dispatch(getCmsData("help"))}}><i><img src={Ask_Question} alt="" /></i>{lan.help}</a></li>
			<li data-bs-toggle="modal" data-bs-target="#blankModalCall"><a href={BASE_HREF} onClick={(e)=>{e.preventDefault();dispatch(getCmsData("support"))}}><i><img src={Service} alt="" /></i>{lan.support}</a></li>
		</ul>
	</div>
</div>
    

<Login/>

<WhyUs cmsData={cmsData} />
{data&&data.role?.role_slug==="seller"&&<CreditModal/>}
{data&&data.role?.role_slug==="seller"&&<AddListing/>}

    </>
  )
}

export default Sidebar