import React from 'react'
import Close_indow from "../../images/Close_indow.png"
import Close from "../../images/close_01.svg"

function SoldModal({markSold}) {
  return (
    <div class="modal soldMainModal fade" id="soldModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            {/* <button type="button" class="btnCloseds" data-bs-dismiss="modal" >x</button> */}
            <span   data-bs-dismiss="modal" class="closeButtonTwo btnCloseds">
                        <img src={Close_indow} alt="" />
                        <img src={Close} alt="" />
                    </span>
            <div class="modal-body">
                <div className='soldModal'>
                    <div className='soldmodalCon'>
                        <h5>Čestitamo na prodaji.<br/> Jaste li sigurni da želite žavrsiti <br/>oglas?</h5>
                    </div>
                    <div className='soldmodalBtns'>
                        <ul>
                            <li onClick={markSold}><button>DA</button></li>
                            <li><button id="sldcls" data-bs-dismiss="modal">NE</button></li>
                        </ul>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default SoldModal