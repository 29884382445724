import React from 'react'
import carDelarRghtLst1 from "../../images/location_city _ylw.svg"
import carDelarRghtLst4 from "../../images/105_mobile_phone_ylw.svg"
import stng from "../../images/stng.svg"
import pencil from "../../images/Pencil_yellow.svg"
import rlok from "../../images/rlok.png"
import iglry from "../../images/gallery_yellow.svg"
import { lan } from '../../config'
import EditProfile from './EditProfile'


function RightSidebar({setData,data,fetchData,setOldimages,oldimages,images,setImages,fetchCities,cities}) {
	let login_data = localStorage.getItem("login_data");
	

	let setPhone=()=>{
		setData(JSON.parse(login_data))
	}
	return (
		<>
		<div class="filterRght carDelarRght">
			<a href="#url" class="closeMnuTwo"><img src="assets/images/CloseTwo.png" alt="" /></a>
			<div class="filterRghtTop">
				<div class="media">
					<figure>
						<img src={stng} />
					</figure>
					<div class="media-object">
						<h3>POSTAVKE PROFILA</h3>
					</div>
				</div>
			</div>
			<div class="carDelarRghtLst">
				<ul>
					<li data-bs-toggle="modal" data-bs-target="#editmodal1">
						<i><img src={rlok} /></i>
						<span>{lan.edit_pass}</span>
					</li>
					<li data-bs-toggle="modal" data-bs-target="#editmodal2" >
						<i><img src={carDelarRghtLst4} /></i>
						<span>{lan.edit_phone}</span>
					</li>
					<li data-bs-toggle="modal" data-bs-target="#editmodal3" onClick={fetchCities}>
						<i><img src={carDelarRghtLst1} /></i>
						<span>{lan.edit_address}</span>
					</li>
				{data.seller_type!=="private_seller"&&<li data-bs-toggle="modal" data-bs-target="#editmodal5" onClick={()=>data.images&&setOldimages(data.images)}> 
					<i><img src={iglry} /></i>
					<span>SLIKE SALONA</span>
				</li>}
				{data.seller_type!=="private_seller"&&<li data-bs-toggle="modal" data-bs-target="#editmodal4">
						<i><img src={pencil} /></i>
						<span>{lan.desc}</span>
					</li>}
				</ul>
			</div>
		</div>
		<EditProfile images={images} oldimages={oldimages} setOldimages={setOldimages} setImages={setImages} setData={setData} fetchData={fetchData} data={data} cities={cities}/>
		</>

	)
}

export default RightSidebar