import Multiselect from 'multiselect-react-dropdown'
import React from 'react'
import { lan } from '../config'

function MultiSelect({displayValue,setFormData,formData,options,data,val,placeholder,multiselectRef,preSelect}) {
    return (
        <Multiselect
        className="deskSelect"
            displayValue={displayValue}
            ref={multiselectRef?multiselectRef:null}
            showArrow={true}
            avoidHighlightFirstOption={true}
            emptyRecordMsg="Nema dostupnih opcija"
            style={{
                chips: {
                    background: "transparent",
                    fontSize: "22px",
                    margin: 0,
                    padding: 0,
                    color: "#000"
                },
                searchBox: {
                    justifyContent: "center",
                    color: "#989898"
                },
                inputField: {
                    textAlign: "center",
                    fontSize: "22px",

                },
                option: {
                    textAlign: "center",
                    fontSize: "22px",

                }
            }
            }
            singleSelect={true}
            // customArrow={<img src={drp} />}
            // onSearch={function noRefCheck(){console.log("sear")}}
            onSelect={function noRefCheck(selectedList, selectedItem) {
                setFormData({ ...formData, [data]: selectedItem[val?val:displayValue] })
            }}
            placeholder={placeholder?placeholder:lan.choose}
            selectedValues={preSelect?preSelect:[]}
            options={options}
        />
    )
}

export default MultiSelect