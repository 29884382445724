export const SET_USER_DATA = "SET_USER_DATA";
export const SET_POST_DATA = "SET_POST_DATA";
export const SET_CMS_DATA = "SET_CMS_DATA";
export const SET_MODE = "SET_MODE";
export const SET_LANDSCAPE = "SET_LANDSCAPE";
export const SET_CURRENT_PRODUCT = "SET_CURRENT_PRODUCT";
export const SET_PRE_PRODUCT = "SET_PRE_PRODUCT";
export const SET_LISTING_MODE = "SET_LISTING_MODE";
export const SET_SORT_FIELD = "SET_SORT_FIELD";
export const SET_SORT_VALUE = "SET_SORT_VALUE";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
export const SET_MORE = "SET_MORE";
export const SET_PAGE = "SET_PAGE";
export const SET_FILTERS = "SET_FILTERS";
export const SET_BANNER = "SET_BANNER";
export const SET_LOADING = "SET_LOADING";
export const SET_MODAL = "SET_MODAL";
export const SET_ADD_LIST = "SET_ADD_LIST";