import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL, lan } from "../../config";
import axios from "axios";
import Header from "../../partial/Header";
import Sidebar from "../../partial/Sidebar";
import SellerDetails from "./SellerDetails";
import SideRight from "./SideRight";
import $ from "jquery";
import {
  getPostData,
  getUserData,
  setMode,
  setloading,
} from "../../Redux/ActionCreater";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function Seller() {
  const { username } = useParams();
  const [data, setData] = useState({});
  const [sellerPost, setSellerPost] = useState([]);
  const [saveDealerData, setSaveDealerData] = useState({});
  const [adData, setAdData] = useState([]);
  const [activePost, setActivePost] = useState([]);
  const [soldPost, setSoldPost] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      dispatch(setloading(true));
      await axios({
        url: BASE_URL + "/api/seller/details",
        method: "POST",
        data: {
          username: username,
          loggedin_user_id: JSON.parse(localStorage.getItem("login_data"))?._id,
        },
      }).then((res) => {
        if (res.data.status === 200) {
          setData(res.data.data);

          let arr = [];
          let arr1 = [];
          for (let i = 0; i < res.data.postData.length; i++) {
            if (
              new Date(res.data.postData[i].expiry_date) > new Date() &&
              res.data.postData[i].sold_status === false
            ) {
              arr.push(res.data.postData[i]);
            }
            if (res.data.postData[i].sold_status === true) {
              arr1.push(res.data.postData[i]);
            }
          }
          setActivePost([...arr]);
          setSoldPost([...arr1]);
          setSellerPost([...arr]);
          setSaveDealerData(res.data.saveDealerData);
          setAdData(res.data.adData);
          setTimeout(() => {
            dispatch(setloading(false));
          }, 1000);
        }else{
          navigate("/");
          toast.error("Nema pronađenih podataka!");
          // dispatch(setloading(false));
        }
      });
    } catch (error) {
      console.log(error);
      dispatch(setloading(false));
    }
  };
  const logOut = () => {
    // setUserData("");
    dispatch(setloading(false));
    dispatch(setMode(""));
    dispatch(getUserData(""));
    localStorage.removeItem("login_data");
    localStorage.removeItem("access_token");
    toast.success(lan.login_continue_msg);
    dispatch(getPostData({ preData: [] }));
    navigate("/");
    $(".btnClose").click();
  };

  let saveSeller = async () => {
    let token = localStorage.getItem("access_token");
    if (token == null || token == "") {
      toast.dismiss();
      return toast.error(lan.login_continue_msg);
    }
    try {
      await axios({
        url: BASE_URL + "/api/save-dealer/add-remove",
        method: "POST",
        headers: {
          authorization: token,
        },
        data: {
          dealer_id: data._id,
          is_favourite: !saveDealerData.is_favourite,
        },
      }).then((res) => {
        if (res.data.status === 200) {
          setSaveDealerData({
            ...saveDealerData,
            is_favourite: !saveDealerData.is_favourite,
          });
          toast.dismiss();
          saveDealerData.is_favourite
            ? toast.success(lan.dealer_remove_msg)
            : toast.success(lan.dealer_add_msg);
        } else {
          toast.error(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
      if (error.response?.status === 401) {
        logOut();
      }
    }
  };

  useEffect(() => {
    fetchData();
    window.scrollTo({ top: 0, behavior: "smooth" });
    $(".modal-backdrop").hide();
    $("body").css("overflow", "auto");
    $("body").removeClass("openMenu");
    $("body").removeClass("openFilter");
  }, []);
  return (
    <>
      <Header page="details" userdata={data} />
      <Sidebar />
      {data && (
        <SellerDetails
          saveSeller={saveSeller}
          saveDealerData={saveDealerData}
          adData={adData}
          setSellerPost={setSellerPost}
          sellerPost={sellerPost}
          data={data}
          username={username}
          soldPost={soldPost}
          activePost={activePost}
        />
      )}
      <SideRight
        saveSeller={saveSeller}
        saveDealerData={saveDealerData}
        setSaveDealerData={setSaveDealerData}
        data={data}
      />
    </>
  );
}

export default Seller;
