import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import MainContent from './MainContent'
import RightSidebar from './RightSidebar'
import {  toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Header from '../Header'
import axios from 'axios'
import { BASE_URL, lan } from '../../config'
import { useDispatch } from 'react-redux'
import { getPostData, getUserData, setMode, setloading } from '../../Redux/ActionCreater'

function Profile() {
const [data,setData]=useState("");
const [addata,setadData]=useState("");
const [icon,setIcon]=useState("");
const [images,setImages]=useState([])
const [oldimages,setOldimages]=useState([])
const [sellerPost, setSellerPost] = useState([])
const [activePost, setActivePost] = useState([])
const [soldPost, setSoldPost] = useState([])
const navigate=useNavigate();
let login_data = JSON.parse(localStorage.getItem("login_data"));
let token = localStorage.getItem("access_token");
const dispatch = useDispatch();
const [cities, setCities] = useState([])

const logOut=()=>{
  // setUserData("");
  dispatch(setloading(false))
  dispatch(getUserData(""));
  localStorage.removeItem("login_data");
  localStorage.removeItem("access_token");
  toast.error(lan.login_continue_msg);
  dispatch(getPostData({preData:[]}));
  dispatch(setMode(""))
  navigate("/")
}
  let fetchData=async()=>{
try {
  await axios({
    url:`${BASE_URL}/api/user/profile-details`,
    method:"post",
    headers:{
      authorization: token
    },
    data:{
      user_id:login_data._id
    }
  }).then(res=>{
    if(res.data.status===200){
      localStorage.setItem('login_data', JSON.stringify(res.data.data));
      setData(res.data.data)
      setadData(res.data.adData)
      setIcon(res.data.sellerIcon);
      let arr=[];
      let arr1=[];
      for(let i=0;i<res.data.postData.length;i++){
        if(new Date(res.data.postData[i].expiry_date)>new Date()&&res.data.postData[i].sold_status===false
        ){
          arr.push(res.data.postData[i])
        }
        if(res.data.postData[i].sold_status===true
        ){
          arr1.push(res.data.postData[i])
        }
      }
      setActivePost([...arr])
      setSoldPost([...arr1])
      setSellerPost([...arr])
    }
  })
} catch (error) {
  console.log(error.response.status)
            if(error.response.status===401){
              logOut()
            }
}
  }

 
	const fetchCities = async () => {
		try {
			await axios({
				method: "get",
				url: `${BASE_URL}/api/city/list`
			}).then(res => {
				if (res.data.status === 200) {
					setCities(res.data.data);
					setData(JSON.parse(login_data))
				}
			})
		} catch (error) {
			console.log(error)
		}
	}

  useEffect(()=>{
    let login_data = localStorage.getItem("login_data");
		if (login_data == null || login_data == "") {
			// toast.dismiss();
			 toast.error(lan.login_continue_msg)
       navigate("/")
       return
		}
    // setData(JSON.parse(login_data))
    fetchData()

  },[])
  return (
   <>
   {/* <div>skfbksbf</div> */}
   <Sidebar/>
   <Header page={"profile"} />
   <MainContent activePost={activePost} soldPost={soldPost} sellerPost={sellerPost} setSellerPost={setSellerPost} addata={addata} data={data} icon={icon} fetchCities={fetchCities} cities={cities} setOldimages={setOldimages} />
   <RightSidebar cities={cities} images={images} oldimages={oldimages} fetchCities={fetchCities}  setOldimages={setOldimages} setImages={setImages} setData={setData} fetchData={fetchData} data={data}/>
   {/* <ToastContainer/> */}
   </>
  )
}

export default Profile