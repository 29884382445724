import React, { useEffect, useState } from 'react'
import logo from "../images/0_mobi_logo.svg"
import userIcon from "../images/29_user_profile_page.svg"
import menu_Icon from "../images/hamburger_menu_02.svg"
import Go_Back from "../images/61_publish_listing.svg"
import FilterIcon2 from "../images/25_filters.svg"
import Close from "../images/close_01.svg"
import whatsapp from "../images/viber.svg"
import Phone from "../images/phone_call.svg"
import mnu1 from "../images/31_about_us_grey.svg"
import mnu2 from "../images/33_price_grey.svg"
import mnu3 from "../images/32_user_support_grey.svg"
import mnu4 from "../images/34_help_grey.svg"
import sort from "../images/26_sort.svg"
import $ from "jquery";
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { BASE_HREF, BASE_URL, lan } from '../config'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import { getCmsData, setCurrentProduct, setMode, setPreProduct } from '../Redux/ActionCreater'
import AfterLogin from './AfterLogin'

function Header({page,userdata}) {
	const dispatch = useDispatch();
	const [data,setData]=useState("");
	const navigate = useNavigate();
	const [cmsData,setCmsData]=useState({})
	const { userData,preProduct} = useSelector(
		(state) => ({
			userData: state.dataReducer.userData,
			preProduct: state.dataReducer.preProduct,
		
		}),
		shallowEqual
	  );


	  const fetchData=async(cms)=>{
		try {
			var config = {
				method: 'get',
				url: `${BASE_URL}/api/cms/${cms}`,
			};
			await axios(config)
			.then(function (res) {
				if (res.data.status === 200) {
				   setCmsData(res.data.data)
				} else {
					toast.dismiss();
					toast.error(res.data.message)
				}
			})
			.catch(function (error) {
				console.log(error)
			});
		} catch (error) {
			console.log(error)
		}
	}

	  useEffect(()=>{
		// $(".mobleFltrt").click(function(){
		// 	$("body").addClass("openFilter");
		// });
		$(".menuIcon").click(function(){
			$("body").addClass("openMenu");
		});
	  },[])
	
	  let clickDetailsBack=(e)=>{
		e.preventDefault();
		if(preProduct.length===0){
			navigate(-1);
		}else{
			let idx=preProduct.length-1
			navigate(-1);
			let arr=preProduct;
			arr.splice(-1);
			dispatch(setPreProduct(arr))
		}
		dispatch(setMode(""))
	  }
	 
	useEffect(()=>{
		setData(userData)
			},[userData])
  return (
   <>
   {page=="home"&&<div class="botomBar">
	<ul>
		<li><a href="" onClick={(e)=>{e.preventDefault();$("body").addClass("openFilter");}} class="mobleFltrt">{lan.filters}<i><img src={FilterIcon2} alt="" /></i></a></li>
		<li><a href="#url" data-bs-toggle="modal" data-bs-target="#sortmodal"><i><img src={sort} alt="" /></i>{lan.sort}</a></li>
	</ul>
</div>}
{page=="details"&&(localStorage.getItem("login_data")&&JSON.parse(localStorage.getItem("login_data"))?._id === userdata?._id ?null:<div class="delerbotomBar">
	<ul>
		<li><a href={`viber://contact?number=%2B${userdata?.phone?.replace("+","")}`} class="mobleFltrt"><i><img style={{width:"100%"}} src={whatsapp} alt="" /></i>{lan.viber_msg}</a></li>
		<li><a href={`tel:${userdata?.phone}`}><i><img style={{width:"100%"}} src={Phone} alt="" /></i>{lan.call}</a></li>
	</ul>
</div>)}

<div class="mobilehder">
	<a href="#url" class="closeMnu"><img src={Close} alt="" /></a>
	<div class="mobilehdertop">
		<ul>
		<li data-bs-toggle="modal" data-bs-target="#blankModalCall"><a href={BASE_HREF} onClick={(e)=>{e.preventDefault();dispatch(getCmsData("about"))}}><i><img src={mnu1} alt="" /></i>{lan.about}</a></li>
			<li data-bs-toggle="modal" data-bs-target="#blankModalCall"><a href={BASE_HREF} onClick={(e)=>{e.preventDefault();dispatch(getCmsData("pricing"))}}><i><img src={mnu2} alt="" /></i>{lan.price}</a></li>
			<li data-bs-toggle="modal" data-bs-target="#blankModalCall"><a href={BASE_HREF} onClick={(e)=>{e.preventDefault();dispatch(getCmsData("help"))}}><i><img src={mnu4} alt="" /></i>{lan.help}</a></li>
			<li data-bs-toggle="modal" data-bs-target="#blankModalCall"><a href={BASE_HREF} onClick={(e)=>{e.preventDefault();dispatch(getCmsData("support"))}}><i><img src={mnu3} alt="" /></i>{lan.support}</a></li>
		</ul>
	</div>
	<div class="mobilehderbtm">
		<div style={{marginLeft:"-10px"}} class="media" data-bs-toggle="modal" data-bs-target="#blankModalCall" onClick={()=>{dispatch(getCmsData("why_sell_with_us"))}}>
			{/* <figure><img src={Query} alt="" /></figure>
			<div class="media-object">
				<h3>{lan.why}</h3>
				<strong><img src={mobiLgo} alt="" /></strong>
			</div> */}
			<div className='cpyrgt'>
				<span>©</span>
				<p>intelektualno i pravno vlasništvo mobi.ba kompanije, sva prava zadržana</p>
			</div>
		</div>
	</div>
</div>



<div class="mobileTphder">
	<div class="container">
		<div class="mobileTphderinr">
			{page!=="details"&&<button class="menuIcon"><img src={menu_Icon} alt="" /></button>}
			{page=="details"&&<a href='' class="backButon"><img onClick={(e)=>{clickDetailsBack(e)}} src={Go_Back} alt="" /></a>}
			{/* {page=="details"&&stage==="seller"&&<a href='' onClick={(e)=>{e.preventDefault();setStage("details")}} class="backButon"><img src={Go_Back} alt="" /></a>} */}
			<a href={BASE_HREF} onClick={(e)=>{e.preventDefault();dispatch(setCurrentProduct(""));navigate("/");dispatch(setMode(""));}} class="mobleHdrLgo"><img src={logo} alt="" /></a>
			{data?<a href="#url" className='usrpic' data-bs-toggle="modal" data-bs-target="#afterloginmodal"><img src={userIcon} alt="" /></a>:<a className='usrpic' href="#url" data-bs-toggle="modal" data-bs-target="#exampleModal"><img src={userIcon} alt="" /></a>}
		</div>
	</div>
</div>
<AfterLogin/>
   </>
  )
}

export default Header