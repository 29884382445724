import React from 'react'
import CloseTwo from "../../images/close_01_yellow.svg"
import { BASE_URL, lan } from '../../config'
import iglry from "../../images/gallery_yellow.svg"
import pencil from "../../images/Pencil_yellow.svg" 
import handshke from "../../images/handshake.svg"
import addoller from "../../images/addoller.svg"  
import grncircle from "../../images/grncircle.svg"
import redcircle from "../../images/Filled Circle red.svg"
import UpdateModal from './UpdateModal'
import edtlist from "../../images/edtlist.svg"
import coinpic from "../../images/37_credits_green.svg"
import coinpicred from "../../images/37_credits_red.svg"
import stng from "../../images/stng.svg"
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { getPostData, getUserData, setCurrentProduct, setModal, setMode } from '../../Redux/ActionCreater'



function UpdateSideRight({ stage, setStage, data ,setData}) {
    let token = localStorage.getItem("access_token");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const logOut=()=>{
      // setUserData("");
      dispatch(setMode(""));
      dispatch(getUserData(""));
      localStorage.removeItem("login_data");
      localStorage.removeItem("access_token");
      dispatch(setCurrentProduct(""));
      toast.error(lan.login_continue_msg);
      dispatch(getPostData({preData:[]}));
      navigate("/")
  }
    let markSold=async()=>{
        try {
            await axios({
                url: BASE_URL + "/api/listing-mark-sold",
                method: "POST",
                headers: {
                    authorization: token
                },
                data: {id:data._id},
            }).then((res) => {
                if (res.data.status === 200) {
                    // dispatch(setMode("finish"))
                    console.log(data)
                 navigate("/"+data.user_id.unique_id+"/zavrseni-oglasi")
                    toast.success("Oglas uspješno završen!");
                } else {
                    toast.error(res.data.message)
                }
            })
            
        } catch (error) {
          console.log(error)
          if(error?.response?.status===401){
            logOut()
          }
        }
    }
  return (
    <>
    <div class="filterRght carDelarRght" >
      <a href="#url" class="closeMnuTwo"><img src={CloseTwo} alt="" /></a>
      <div class="filterRghtTop" style={{ border: "none" }}>
      <div style={{ cursor: "pointer" }} class="media">
          <figure>
            <img src={stng} alt="" />
          </figure>
          <div class="media-object">
            <h3>POSTAVKE OGLASA</h3>
          </div>
        </div>
        {/* {stage == "seller" && <div class="media">
          <figure>
            <img src={data?.user_id?.seller_type == "private_seller" ? Account : Frame} alt="" />
          </figure>
          <div class="media-object">
            <h3>{data.user_id.full_name}</h3>
          </div>
        </div>} */}
      </div>
      <div class="carDelarRghtLst" style={{ border: "none" }}>
        <ul>
          <li class="" data-bs-toggle="modal" data-bs-target={new Date(data.expiry_date) >= new Date() ? "#pormotlist":"#upcredit"}>
            <i><img src={addoller} alt="" /></i>
            <span>{new Date(data.expiry_date) >= new Date() ? "IZDVOJI OGLAS":"AKTIVIRAJ OGLAS"}</span>
          </li>
          <li data-bs-toggle="modal" data-bs-target="#upmodal2" onClick={()=>{dispatch(setModal(true))}}>
            <i><img src={edtlist} /></i>
            <span>UREDI DETALJE</span>
          </li>
          <li  data-bs-toggle="modal" data-bs-target="#upmodal8">
            <i><img src={iglry} /></i>
            <span>UREDI SLIKE</span>
          </li>
          <li data-bs-toggle="modal" data-bs-target="#upmodal20">
            <i><img src={pencil} /></i>
            <span>UREDI OPIS</span>
          </li>
          {data.sold_status?
          // <li style={{ border: "none" }}>
          //   <i><img src={handshke} alt="" /></i>
          //   <span>ZAVRŠEN OGLAS</span>
          // </li>
          null
          :
          <li style={{ border: "none" }} data-bs-toggle="modal" data-bs-target="#soldModal">
            <i><img src={handshke} alt="" /></i>
            <span>ZAVRŠI OGLAS</span>
          </li>}
        </ul>
      </div>
      <div class="carDelarRghtBtmMsg">
      <div class="carDelarRghtLst" style={{ border: "none" }}>
      <li style={{ border: "none" }}>
            <i><img src={data.approve_status?coinpic:coinpicred} alt="" /></i>
            <span>DODIJELJENO KREDITA: {data.assign_credit?data.assign_credit:0}</span>
          </li>
          <li style={{ border: "none"}}>
            <i><img src={data.approve_status?grncircle:redcircle} alt="" /></i>
            <span>{data.approve_status?"OGLAS JE AKTIVAN":"OGLAS JE NEAKTIVAN"}</span>
          </li>
      </div>
      
      </div>
    </div>
    <UpdateModal setData={setData} data={data}/>
    </>
  )
}


export default UpdateSideRight