import React, { useState } from 'react'
import Close_indow from "../images/Close_indow.png"
import Close from "../images/close_01.svg"
import Forgot_Password from "../images/forgot_password.svg"
import User from "../images/private_seleer_yellow.svg"
import $ from "jquery";
import { toast } from 'react-toastify'
import { BASE_URL, lan } from '../config'
import axios from 'axios'

function ForgetPass({toggleModalPass}) {
    const [formData, setFormData]=useState({
        username:"",
        verification_code:"",
        new_password:"",
        user_id:""
    });
    const [step,setStep]=useState("user")
    const [otp,setOtp]=useState("");


    const onChange=(e)=>{
        setFormData({...formData,[e.target.name]:e.target.value})
    }

const submitPass=async(e)=>{
    e.preventDefault();
    if (formData.new_password===""){
        return toast.error(lan.otp_error)
    }
    await axios({
        url: BASE_URL+ "/api/user/change-forgot-password",
        method: "POST",
        data: {
            "user_id": formData.user_id,
            "new_password": formData.new_password
        },
      }).then(res=>{
        if(res.data.status===200){
           setFormData({
            username:"",
            verification_code:"",
            new_password:"",
            user_id:""
        })
        setStep("user");
        setOtp("");
            $(".closeButtonfogot").click();
            toast.success(lan.password_change_success)
            
        }else{
            toast.error(res.data.message);
        }
      })
}

const submitOtp=async(e)=>{
    e.preventDefault();
    if (formData.verification_code===""){
        return toast.error("Unesi kod iz SMS poruke!")
    }
    await axios({
        url: BASE_URL+ "/api/user/check/forgot-otp",
        method: "POST",
        data: {
            "username": formData.username,
            "verification_code": formData.verification_code
        },
      }).then(res=>{
        if(res.data.status===200){
           
            setFormData({...formData,user_id:res.data.data.id})
            toast.success("Unesite novu lozinku!");
            setStep("pass")
            
        }else{
            toast.error("Neispravan kod!");
        }
      })
}

const submitUser=async(e)=>{
    e.preventDefault();
    if (formData.username===""){
        return toast.error(lan.username_error)
    }
    await axios({
        url: BASE_URL+ "/api/user/forgot-password",
        method: "POST",
        data: {
            "username": formData.username
        },
      }).then(res=>{
        if(res.data.status===200){
            setStep("otp")
            // setOtp(res.data.data.verification_code)
            toast.success("Poslali smo vam kod u SMS poruci");
            
        }else{
            toast.error(res.data.message);
        }
      })
}

  return (
    <div class="fogotPassModal demmoModal">
	<div class="modal-content">
	  <span class="closeButtonfogot" onClick={toggleModalPass}>
      <img src={Close_indow} alt="" />
					<img src={Close} alt="" />
	  </span>
	  <div class="modalRegisterpnl">
		  <div class="selerDelrBx">
			  <div class="selerDelrTop">
				  <img src={Forgot_Password} alt="" />
			  </div>
			  <div class="usermodal registermodal">
				<form>
         
					{step==="user"&&<div class="usermodaltop">
						<div class="frmData">
							<i><img src={User} alt="" /></i>
							<input onFocus={(e)=>{e.target.placeholder=""}} onBlur={(e)=>{if(e.target.value==="")e.target.placeholder=lan.username}} value={formData.username} name='username' onChange={onChange}  type="text" placeholder={lan.username}  />
						</div>
                      
						<div class="loginBtn">
							<input style={{cursor:step!=="user"?"not-allowed":'pointer'}} onClick={submitUser} disabled={step!=="user"?true:false} type="submit" value={lan.new_pass} />
						</div>
					</div>}
					{step==="otp"&&<div class="usermodaltop">
                    {/* {otp && <div class="frmData">
                                        {otp}
                                    </div>} */}
						<div class="frmData">
							<input onFocus={(e)=>{e.target.placeholder=""}} onBlur={(e)=>{if(e.target.value==="")e.target.placeholder=lan.otp_code}} value={formData.verification_code} name='verification_code' onChange={onChange} type="text" placeholder={lan.otp_code} />
						</div>
						<div class="loginBtn">
							<input style={{cursor:step!=="otp"?"not-allowed":'pointer'}} onClick={submitOtp} disabled={step!=="otp"?true:false} type="submit" value={"OK"} />
						</div>
					</div>}
					{step==="pass"&&<div class="usermodaltop">
						<div class="frmData">
							<input onFocus={(e)=>{e.target.placeholder=""}} onBlur={(e)=>{if(e.target.value==="")e.target.placeholder=lan.new_pass.toLowerCase()}} value={formData.new_password} name='new_password' onChange={onChange}  type="password" placeholder={lan.new_pass.toLowerCase()} />
						</div>
						<div class="loginBtn">
							<input style={{cursor:step!=="pass"?"not-allowed":'pointer'}} onClick={submitPass} disabled={step!=="pass"?true:false}  type="submit" value={lan.confirm} />
						</div>
					</div>}
				</form>
			</div>
		  </div>
	  </div>
	</div>
</div>
  )
}

export default ForgetPass