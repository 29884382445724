import React, { useEffect, useRef, useState } from 'react'
import Close_new from "../../images/close_01_white.svg"
import FilterIcon2 from "../../images/25_filters.svg"
import Trash from "../../images/filter_clear_all.svg"
import axios from 'axios'
import { BASE_URL, lan } from '../../config'
import {  ToastContainer, toast } from 'react-toastify'
import { Scrollbars } from 'react-custom-scrollbars-2';
import $ from "jquery";
import FilterModal from './FilterModal'
import { getPostData, setFilters, setMore, setloading } from '../../Redux/ActionCreater'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

function FilterRight() {
  let update=true;
	const dispatch = useDispatch();
  const [typeData,setTypeData]=useState("");
  const [currentFilter,setCurentFilter]=useState("");
  const multiselectRef=useRef(null);
  const [filterData,setFilterData]=useState({
    vehicle_type_id:"",
    vehicle_sub_type_id:"",
    subCategory:"",
    selling_rent:"",
    new_used:"",
    seller_type:{
      open:false,
      value:""
    },
    make:{
      open:false,
      value:""
    },
    model:{
      open:false,
      value:""
    },
    price:{
      open:false,
      from:"",
      to:""
    },
    year:{
      open:false,
      from:"",
      to:""
    },
    fuel:{
      open:false,
      value:""
    },
    mileage:{
      open:false,
      from:"",
      to:""
    },
    engine_size:{
      open:false,
      from:"",
      to:""
    },
    engine_power:{
      open:false,
      from:"",
      to:""
    },
    transmission:{
      open:false,
      value:""
    },
    drive:{
      open:false,
      value:""
    },
    color:{
      open:false,
      value:""
    },
    seats:{
      open:false,
      value:""
    },
    doors:{
      open:false,
      value:""
    },
    enviroment:{
      open:false,
      value:""
    },
    location:{
      open:false,
      value:[],
      state:[]
    },
    total_weight:{
      open:false,
      value:""
    },
    carrying_capcity:{
      open:false,
      value:""
    },
    number_of_axles:{
      open:false,
      value:""
    },
    motorcycle_atv:{
      open:false,
      value:""
    },
    cooling:{
      open:false,
      value:""
    },
    other_details:{
      open:false,
      value:[]
    }
  });
  const {sortField,sortvalue,searchValue,hasMore,filters} = useSelector(
		(state) => ({
			sortField: state.dataReducer.sortField,
			searchValue: state.dataReducer.searchValue,
			sortvalue: state.dataReducer.sortvalue,
			hasMore: state.dataReducer.hasMore,
      filters:state.dataReducer.filters
		}),
		shallowEqual
	);

  let fetchtypeData=async()=>{
    try {
      dispatch(setloading(true))
      await axios({
        url: BASE_URL + "/api/vehicle-type/list",
        method: "GET",
        
        }).then(res=>{
        if(res.data.status===200){
          setTypeData(res.data.data);
          if(filters.vehicle_type_id){
            $("#"+filters.vehicle_type_id).addClass("active")
            setFilterData(filters);
          }else{
            setFilterData({...filterData,vehicle_type_id:res.data.data[0]._id})
          }
          dispatch(setloading(false))
        }else{
          toast.error(res.data.message);
        }
        })
    } catch (error) {
      console.log(error)
    }
  }

  
  let resetSelection=()=>{
    multiselectRef.current.resetSelectedValues()
   }


const clickFilter=(e,type,val,id)=>{

if(filterData[type]===val){
  setFilterData({...filterData,[type]:""})
  setCurentFilter("")
  $("#"+id).removeClass("active");
  
}else{
  setFilterData({...filterData,[type]:val})
  setCurentFilter(id);
  
}
}

const clickBtn=(type,id)=>{
  // if(!filterData[type].open){
  //   setFilterData({...filterData,[type]:{...filterData[type],value:"",from:"",to:""}})
  // }
  // setFilterData({...filterData,[type]:{...filterData[type],open:!filterData[type].open}})
  setCurentFilter(id)
}

const clickCat=(item)=>{
  // $("#"+item.slug+"new").click();
  // $("#"+item.slug+"sell").click();
  $("#option-s1").removeClass("active");
  $("#option-s2").removeClass("active");
  $("[type=checkbox]").removeClass("active");
  $("[type=checkbox]").prop('checked', false);
  $(".region_sec").removeClass("active");
  resetSelection();
  setFilterData({
    vehicle_type_id:item._id,
    vehicle_sub_type_id:"",
    subCategory:"",
    selling_rent:"",
    new_used:"",
    seller_type:{
      open:false,
      value:""
    },
    make:{
      open:false,
      value:""
    },
    model:{
      open:false,
      value:""
    },
    price:{
      open:false,
      from:"",
      to:""
    },
    price_to:"",
    year:{
      open:false,
      from:"",
      to:""
    },
    fuel:{
      open:false,
      value:""
    },
    mileage:"",
    engine_size:{
      open:false,
      value:""
    },
    engine_power:{
      open:false,
      value:""
    },
    transmission:{
      open:false,
      value:""
    },
    drive:{
      open:false,
      value:""
    },
    color:{
      open:false,
      value:""
    },
    seats:{
      open:false,
      value:""
    },
    doors:{
      open:false,
      value:""
    },
    enviroment:{
      open:false,
      value:""
    },
    location:{
      open:false,
      value:[],
      state:[],
    },
    total_weight:{
      open:false,
      value:""
    },
    carrying_capcity:{
      open:false,
      value:""
    },
    number_of_axles:{
      open:false,
      value:""
    },
    motorcycle_atv:{
      open:false,
      value:""
    },
    cooling:{
      open:false,
      value:""
    },
    other_details:{
      open:false,
      value:[]
    }
  })
  dispatch(setFilters({}))
//  setFilterData({...filterData,vehicle_type_id:item._id,vehicle_sub_type_id:""})
}

// const clickSubCat=(item)=>{
//   $("#"+item.slug+"new").click();
//   $("#"+item.slug+"sell").click();
//   $("[type=checkbox]").prop('checked', false)
//   setFilterData({
//     vehicle_type_id:"",
//     vehicle_sub_type_id:"",
//     subCategory:"",
//     selling_rent:"",
//     new_used:"",
//     seller_type:{
//       open:false,
//       value:""
//     },
//     make:{
//       open:false,
//       value:""
//     },
//     model:{
//       open:false,
//       value:""
//     },
//     price:{
//       open:false,
//       from:"",
//       to:""
//     },
//     year:{
//       open:false,
//       from:"",
//       to:""
//     },
//     fuel:{
//       open:false,
//       value:""
//     },
//     mileage:{
//       open:false,
//       from:"",
//       to:""
//     },
//     engine_size:{
//       open:false,
//       from:"",
//       to:""
//     },
//     engine_power:{
//       open:false,
//       from:"",
//       to:""
//     },
//     transmission:{
//       open:false,
//       value:""
//     },
//     drive:{
//       open:false,
//       value:""
//     },
//     color:{
//       open:false,
//       value:""
//     },
//     seats:{
//       open:false,
//       value:""
//     },
//     doors:{
//       open:false,
//       value:""
//     },
//     enviroment:{
//       open:false,
//       value:""
//     },
//     location:{
//       open:false,
//       value:[],
//       state:[]
//     },
//     total_weight:{
//       open:false,
//       value:""
//     },
//     carrying_capcity:{
//       open:false,
//       value:""
//     },
//     number_of_axles:{
//       open:false,
//       value:""
//     },
//     motorcycle_atv:{
//       open:false,
//       value:""
//     },
//     cooling:{
//       open:false,
//       value:""
//     },
//     other_details:{
//       open:false,
//       value:[]
//     }
//   })
//   setFilterData({...filterData,vehicle_sub_type_id:item._id});
  
// }

const clearAll=(e)=>{
  e.preventDefault();
  
  dispatch(setloading(true))
  dispatch(setMore(true))
  dispatch(setFilters({}))
  // dispatch(getPostData(filterData));
  dispatch(getPostData({sort_field:sortField,sort_value:sortvalue,searchData:searchValue,pageNo:0,hasMore:hasMore,preData:[]}));
 
  // $("#car-tab").click();
  $(".firstCat")[0].click();
  $( "[type=checkbox]" ).prop('checked', false)
  $( "[type=checkbox]" ).removeClass('active')
  $(".filterBtn").removeClass('active');
  $(".region_sec").removeClass("active");
  resetSelection();
  // setFilterData({
  //   vehicle_type_id:"",
  //   vehicle_sub_type_id:"",
  //   subCategory:"",
  //   selling_rent:"",
  //   new_used:"",
  //   seller_type:{
  //     open:false,
  //     value:""
  //   },
  //   make:{
  //     open:false,
  //     value:""
  //   },
  //   model:{
  //     open:false,
  //     value:""
  //   },
  //   price:{
  //     open:false,
  //     from:"",
  //     to:""
  //   },
  //   year:{
  //     open:false,
  //     from:"",
  //     to:""
  //   },
  //   fuel:{
  //     open:false,
  //     value:""
  //   },
  //   mileage:{
  //     open:false,
  //     from:"",
  //     to:""
  //   },
  //   engine_size:{
  //     open:false,
  //     from:"",
  //     to:""
  //   },
  //   engine_power:{
  //     open:false,
  //     from:"",
  //     to:""
  //   },
  //   transmission:{
  //     open:false,
  //     value:""
  //   },
  //   drive:{
  //     open:false,
  //     value:""
  //   },
  //   color:{
  //     open:false,
  //     value:""
  //   },
  //   seats:{
  //     open:false,
  //     value:""
  //   },
  //   doors:{
  //     open:false,
  //     value:""
  //   },
  //   enviroment:{
  //     open:false,
  //     value:""
  //   },
  //   location:{
  //     open:false,
  //     value:[]
  //   },
  //   total_weight:{
  //     open:false,
  //     value:""
  //   },
  //   carrying_capcity:{
  //     open:false,
  //     value:""
  //   },
  //   number_of_axles:{
  //     open:false,
  //     value:""
  //   },
  //   motorcycle_atv:{
  //     open:false,
  //     value:""
  //   },
  //   cooling:{
  //     open:false,
  //     value:""
  //   },
  //   other_details:{
  //     open:false,
  //     value:""
  //   }
  // })
}

const submitFilter=(e)=>{
  e.preventDefault();
  // if(parseInt(filterData.price.from)>parseInt(filterData.price.to)){
  //   return toast.error("Price range from value should be less than to value");
  // }
  // if(parseInt(filterData.year.from)>parseInt(filterData.year.to)){
  //   return toast.error("Year range from value should be less than to value");
  // }
  dispatch(setloading(true))
  dispatch(setFilters({...filterData}))
  // dispatch(getPostData(filterData));
  dispatch(getPostData({...filterData,sort_field:sortField,sort_value:sortvalue,searchData:searchValue,pageNo:0,hasMore:hasMore,preData:[]}));

}

useEffect(()=>{
  fetchtypeData();
$(".closeMnuTwo").click(function(){
  $("body").removeClass("openFilter");
});
 
},[])
// useEffect(()=>{
//   console.log(filterData)
// },[filterData])

  return (
    <>
   <div className="filterRght">
  <a href="#url" className="closeMnuTwo">    
    <li><a href="" onClick={clearAll}><img src={Trash} /></a></li>
    <li><a href="" onClick={submitFilter} className='mbileTopBtn'>{lan.use_filters}</a></li>
    <li><img src={Close_new} alt /></li>
  </a>
  <div className="filterRghtTop">
    <div className="media mbilfltrnon">
      <figure>
        <img src={FilterIcon2} alt />
      </figure>
      <div className="media-object">
        <h3>{lan.filters}</h3>
      </div>
    </div>    
  </div>
  {typeData&&typeData.length>0&&<div className="filterRghtbtm">
    <div className="filterProductLnk">
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {typeData&&typeData.length>0&&typeData.map((item,idx)=>{
           return <li className="nav-item"   role="presentation">
          <button onClick={()=>{clickCat(item)}}  className={filterData.vehicle_type_id===item._id?"nav-link firstCat active":"nav-link firstCat"} id={item._id} data-bs-toggle="tab" data-bs-target={`#${item.slug}`} type="button" role="tab" aria-controls={item.slug} aria-selected="true"><span className={idx==0?`icon-Frame`:`icon-Frame-${idx}`} /></button>
        </li>
        })}
      </ul>
    </div>
    <div className="filterProductLst">
      <div className="tab-content" id="myTabContent">
      {typeData&&typeData.length>0&&typeData.map((item,idx)=>{
     console.log("================>571",item);
        return <div className={filterData.vehicle_type_id===item._id?"tab-pane fade show active":"tab-pane fade"} id={item.slug} role="tabpanel" aria-labelledby={`${item.slug}-tab`}>
        <div className="filterProductLstIner ">
          <div className="filterProductLstTop">
            <nav>
            {item.slug==="motorcycle"&&<ul className="nav nav-tabs" id="" role="tablist">
                <li>
                  <button className={filters.vehicle_sub_type_id===item.vehicleSubTypeData[1]._id?"nav-link filterBtn active":"nav-link filterBtn"} id={item.slug+"atv"}  data-bs-toggle="tab"  type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={(e)=>{clickFilter(e,"vehicle_sub_type_id",item.vehicleSubTypeData[1]._id,item.slug+"atv")}}><span class="icon-Frame-4"></span></button>
                </li>
                <li>
                  <button className={filters.vehicle_sub_type_id===item.vehicleSubTypeData[0]._id?"nav-link filterBtn active":"nav-link filterBtn"} id={item.slug+"moto"}  data-bs-toggle="tab"  type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={(e)=>{clickFilter(e,"vehicle_sub_type_id",item.vehicleSubTypeData[0]._id,item.slug+"moto")}}><span class="icon-Frame1"></span></button>
                </li>
               </ul>}
              <ul className="nav nav-tabs " id="" role="tablist">
                <li>
                  <button className={filters.selling_rent==="Selling"?"nav-link filterBtn active":"nav-link filterBtn"} id={item.slug+"sell"}  data-bs-toggle="tab"  type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={(e)=>{clickFilter(e,"selling_rent","Selling",item.slug+"sell")}}>{lan.selling}</button>
                </li>
                <li>
                  <button className={filters.selling_rent==="Rent"?"nav-link filterBtn active":"nav-link filterBtn"} id={item.slug+"rent"}  data-bs-toggle="tab"  type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={(e)=>{clickFilter(e,"selling_rent","Rent",item.slug+"rent")}}>{lan.rent}</button>
                </li>
               </ul>
               <ul className="nav nav-tabs " id="" role="tablist"> 
                <li>
                  <button className={filters.new_used==="Novo"?"nav-link filterBtn active":"nav-link filterBtn"} id={item.slug+"new"}  data-bs-toggle="tab"  type="button" role="tab" aria-controls="nav-contact" aria-selected="false" onClick={(e)=>{clickFilter(e,"new_used","Novo",item.slug+"new")}}>{lan.new}</button>
                </li>
                <li>
                  <button className={filters.new_used==="Korišteno"?"nav-link filterBtn active":"nav-link filterBtn"} id={item.slug+"used"}  data-bs-toggle="tab"  type="button" role="tab" aria-controls="nav-contact" aria-selected="false" onClick={(e)=>{clickFilter(e,"new_used","Korišteno",item.slug+"used")}}>{lan.used}</button>
                </li>
              </ul>
            </nav>						  							  
          </div>
          <div className="filterProductLstBtm">
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                <div className="listflterCtgry">
                  <ul>
                  <li>
                      <div className="form-group" onClick={()=>{clickBtn("seller_type","seller_type"+item.slug)}} data-bs-toggle="modal" data-bs-target={"#seller_type"}>
                        <input className={filters.seller_type?.open?"active":""} type="checkbox" id={"seller_type"+item.slug} />
                        <label htmlFor={"seller_type"+item.slug}>{lan.type}</label>
                      </div>
                    </li>
                    {/* <li>
                      <div className="form-group" onClick={()=>{clickBtn("make","make"+item.slug)}} data-bs-toggle="modal" data-bs-target={"#make"}>
                        <input type="checkbox" id={"make"+item.slug} />
                        <label htmlFor={"make"+item.slug}>{lan.make_mode}</label>
                      </div>
                    </li> */}
                    {
                    // item.vehicleSubTypeData.length>0?item.vehicleSubTypeData&&item.vehicleSubTypeData[0].attributeData.map((i,idx)=>{
                    //     return i.vehicle_attribute_id.slug!="selling_rent"&&i.vehicle_attribute_id.slug!="new_used"&&i.vehicle_attribute_id.slug!="make"&&i.vehicle_attribute_id.slug!="model"&&i.vehicle_attribute_id.active_status==true&&<li>
                    //      <div className="form-group" onClick={()=>{clickBtn(i.vehicle_attribute_id.slug,i.vehicle_attribute_id.slug+item.slug)}} data-bs-toggle="modal" data-bs-target={"#"+i.vehicle_attribute_id.slug}>
                    //        <input type="checkbox" id={i.vehicle_attribute_id.slug+item.slug} />
                    //        <label htmlFor={i.vehicle_attribute_id.slug+item.slug}>{i.vehicle_attribute_id.name}</label>
                    //      </div>
                    //    </li>
                    //    }):
                       item.attributeData&&item.attributeData.map((i,idx)=>{
                        return i.vehicle_attribute_id.slug!="selling_rent"&&i.vehicle_attribute_id.slug!="new_used"
                        // &&i.vehicle_attribute_id.slug!="make"
                        &&i.vehicle_attribute_id.slug!="model"
                        // &&i.vehicle_attribute_id.active_status==true
                        &&<li>
                         <div className="form-group" onClick={()=>{clickBtn(i.vehicle_attribute_id.slug,i.vehicle_attribute_id.slug+item.slug)}} data-bs-toggle="modal" data-bs-target={"#"+i.vehicle_attribute_id.slug}>
                           <input type="checkbox" class={filters[i.vehicle_attribute_id.slug]?.open?"active":""} id={i.vehicle_attribute_id.slug+item.slug} />
                           <label htmlFor={i.vehicle_attribute_id.slug+item.slug}>{i.vehicle_attribute_id.name}</label>
                         </div>
                       </li>
                       })}
                      
                    
         
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      </div>
      })}
      </div>
    </div>
  </div>}
  <div className="filterRghtBtn">
    <ul>
      <li><a href="" onClick={submitFilter}>{lan.use_filters}</a></li>
      <li><a href="" onClick={clearAll}><img src={Trash} /></a></li>
    </ul>
  </div>
</div>

<FilterModal resetSelection={resetSelection} multiselectRef={multiselectRef} currentFilter={currentFilter} update={update} filterData={filterData} setFilterData={setFilterData} />
{/* <ToastContainer/> */}
</>
  )
}

export default FilterRight