import React, { useEffect, useRef, useState } from 'react'
import { PayPalButtons, PayPalHostedField, PayPalHostedFieldsProvider, usePayPalHostedFields } from "@paypal/react-paypal-js";
import Close_indow from "../images/Close_indow.png"
import CloseTwo from "../images/close_01.svg"
import { BASE_URL } from '../config';
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import $ from "jquery";
import { useDispatch } from 'react-redux';
import { getUserData, setModal } from '../Redux/ActionCreater';
import { Link } from 'react-router-dom';

function Paymentmodal({ payment, load, credit, setLoad }) {

  const dispatch = useDispatch();
  let token = localStorage.getItem("access_token");

  const { userData } = useSelector(
    (state) => ({
      userData: state.dataReducer.userData,
    }),
    shallowEqual
  );

  const CUSTOM_FIELD_STYLE = { "border": "1px solid #606060", "boxShadow": "2px 2px 10px 2px rgba(0,0,0,0.1)", "height": "50px" };
  const INVALID_COLOR = {
    color: "#dc3545",
  };
  const [state, setState] = useState({
    number: '',
    expiry: '',
    cvc: '',
    name: '',
    focus: '',
  });

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;

    setState((prev) => ({ ...prev, [name]: value }));
  }

  const handleInputFocus = (evt) => {
    setState((prev) => ({ ...prev, focus: evt.target.name }));
  }



  const SubmitPayment = ({ customStyle }) => {
    const [paying, setPaying] = useState(false);
    const cardHolderName = useRef(null);
    const hostedField = usePayPalHostedFields();

    const handleClick = () => {
      // if (!hostedField?.cardFields) {
      //         const childErrorMessage = 'Unable to find any child components in the <PayPalHostedFieldsProvider />';

      //         action(ERROR)(childErrorMessage);
      //         throw new Error(childErrorMessage);
      //     }
      const isFormInvalid =
        Object.values(hostedField.cardFields.getState().fields).some(
          (field) => !field.isValid
        ) || !cardHolderName?.current?.value;

      if (isFormInvalid) {
        return alert(
          "The payment form is invalid"
        );
      }
      setPaying(true);
      hostedField.cardFields
        .submit({
          cardholderName: cardHolderName?.current?.value,
        })
        .then((data) => {
          console.log(data)
          // Your logic to capture the transaction
          fetch(BASE_URL + "/api/capture-payment-order", {
            method: "POST",
            body: { order_id: data.orderId }
          })
            .then((response) => response.json())
            .then((data) => {
              console.log(data)
            })
            .catch((err) => {
              // Here handle error
            })
            .finally(() => {
              setPaying(false);
            });
        })
        .catch((err) => {
          // Here handle error
          setPaying(false);
        });
    };

    return (
      <>
        <label title="This represents the full name as shown in the card">
          Card Holder Name
          <input
            id="card-holder"
            ref={cardHolderName}
            className="card-field"
            style={{ ...customStyle, outline: "none" }}
            type="text"
            placeholder="Full name"
          />
        </label>
        <button
          className={`btn${paying ? "" : " btn-primary"}`}
          style={{ float: "right" }}
          onClick={handleClick}
        >
          {paying ? <div className="spinner tiny" /> : "Pay"}
        </button>
      </>
    );
  };


  return (
    <>
      <div class="modal dashbordModal fade" style={{ overflow: "hidden" }} id="paymentmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
            </div>
            <div class="modal-body paypalmodal" style={{ overflow: "auto" }}>
              <button type="button" class="btnClose payCls" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setLoad(false); dispatch(setModal(false)); }}>
                <img src={Close_indow} alt="" />
                <img src={CloseTwo} alt="" />
              </button>

              <div className='paypalBtn'>
                {load && <PayPalButtons style={{ width: "600px" }}
                  createOrder={(data, actions) => {
                    try {
                      return actions.order.create({
                        purchase_units: [
                          {
                            amount: {
                              "currency_code": "EUR",
                              value: payment,
                            }
                          }
                        ],

                        "application_context":
                        {
                          "shipping_preference": "NO_SHIPPING",
                          "return_url": "https://mobi.ba",
                          "cancel_url": "https://mobi.ba"
                        },
                        "payment_method": {
                          "payee_preferred": "UNRESTRICTED"
                        },

                      });
                    } catch (error) {
                      console.log(error)
                    }

                  }}

                  onApprove={async (data, actions) => {
                    try {
                      const order = await actions.order.capture();
                      console.log("order", order, order.status, payment);
                      if (order.status === "COMPLETED") {
                        let transId = order.purchase_units[0].payments.captures[0].id;
                        console.log(transId)
                        await axios({
                          url: BASE_URL + "/api/order-success",
                          method: "POST",
                          headers: {
                            authorization: token
                          },
                          data: {
                            "trans_id": transId,
                            "amount": payment,
                            "credit": credit

                          },
                        })
                          .then(res => {
                            console.log(res.data.status)
                            if (res.data.status === 200) {
                              $(".payCls").click();
                              let userData = JSON.parse(localStorage.getItem("login_data"))
                              dispatch(getUserData({ ...userData, ...res.data.data }));
                              localStorage.setItem("login_data", JSON.stringify({ ...userData, ...res.data.data }))
                              toast.success("Uspješno ste dopunili kredit!");
                            }
                          })
                      } else {
                        toast.error("Dopuna kredita nije uspjela!");
                      }
                    } catch (error) {
                      console.log(error)
                    }



                  }}
                  onCancel={(data, actions) => {
                    toast.error("Dopuna kredita nije uspjela!");
                  }}




                />}
              </div>

              <div className='paypalbtomtxt'>
                <p>PayPal je najsigurniji i najpoznatiji pružatelj usluga u svijetu elektronskih novčanih transakcija.</p>
                <p>Sve transakcije se odvijaju isključivo na PayPal serverima i nemaju nikakve veze s nama.</p>
                <p>Za kartično plaćanje MasterCard karticama kao valutu odaberite EUR, kako biste izbjegli dodatnu proviziju na samu konverziju!</p>
                <div className='paypalbtomdetails'>
                <p>Uplata na žiroračun: 1994400005201622</p>
                <p> Primatelj: mobi.ba</p>
                <p> Svrha uplate: korisničko ime</p>
                <p> Iznos uplate: 3, 9 ili 49 KM</p>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="modal dashbordModal fade" style={{ overflow: "hidden" }} id="paymentmodal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
            </div>
            <div class="modal-body paypalmodal" style={{ overflow: "auto" }}>
              <button type="button" class="btnClose payCls" data-bs-dismiss="modal" aria-label="Close">
                <img src={Close_indow} alt="" />
                <img src={CloseTwo} alt="" />
              </button>

              <div className='paypalBtn'>
                <PayPalButtons style={{ width: "600px" }}
                  createOrder={(data, actions) => {
                    try {
                      return actions.order.create({
                        purchase_units: [
                          {
                            amount: {
                              "currency_code": "EUR",
                              value: 25,
                            }
                          }
                        ],

                        "application_context":
                        {
                          "shipping_preference": "NO_SHIPPING",
                          "return_url": "https://developer-beta.com/mobi/site-portal",
                          "cancel_url": "https://developer-beta.com/mobi/site-portal"
                        },
                        "payment_method": {
                          "payee_preferred": "UNRESTRICTED"
                        },

                      });
                    } catch (error) {
                      console.log(error)
                    }

                  }}

                  onApprove={async (data, actions) => {
                    try {
                      const order = await actions.order.capture();
                      console.log("order", order, order.status, payment);
                      if (order.status === "COMPLETED") {
                        let transId = order.purchase_units[0].payments.captures[0].id;
                        console.log(transId)
                        await axios({
                          url: BASE_URL + "/api/order-success",
                          method: "POST",
                          headers: {
                            authorization: token
                          },
                          data: {
                            "trans_id": transId,
                            "amount": "25",
                            "credit": "60"

                          },
                        })
                          .then(res => {
                            console.log(res.data.status)
                            if (res.data.status === 200) {
                              $(".payCls").click();
                              let userData = JSON.parse(localStorage.getItem("login_data"))
                              dispatch(getUserData({ ...userData, ...res.data.data }));
                              localStorage.setItem("login_data", JSON.stringify({ ...userData, ...res.data.data }))
                              toast.success("Vaša u plata je uspješno obavljena!");
                            }
                          })
                      }
                    } catch (error) {
                      console.log(error)
                    }



                  }}


                />
              </div>

              <div className='paypalbtomtxt'>
                <p>PayPal je najsigurniji i najpoznatiji pružatelj usluga u svijetu elektronskih novčanih transakcija.</p>
                <p>Sve transakcije se odvijaju isključivo na PayPal serverima i nemaju nikakve veze s nama.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal dashbordModal fade" style={{ overflow: "hidden" }} id="paymentmodal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
            </div>
            <div class="modal-body paypalmodal" style={{ overflow: "auto" }}>
              <button type="button" class="btnClose payCls" data-bs-dismiss="modal" aria-label="Close">
                <img src={Close_indow} alt="" />
                <img src={CloseTwo} alt="" />
              </button>

              <div className='paypalBtn'>
                <PayPalButtons style={{ width: "600px" }}
                  createOrder={(data, actions) => {
                    try {
                      return actions.order.create({
                        purchase_units: [
                          {
                            amount: {
                              "currency_code": "EUR",
                              value: 50,
                            }
                          }
                        ],

                        "application_context":
                        {
                          "shipping_preference": "NO_SHIPPING",
                          "return_url": "https://developer-beta.com/mobi/site-portal",
                          "cancel_url": "https://developer-beta.com/mobi/site-portal"
                        },
                        "payment_method": {
                          "payee_preferred": "UNRESTRICTED"
                        },

                      });
                    } catch (error) {
                      console.log(error)
                    }

                  }}

                  onApprove={async (data, actions) => {
                    try {
                      const order = await actions.order.capture();
                      console.log("order", order, order.status, payment);
                      if (order.status === "COMPLETED") {
                        let transId = order.purchase_units[0].payments.captures[0].id;
                        console.log(transId)
                        await axios({
                          url: BASE_URL + "/api/order-success",
                          method: "POST",
                          headers: {
                            authorization: token
                          },
                          data: {
                            "trans_id": transId,
                            "amount": "50",
                            "credit": "130"

                          },
                        })
                          .then(res => {
                            console.log(res.data.status)
                            if (res.data.status === 200) {
                              $(".payCls").click();
                              let userData = JSON.parse(localStorage.getItem("login_data"))
                              dispatch(getUserData({ ...userData, ...res.data.data }));
                              localStorage.setItem("login_data", JSON.stringify({ ...userData, ...res.data.data }))
                              toast.success("Vaša u plata je uspješno obavljena!");
                            }
                          })
                      }
                    } catch (error) {
                      console.log(error)
                    }



                  }}


                />
              </div>

              <div className='paypalbtomtxt'>
                <p>PayPal je najsigurniji i najpoznatiji pružatelj usluga u svijetu elektronskih novčanih transakcija.</p>
                <p>Sve transakcije se odvijaju isključivo na PayPal serverima i nemaju nikakve veze s nama.</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

    </>
  )
}

export default Paymentmodal