import React, { useEffect, useState } from 'react'
import userIcon from "../images/29_user_profile_page.svg"
import Query from "../images/30_why_sell_with_us.svg"
import mobiLgo from "../images/Group.svg"
import grn from "../images/Filled Circle green.svg"
import rd from "../images/Filled Circle red.svg"
import ylw from "../images/Filled Circle yellow.svg"
import coin from "../images/37_credits_yellow.svg"
import add from "../images/39_new_listing.svg"
import Close_indow from "../images/Close_indow.png"
import CloseTwo from "../images/close_01_yellow.svg"
import { useNavigate } from 'react-router-dom'
import { BASE_HREF, BASE_URL, lan } from '../config'
import $ from "jquery";
import { toast } from 'react-toastify'
import { getCmsData, getPostData, getUserData, setCurrentProduct, setModal } from '../Redux/ActionCreater'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

export default function AfterLogin() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState("");
    const [cmsPage, setCmsPage] = useState("");
    // const [cmsData,setCmsData]=useState({})
    const { userData, cmsData } = useSelector(
        (state) => ({
            userData: state.dataReducer.userData,
            cmsData: state.dataReducer.cmsData
        }),
        shallowEqual
    );
    useEffect(() => {
        let localUserData = localStorage.getItem("login_data")
        if (localUserData) {
            // setData(JSON.parse(localUserData))
            dispatch(getUserData(JSON.parse(localUserData)));
        }
        $(".closeMnu").click(function () {
            $("body").removeClass("openMenu");
        });
    }, [])
    const logOut = () => {
        // setUserData("");
        dispatch(getUserData(""));
        localStorage.removeItem("login_data");
        localStorage.removeItem("access_token");
        dispatch(setCurrentProduct(""));
        dispatch(getPostData({ preData: [] }));
        navigate("/")
        toast.success(lan.logout_success);
    }


    useEffect(() => {
        setData(userData)
    }, [userData])
    useEffect(() => {
        setCmsPage(cmsData)
    }, [cmsData])
    return (
        <>
            <div class="modal dashbordModal fade" id="afterloginmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog popmnu">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt="" />
                                <img src={CloseTwo} alt="" />
                            </button>
                        </div>
                        <div class="modal-body aftrlogbg">
                            <div class="usermodal">
                                <div class="leftPart aftrmenupop leftnew">

                                    <div className='mobileft'>
                                        <div class="leftpartTop">

                                            <div class="lftuser lftusrpdd topbdrnon">

                                                {data ? <div class="media" style={{ cursor: "pointer" }}>
                                                    <figure><img className='userImg' src={ userIcon} alt="" /></figure>
                                                    <div class="media-object">
                                                        <h4 data-bs-dismiss="modal" aria-label="Close" onClick={() => { dispatch(setCurrentProduct("")); navigate(`/profile`) }}>{data.username}</h4>
                                                        <strong data-bs-dismiss="modal" aria-label="Close" onClick={logOut}>{lan.logout}</strong>
                                                    </div>
                                                </div> : <>
                                                    <button class="userModal" data-bs-toggle="modal" data-bs-target="#exampleModal"></button>
                                                    <div class="media">
                                                        <figure><img src={userIcon} alt="" /></figure>
                                                        <div class="media-object">
                                                            <h4>{lan.user}</h4>
                                                            <strong>{lan.account}</strong>
                                                        </div>
                                                    </div>
                                                </>}
                                            </div>
                                            {data && data.role && data.role?.role_slug === "seller" && <div class="lftuser lftusrpdd" data-bs-toggle="modal" data-bs-target="#addmodal23">

                                                <div class="media" style={{ cursor: "pointer" }}>
                                                    <figure><img className='userImg' src={coin} alt="" /></figure>
                                                    <div class="media-object">
                                                        <h4>{lan.credits}:  {userData.total_credit}</h4>
                                                        <strong>{lan.buying_credits}</strong>
                                                    </div>
                                                </div>
                                            </div>}
                                            {data && data.role && data.role?.role_slug === "seller" && <div class="lftuser lftusrpdd">

                                                <div class="media" style={{ cursor: "pointer" }} onClick={()=>{dispatch(setModal(true));}} data-bs-toggle="modal" data-bs-target="#sortmodal3">
                                                    <figure><img className='userImg' src={add} alt="" /></figure>
                                                    <div class="media-object">
                                                        <h4>{lan.new_listings}</h4>
                                                    </div>
                                                </div>

                                            </div>}
                                        </div>
                                        <div class="leftwhy adwhy" onClick={() => { dispatch(getCmsData("why_sell_with_us")) }}>
                                            <button class="blankModal" data-bs-toggle="modal" data-bs-target="#blankModalCall"></button>
                                            <div class="media">
                                                <figure><img src={Query} alt="" /></figure>
                                                <div class="media-object">
                                                    <h4>{lan.why}</h4>
                                                    <strong><img src={mobiLgo} alt="" /></strong>
                                                </div>
                                            </div>
                                        </div>
                                        {data && data.role && data.role?.role_slug === "seller" && <div class="leftpnlactiv">
                                            <h6>{lan.selling}</h6>
                                            <ul className='leftul'>
                                                <li data-bs-dismiss="modal" aria-label="Close"><a href="" onClick={(e) => { e.preventDefault(); dispatch(setCurrentProduct("")); navigate("/" + userData.unique_id + "/aktivni-oglasi") }}><svg style={{ marginRight: "7px" }} height="20" width="20">
                                                    <circle cx="10" cy="9" r="6" fill="green" />
                                                </svg>{lan.active_listings}
                                                    {/* <span> (3)</span> */}
                                                </a></li>
                                                <li data-bs-dismiss="modal" aria-label="Close" ><a href="" onClick={(e) => { e.preventDefault(); dispatch(setCurrentProduct("")); navigate("/" + userData.unique_id + "/neaktivni-oglasi") }}><svg style={{ marginRight: "7px" }} height="20" width="20">
                                                    <circle cx="10" cy="9" r="6" fill="red" />
                                                </svg>{lan.inactive_listings}
                                                    {/* <span> (2)</span> */}
                                                </a></li>
                                                <li data-bs-dismiss="modal" aria-label="Close"><a href="" onClick={(e) => { e.preventDefault(); dispatch(setCurrentProduct("")); navigate("/" + userData.unique_id + "/zavrseni-oglasi") }}><svg style={{ marginRight: "7px" }} height="20" width="20">
                                                    <circle cx="10" cy="9" r="6" fill="#F8B756" />
                                                </svg>{lan.finish_listings}
                                                    {/* <span> (15)</span> */}
                                                </a></li>
                                            </ul>
                                        </div>}
                                        <div class="leftpnlactiv" style={{ borderBottom: '0px' }}>
                                            <div class="cntrlst">
                                                <h6>{lan.buying}</h6>
                                                <ul className='leftul'>
                                                    <li data-bs-dismiss="modal" aria-label="Close"><a href="" onClick={(e) => { e.preventDefault(); dispatch(setCurrentProduct("")); navigate("/saved-listings"); }}><svg style={{ marginRight: "7px" }} height="20" width="20">
                                                        <circle cx="10" cy="9" r="6" fill="#F8B756" />
                                                    </svg>{lan.saved_listings}</a></li>
                                                    <li data-bs-dismiss="modal" aria-label="Close"><a href="" onClick={(e) => { e.preventDefault(); dispatch(setCurrentProduct("")); navigate("/saved-sellers") }}><svg style={{ marginRight: "7px" }} height="20" width="20">
                                                        <circle cx="10" cy="9" r="6" fill="#F8B756" />
                                                    </svg>{lan.saved_deales}</a></li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ToastContainer/> */}
        </>
    )
}
