import React, { useEffect } from 'react'
import Header from '../../partial/Header'
import Sidebar from '../../partial/Sidebar'
import { useDispatch } from 'react-redux';
import { setloading } from '../../Redux/ActionCreater';

function Success() {
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(setloading(false))
    },[])
  return (
    <>
    <Header page="success"/>
    <Sidebar/>
    <div className='payment-container'>

    <div className="payment-card">
                <div style={{ borderRadius: 200, height: 200, width: 200, background: '#F8FAF5', margin: '0 auto' }}>
                    <i className="checkmark">✓</i>
                </div>
                <h1>Uspjeh</h1>
                <p>Congrats!! Vaša transakcija je uspješno završena.</p>
            </div>
    </div>
    <div className='container'>
        <h2>Privacy and Security</h2>
        <p>Sed nulla dolor est laboris ex qui ea velit officia sit est cupidatat ut mollit reprehenderit in ut ullamco elit cillum exercitation fugiat esse dolore velit culpa aliquip et eu laboris ut nostrud incididunt ad quis nostrud reprehenderit irure dolore adipisicing est pariatur reprehenderit nostrud sed sed laborum commodo esse occaecat adipisicing adipisicing ut proident consectetur enim aliqua reprehenderit dolor eu est dolore ex ad nostrud ut nostrud in consectetur consequat sit elit dolor proident laboris id nulla ut nostrud ut amet irure pariatur ex commodo et in elit non labore eu dolore dolore veniam sed incididunt quis proident officia nostrud exercitation laboris duis cupidatat exercitation do mollit veniam pariatur labore ut consectetur adipisicing cillum minim fugiat ad elit anim id deserunt id dolore ut qui culpa nulla duis eiusmod mollit cupidatat nulla irure anim cillum mollit labore in do amet deserunt amet laboris laboris ex nulla laborum consectetur tempor reprehenderit tempor sit est incididunt eu dolor ex fugiat est tempor magna voluptate reprehenderit ullamco est sint ut dolore laborum ut consequat magna do id est non reprehenderit laborum aliquip voluptate minim sit magna irure dolor anim ad duis in sed et dolor aliqua dolor deserunt dolor voluptate ullamco ut culpa sunt cillum est cillum consequat in proident aliqua.</p>
    </div>
    </>
  )
}

export default Success



