import axios from "axios";
import { BASE_URL } from "../config";
import { SET_USER_DATA,SET_POST_DATA,SET_CMS_DATA,SET_PAGE,SET_MORE,SET_MODE,SET_LANDSCAPE,SET_CURRENT_PRODUCT,SET_LISTING_MODE,SET_PRE_PRODUCT,SET_SORT_VALUE,SET_SORT_FIELD,SET_SEARCH_VALUE,SET_FILTERS,SET_BANNER,SET_LOADING,SET_MODAL,SET_ADD_LIST} from "./Action";
import { toast } from "react-toastify";

export  const setUserData = (data) => ({
    type: SET_USER_DATA,
    payload: data,
  });
  export  const setPostData = (data) => ({
    type: SET_POST_DATA,
    payload: data,
  });
  export  const setCmsData = (data) => ({
    type: SET_CMS_DATA,
    payload: data,
  });

  export  const setMode = (data) => ({
    type: SET_MODE,
    payload: data,
  });
  export  const setCurrentProduct = (data) => ({
    type: SET_CURRENT_PRODUCT,
    payload: data,
  });
  export  const setPreProduct = (data) => ({
    type: SET_PRE_PRODUCT,
    payload: data,
  });
  export  const setListingMode = (data) => ({
    type: SET_LISTING_MODE,
    payload: data,
  });
  export  const setLandscape = (data) => ({
    type: SET_LANDSCAPE,
    payload: data,
  });
  export  const setSearchdata = (data) => ({
    type: SET_SEARCH_VALUE,
    payload: data,
  });
  export  const setSortField = (data) => ({
    type: SET_SORT_FIELD,
    payload: data,
  });
  export  const setSortValue = (data) => ({
    type: SET_SORT_VALUE,
    payload: data,
  });

  export  const setMore = (data) => ({
    type: SET_MORE,
    payload: data,
  });
  export  const setPage = (data) => ({
    type: SET_PAGE,
    payload: data,
  });
  export  const setFilters = (data) => ({
    type: SET_FILTERS,
    payload: data,
  });
  export  const setBanner = (data) => ({
    type: SET_BANNER,
    payload: data,
  });
  export  const setloading = (data) => ({
    type: SET_LOADING,
    payload: data,
  });

  export  const setModal = (data) => ({
    type: SET_MODAL,
    payload: data,
  });

  export  const setAddListStatus = (data) => ({
    type: SET_ADD_LIST,
    payload: data,
  });

  export const getUserData=(data)=>async(dispatch)=>{
    // let data = await axios.get(`${BASE_URL}/api/home`,path)
    dispatch(setUserData(data));
  }

  export const getPostData=(data)=>async(dispatch)=>{
    try {
			await axios({
				url: BASE_URL + "/api/vehicle/post/list",
				method: "POST",
				data:{
					"page_no":data.pageNo?data.pageNo:0,
					"data_per_page":36,
					"loggedin_user_id":JSON.parse(localStorage.getItem("login_data"))?._id,
					"search_data":data.searchData?data.searchData:"",
					"sort_field":data.sort_field?data.sort_field:"",
					"sort_value":data.sort_value?data.sort_value:"",
					"vehicle_type_id": data.vehicle_type_id?data.vehicle_type_id:"",
    				"vehicle_sub_type_id": data.vehicle_sub_type_id?data.vehicle_sub_type_id:"",
    				"user_id":"",
    				"cooling": data.cooling&&data.cooling.open?data.cooling.value:"",
					"motorcycle_atv": data.motorcycle_atv&&data.motorcycle_atv.open?data.motorcycle_atv.value:"",
					"number_of_axles": data.number_of_axles&&data.number_of_axles.open?parseInt(data.number_of_axles.value):"",
					"carrying_capcity": data.carrying_capcity&&data.carrying_capcity.open?data.carrying_capcity.value:"",
					"total_weight": data.total_weight&&data.total_weight.open?data.total_weight.value:"",
					"other_details": data.other_details&&data.other_details.open?data.other_details.value:"",
					"location": data.location&&data.location.open?data.location.value:[],
					"state": data.location&&data.location.open?data.location.state:[],
					"enviroment": data.enviroment&&data.enviroment.open?data.enviroment.value:"",
					"doors": data.doors&&data.doors.open?parseInt(data.doors.value):"",
					"seats": data.seats&&data.seats.open?parseInt(data.seats.value):"",
					"color": data.color&&data.color.open?data.color.value:"",
					"drive": data.drive&&data.drive.open?data.drive.value:"",
					"transmission": data.transmission&&data.transmission.open?data.transmission.value:"",
					"engine_power_low": data.engine_power&&data.engine_power.open?parseInt(data.engine_power.from):"",
					"engine_power_high": data.engine_power&&data.engine_power.open?parseInt(data.engine_power.to):"",
					"engine_size_low": data.engine_size&&data.engine_size.open?parseInt(data.engine_size.from):"",
					"engine_size_high": data.engine_size&&data.engine_size.open?parseInt(data.engine_size.to):"",
					"mileage_high": data.mileage&&data.mileage.open?parseInt(data.mileage.to):"",
					"mileage_low": data.mileage&&data.mileage.open?parseInt(data.mileage.from):"",
					"fuel": data.fuel&&data.fuel.open?data.fuel.value:"",
					"year_low": data.year&&data.year.open?parseInt(data.year.from):"",
					"year_high": data.year&&data.year.open?parseInt(data.year.to):"",
					"price_high": data.price&&data.price.open?parseInt(data.price.to):"",
					"price_low": data.price&&data.price.open?parseInt(data.price.from):"",
					"model": data.model&&data.model.open?data.model.value:"",
					"make": data.make&&data.make.open?data.make.value:"",
					"new_used": data.new_used?data.new_used:"",
					"selling_rent": data.selling_rent?data.selling_rent:"",
					"seller_type":data.seller_type&&data.seller_type.open?data.seller_type.value:"",
				}
			  }).then(res=>{
				if(res.data.status===200){
					dispatch(setMore(true))
					if(res.data.data.length===0&&data.searchData===""){
						
						dispatch(setMore(false))
						
					}else if(res.data.data.length===0 && data.searchData!==""){
						
						dispatch(setMore(false))
						dispatch(setPage(0))
						
					}else{
						let pageNo=data.pageNo?data.pageNo:0
						dispatch(setPage(pageNo+1))
						
					}
					if(data.preData.length===0&&data.sort_value===""){
						dispatch(setPostData([...data.preData,...res.data.data].sort(() => Math.random() - 0.5)));
					}else{
						dispatch(setPostData([...data.preData,...res.data.data]));
					}
					setTimeout(() => {	
						dispatch(setloading(false))
					}, 1000);
				}else{
					dispatch(setloading(false))
					toast.error(res.data.message);
				}
			  })
		} catch (error) {
			console.log(error);
			dispatch(setloading(false))
		}
    
  }

  export const getCmsData=(cms)=>async(dispatch)=>{
    try {
		var config = {
			method: 'get',
			url: `${BASE_URL}/api/cms/${cms}`,
		};
		await axios(config)
		.then(function (res) {
			if (res.data.status === 200) {
				dispatch(setCmsData(res.data.data));
			} else {
				toast.dismiss();
				toast.error(res.data.message)
			}
		})
		.catch(function (error) {
			console.log(error)
		});
	} catch (error) {
		console.log(error)
	}
    
  }