import React, { useEffect } from 'react'
import Header from '../../partial/Header'
import Sidebar from '../../partial/Sidebar'
import ActiveListings from '../Home/ActiveListings'
import $ from "jquery";
function FinishList() {
    useEffect(()=>{
        $(".modal-backdrop").hide()
		$("body").css("overflow","auto");
		$("body").removeClass("openMenu");
		$("body").removeClass("openFilter");
    },[])
  return (
    <>
    <Header page={"finishlist"} />
    <Sidebar/>
    <ActiveListings status={false} finish={true}/>
    </>
  )
}

export default FinishList