import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../config'
import { toast } from 'react-toastify'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setBanner, setloading } from '../../Redux/ActionCreater';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'
function Ads() {
	const dispatch = useDispatch();
	let [idx,setIdx]=useState(0);
	const options = {
        loop: true,
        margin: 0,
        items: 1,
        autoplay: true,
        autoplaySpeed:1200,
        rewind: false,
        lazyLoad:true,
        // navRewind: true,
              autoplayTimeout:60000*30,
              navSpeed: 2000,
              dragEndSpeed: 2000,
              dots: true,
              nav: false
      };


	const {banner} = useSelector(
		(state) => ({
			banner: state.dataReducer.banner
		}),
		shallowEqual
	);
	let fetchtypeData=async()=>{
	  try {
		await axios({
		  url: BASE_URL + "/api/ad-banner",
		  method: "GET",
		  
		  }).then(res=>{
		  if(res.data.status===200){
			if(res.data.data.length>0){
				let idx=Math.floor(Math.random() * res.data.data.length)
				setIdx(idx)
			}
			dispatch(setBanner(res.data.data))
			dispatch(setloading(true))
		  }else{
			toast.error(res.data.message);
		  }
		  })
	  } catch (error) {
		console.log(error)
	  }
	}


let gotolink=(url)=>{
	url&&window.open(url, "_blank", "noreferrer");
}

  useEffect(() => {
	if(banner.length===0){
		dispatch(setloading(true))
		fetchtypeData()
	}
  
  }, [])
  return (
    <>
    <div class="desktopAds">
	<div class="container">
	{/* <OwlCarousel class="owl-carousel owl-theme" {...options} loop dots={false}  nav={false}>
					{banner.length>0&&banner.map(item=>{
return <div class="item" onClick={()=>gotolink(item.link)}>
<div class="carDetilsImge">
	<img src={`${BASE_URL}/uploads/banner/${item.image}`}/>
</div>
</div>
						})}
							
						</OwlCarousel> */}
		<div class="desktopAdspnl" onClick={()=>gotolink(banner[idx].link)}>
		
			{banner.length>0&&banner[0].image&&<img src={`${BASE_URL}/uploads/banner/${banner[idx].image}`} alt="" />}
		</div>
	</div>
</div>

<div class="mobileAds">
	<div class="container">
	<div class="mobileAdspnl" onClick={()=>gotolink(banner[0].link)}>
		
			{banner.length>0&&banner[0].image&&<img src={`${BASE_URL}/uploads/banner/${banner[idx].mobile_image}`} alt="" />}
		</div>
	</div>
</div>
    </>
  )
}

export default Ads

