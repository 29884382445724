import React from 'react'
import CloseTwo from "../../images/close_01_yellow.svg"
import Frame from "../../images/car_dealer_yellow.svg"
import carDelarRghtLst1 from "../../images/location_city _ylw.svg"
import carDelarRghtLst2 from "../../images/address_black_ylw.svg"
import carDelarRghtLst3 from "../../images/104_directions_ylw.svg"
import carDelarRghtLst4 from "../../images/105_mobile_phone_ylw.svg"
import carDelarRghtLst5 from "../../images/heart_y.svg"
import Love from "../../images/heart_black.svg"
import Heart from "../../images/heart_trans.svg"
import Account from "../../images/private_seleer_yellow.svg"
import { BASE_URL, lan } from '../../config'
import { getPostData, getUserData, setCurrentProduct, setMode } from '../../Redux/ActionCreater'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import $ from "jquery";

function SideRight({stage,clickSeler,data,saveDealerData,setSaveDealerData}) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const logOut=()=>{
        // setUserData("");
        dispatch(setMode(""));
        dispatch(getUserData(""));
        localStorage.removeItem("login_data");
        localStorage.removeItem("access_token");
        toast.success(lan.login_continue_msg);
        dispatch(getPostData({preData:[]}));
		dispatch(setCurrentProduct(""));
        navigate("/")
        $(".btnClose").click();
    }
	let saveSeller=async(e,type)=>{
		let token = localStorage.getItem("access_token");
		if (token == null || token == "") {
			toast.dismiss();
			return toast.error(lan.login_continue_msg)
		}
		try {
			await axios({
				url: BASE_URL + "/api/save-dealer/add-remove",
				method: "POST",
				headers: {
					authorization: token
				},
				data: {
					"dealer_id": data.user_id._id,
					"is_favourite": !saveDealerData.is_favourite,

				},
			}).then(res => {
				if (res.data.status === 200) {
					setSaveDealerData({...saveDealerData,is_favourite:!saveDealerData.is_favourite})
					toast.dismiss();
					!type ? toast.success(lan.dealer_add_msg) :
						toast.success(lan.dealer_remove_msg);
				} else {
					toast.error(res.data.message);
				}
			})
		} catch (error) {
			console.log(error)
                if(error.response.status===401){
                    logOut()
                  }
		}
	}
  return (
    <div class="filterRght carDelarRght" >
	<a href="#url" class="closeMnuTwo"><img src={CloseTwo} alt="" /></a>
	<div class="filterRghtTop" >
	{stage=="details"&&<div style={{cursor:"pointer"}} class="media" onClick={() => {clickSeler() }}>
			<figure>
				<img src={data?.user_id?.seller_type=="private_seller"?Account:Frame} alt="" />
			</figure>
			<div class="media-object">
				<h3>{lan.view_profile}</h3>
			</div>
		</div>}
		{stage=="seller"&&<div class="media">
			<figure>
				<img src={data?.user_id?.seller_type=="private_seller"?Account:Frame} alt="" />
			</figure>
			<div class="media-object">
				<h3>{data.user_id.username?data.user_id.username:data.user_id.full_name}</h3>
			</div>
		</div>}
	</div>
	<div class="carDelarRghtLst" style={{border:"none"}}>
		<ul>
			<li >
				<i><img src={carDelarRghtLst1} alt="" /></i>
				<span>{data?.location.city_name}</span>
			</li>
			{data&&data?.user_id?.seller_type!=="private_seller"&&<li >
				<i><img src={carDelarRghtLst2} alt="" /></i>
				<span>{data?.user_id?.address}</span>
				{/* <span>{lan.address}</span> */}
			</li>}
			{data&&data?.user_id?.seller_type!=="private_seller"&&<li >
				<a href={data?.user_id?.direction_link} target="_blank"><i><img src={carDelarRghtLst3} alt="" /></i>
				<span>{lan.directions}</span></a>
			</li>}
			<li class="hverShow">
				<i><img src={carDelarRghtLst4} alt="" /></i>
				<span>{lan.mob_num}</span>
				<em>{data?.user_id?.phone}</em>
			</li>
			{data?.user_id && JSON.parse(localStorage.getItem("login_data"))?._id !== data?.user_id?._id &&<li  onClick={(e)=>saveSeller(e,saveDealerData?.is_favourite)}>
				{/* <i><img src={saveDealerData?.is_favourite ? carDelarRghtLst5:grayHeart} alt="" /></i> */}
				<i><img src={saveDealerData?.is_favourite ? carDelarRghtLst5:Heart} alt="" /></i>
				<span>{saveDealerData?.is_favourite?lan.saved_seller:lan.save_seller}</span>
			</li>}
		</ul>
	</div>
	{/* <div class="carDelarRghtBtmMsg">
		<span>{lan.report_seller}</span>
	</div> */}
</div>
  )
}

export default SideRight