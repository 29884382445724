import React, { useEffect, useRef, useState } from 'react'
import Close_indow from "../../images/Close_indow.png"
import Close from "../../images/close_01.svg"
import slrtyp1 from "../../images/car_dealer.svg"
import slrtyp2 from "../../images/private_seller.svg"
import stear1 from "../../images/1_filter_make.svg"
import drp from "../../images/drp.png"
import stear2 from "../../images/filter_model.svg"
import pricepic from "../../images/2_filter_price.svg"
import year_pic from "../../images/3_filter_year.svg"
import fuel_pic from "../../images/4_filter_fuel.svg"
import mileage_pic from "../../images/5_filter_mileage.svg"
import engine_pic from "../../images/6_filter_engine_size.svg"
import power_pic from "../../images/7_filter_engine_power.svg"
import trans_pic from "../../images/8_filter_transmission.svg"
import drive_pic from "../../images/9_filter_drive.svg"
import color_pic from "../../images/11_filter_color.svg"
import seats_pic from "../../images/12_filter_seats.svg"
import doors_pic from "../../images/13_filter_doors.svg"
import weight_pic from "../../images/20_filter_total_weight.svg"
import env_pic from "../../images/10_filter_enviroment.svg"
import carry_pic from "../../images/21_filter_carrying_capacity.svg"
import axles_pic from "../../images/23_filter_number_of_axles.svg"
import cooling_pic from "../../images/22_filter_cooling.svg"
import location_city from "../../images/location_city.svg"
import midlearw from "../../images/midlearw.svg"
import srtfltr from "../../images/24_filter_other_details.svg"
import cross_icon from "../../images/cross-circle-svgrepo-com (1).svg"
import { shallowEqual, useSelector } from 'react-redux'
import axios from 'axios'
import { BASE_URL, lan } from '../../config'
import { toast } from 'react-toastify'
import Multiselect from 'multiselect-react-dropdown';
import Map from './map'
import $ from "jquery";


export default function FilterModal({ update, filterData, setFilterData, currentFilter,multiselectRef,resetSelection }) {
    const [make, setMake] = useState([]);
    const [model, setModel] = useState([]);
    const [price, setPrice] = useState([]);
    const [color, setColor] = useState([]);
    const [year, setYear] = useState([]);
    const [fuel, setFuel] = useState([]);
    const [mileage, setMileage] = useState([]);
    const [engineSize, setEngineSize] = useState([]);
    const [power, setPower] = useState([]);
    const [tranmission, setTransmission] = useState([]);
    const [drive, setDrive] = useState([]);
    const [seats, setSeats] = useState([]);
    const [door, setDoor] = useState([]);
    const [enviroment, setEnviroment] = useState([]);
    const [weight, setWeight] = useState([]);
    const [carry, setCarry] = useState([]);
    const [cool, setCool] = useState([]);
    const [axles, setAxles] = useState([]);
    const [city, setCity] = useState([]);
    const [preData, setPreData] = useState([]);
    const [otherDetails, setOtherDetails] = useState([]);
    const { postData } = useSelector(
        (state) => ({
            postData: state.dataReducer.postData,

        }),
        shallowEqual
    );

    const fetchCity = async () => {
        try {
            await axios({
                url: BASE_URL + "/api/city/list",
                method: "GET",
            }).then(res => {
                if (res.data.status === 200) {
                    // let arr=[]
                    // res.data.data.map(item=>{
                    //     arr.push(item.city_name)
                    // })
                    setCity(res.data.data);
                    
                } else {
                    toast.error(res.data.message);
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const fetchModels = async (id) => {
        try {
            // setFilterData({ ...filterData, model: { ...filterData.model, value: "" } })
            await axios({
                url: BASE_URL + "/api/get-models",
                method: "POST",
                data:{make_id:id}
            }).then(res => {
                if (res.data.status === 200) {
                    // let arr=[]
                    // res.data.data.map(item=>{
                    //     arr.push(item.city_name)
                    // })
                    setModel(res.data.data);
                    
                } else {
                    toast.error(res.data.message);
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    const fetchData = async () => {
        try {
            await axios({
                url: BASE_URL + "/api/get-pre-value",
                method: "POST",
                data:{
                    id:filterData?.vehicle_type_id
                }
            }).then(res => {
                if (res.data.status === 200) {
                    // let arr=[]
                    // res.data.data.map(item=>{
                    //     arr.push(item.city_name)
                    // })
                    setPreData(res.data.data);
                    setOtherDetails(res.data.other_details);
                    setMake(res.data.makes_data)
                } else {
                    toast.error(res.data.message);
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if(filterData?.vehicle_type_id){

            fetchData();

        }
    }, [filterData])
    useEffect(()=>{
        fetchCity();
    },[])

    let clickOkBtn = (e, filter, id) => {
        e.preventDefault();
        if (filterData[filter].value === "") {
            return toast.error("Odaberite željene opcije!");
        }
        $(id).prop('disabled', false);
        $(id).click();
        $(id).prop('disabled', true);
        $("#" + currentFilter).addClass("active");
        if(filter==="make"){
            setFilterData({ ...filterData, make: { ...filterData.make, open: true },model: { ...filterData.model, open: true } })
        }else{

            setFilterData({ ...filterData, [filter]: { ...filterData[filter], open: true } })
        }
    }

    let clickYearModal = (e) => {
        e.preventDefault();
        if (filterData.year.from === "" || filterData.year.to === "") {
            return toast.error("Odaberite željene opcije!");
        }
        if (parseInt(filterData.year.from) > parseInt(filterData.year.to)) {
            return toast.error("Year range from value should be less than to value");
        }
        $("#yearBtn").prop('disabled', false);
        $("#yearBtn").click();
        $("#yearBtn").prop('disabled', true);
        $("#" + currentFilter).addClass("active");
        setFilterData({ ...filterData, year: { ...filterData.year, open: true } })
    }

    let clickPriceModal = (e) => {
        e.preventDefault();
        if (filterData.price.from === "" || filterData.price.to === "") {
            return toast.error("Odaberite željene opcije!");
        }
        if (parseInt(filterData.price.from) > parseInt(filterData.price.to)) {
            return toast.error("Price range from value should be less than to value");
        }
        $("#priceBtn").prop('disabled', false);
        $("#priceBtn").click();
        $("#priceBtn").prop('disabled', true);
        $("#" + currentFilter).addClass("active");
        setFilterData({ ...filterData, price: { ...filterData.price, open: true } })
    }

    let clickMileageModal = (e) => {
        e.preventDefault();
        if (filterData.mileage.from === "" || filterData.mileage.to === "") {
            return toast.error("Odaberite željene opcije!");
        }
        if (parseInt(filterData.mileage.from) > parseInt(filterData.mileage.to)) {
            return toast.error("Mileage range from value should be less than to value");
        }
        $("#mileageBtn").prop('disabled', false);
        $("#mileageBtn").click();
        $("#mileageBtn").prop('disabled', true);
        $("#" + currentFilter).addClass("active");
        setFilterData({ ...filterData, mileage: { ...filterData.mileage, open: true } })
    }

    let clickSizeModal = (e) => {
        e.preventDefault();
        if (filterData.engine_size.from === "" || filterData.engine_size.to === "") {
            return toast.error("Odaberite željene opcije!");
        }
        if (parseInt(filterData.engine_size.from) > parseInt(filterData.engine_size.to)) {
            return toast.error("Engine size range from value should be less than to value");
        }
        $("#sizeBtn").prop('disabled', false);
        $("#sizeBtn").click();
        $("#sizeBtn").prop('disabled', true);
        $("#" + currentFilter).addClass("active");
        setFilterData({ ...filterData, engine_size: { ...filterData.engine_size, open: true } })
    }

    let clickPowerModal = (e) => {
        e.preventDefault();
        if (filterData.engine_power.from === "" || filterData.engine_power.to === "") {
            return toast.error("Odaberite željene opcije!");
        }
        if (parseInt(filterData.engine_power.from) > parseInt(filterData.engine_power.to)) {
            return toast.error("Engine power range from value should be less than to value");
        }
        $("#powerBtn").prop('disabled', false);
        $("#powerBtn").click();
        $("#powerBtn").prop('disabled', true);
        $("#" + currentFilter).addClass("active");
        setFilterData({ ...filterData, engine_power: { ...filterData.engine_power, open: true } })
    }

    let otherDetailsClick=(item)=>{
        let index=filterData.other_details.value.indexOf(item.slug)
        if(index>-1){
            let arr=[...filterData.other_details.value]
           arr.splice(index, 1)
            setFilterData({...filterData,other_details:{...filterData.other_details,value:arr}})    
        }else{
            let arr=[...filterData.other_details.value]
           arr.push(item.slug)
            setFilterData({...filterData,other_details:{...filterData.other_details,value:arr}})
        }
    }

    let clickDetailsModal = (e) => {
        e.preventDefault();
        if (filterData.other_details.value.length === 0) {
            return toast.error("Odaberite željene opcije!");
        }
        $("#detailsBtn").prop('disabled', false);
        $("#detailsBtn").click();
        $("#detailsBtn").prop('disabled', true);
        $("#" + currentFilter).addClass("active");
        setFilterData({ ...filterData, other_details: { ...filterData.other_details, open: true } })
    }

   let clickLocationModal =(e)=>{
    if (filterData.location.value.length === 0&&filterData.location.state.length ===0) {
        return toast.error("Odaberite regije ili gradove!");
    }
    $("#locationbtn").prop('disabled', false);
    $("#locationbtn").click();
    $("#locationbtn").prop('disabled', true);
    $("#" + currentFilter).addClass("active");
    setFilterData({ ...filterData, location: { ...filterData.location, open: true } })
   }

 

    useEffect(() => {
        if (price.length == 0) {
            let arr_make = []
            let arr_model = []
            let arr_price = []
            let arr_color = []
            let arr_year = []
            let arr_fuel = []
            let arr_mileage = []
            let arr_size = []
            let arr_power = []
            let arr_trans = []
            let arr_drive = []
            let arr_seats = []
            let arr_door = []
            let arr_env = []
            let arr_weight = []
            let arr_carry = []
            let arr_cool = []
            let arr_axles = []
            // postData.length>0&&postData.map(item=>{
            //     item.make&&item.make!==""&&arr_make.push(item.make);
            //      item.model&&item.model!==""&&arr_model.push(item.model)
            //      item.price&&item.price!==""&&arr_price.push(item.price)
            //      item.color&&item.color!==""&&arr_color.push(item.color)
            //      item.year&&item.year!==""&&arr_year.push(item.year)
            //      item.fuel&&item.fuel!==""&&arr_fuel.push(item.fuel)
            //      item.mileage&&item.mileage!==""&&arr_mileage.push(item.mileage)
            //      item.engine_power&&item.engine_power!==""&&arr_power.push(item.engine_power)
            //      item.engine_power&&item.engine_size!==""&&arr_size.push(item.engine_size)
            //      item.transmission&&item.transmission!==""&&arr_trans.push(item.transmission)
            //      item.drive&&item.drive!==""&&arr_drive.push(item.drive)
            //      item.seats&&item.seats!==""&&arr_seats.push(item.seats)
            //      item.doors&&item.doors!==""&&arr_door.push(item.doors)
            //      item.enviroment&&item.enviroment!==""&&arr_env.push(item.enviroment)
            //      item.total_weight&&item.total_weight!==""&&arr_weight.push(item.total_weight)
            //      item.carrying_capcity&&item.carrying_capcity!==""&&arr_carry.push(item.carrying_capcity)
            //      item.cooling&&item.cooling!==""&&arr_cool.push(item.cooling)
            //      item.number_of_axles&&item.number_of_axles!==""&&arr_axles.push(item.number_of_axles)
            // })
            let preValue = {}
            for (let i = 0; i < preData.length; i++) {
                let key = preData[i].slug

                preValue = { ...preValue, [key]: preData[i].preValue }
            }
            // preData.length>0&&preData.map(item=>{
            //     preValue={...preValue,[item.slug]:item.preValue}
            // })
            // preValue.make && setMake([...preValue.make])
            // preValue.model && setModel([...preValue.model])
            preValue.price && setPrice([...preValue.price].sort(function(a, b){return Number(a.value)-Number(b.value)}))
            preValue.color && setColor([...preValue.color])
            preValue.year && setYear([...preValue.year].sort(function(a, b){return Number(b.value)-Number(a.value)}))
            preValue.fuel && setFuel([...preValue.fuel])
            preValue.mileage && setMileage([...preValue.mileage].sort(function(a, b){return Number(a.value)-Number(b.value)}))
            preValue.engine_size && setEngineSize([...preValue.engine_size].sort(function(a, b){return Number(a.value)-Number(b.value)}))
            preValue.engine_power && setPower([...preValue.engine_power].sort(function(a, b){return Number(a.value)-Number(b.value)}))
            preValue.engine_power && setTransmission([...preValue.transmission])
            preValue.drive && setDrive([...preValue.drive])
            preValue.seats && setSeats([...preValue.seats].sort(function(a, b){return Number(a.value)-Number(b.value)}))
            preValue.doors && setDoor([...preValue.doors].sort(function(a, b){return Number(a.value)-Number(b.value)}))
            preValue.enviroment && setEnviroment([...preValue.enviroment])
            preValue.total_weight && setWeight([...preValue.total_weight].sort(function(a, b){return Number(a.value)-Number(b.value)}))
            preValue.carrying_capcity && setCarry([...preValue.carrying_capcity].sort(function(a, b){return Number(a.value)-Number(b.value)}))
            preValue.cooling && setCool([...preValue.cooling])
            preValue.number_of_axles && setAxles([...preValue.number_of_axles].sort(function(a, b){return Number(a.value)-Number(b.value)}))
        }

    }, [preData])

  
    return (
        <>

            <div className="modal dashbordModal fade" id="seller_type" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup autoSalon">
                                <div className="seller-type-pop pt-0">
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <div className="twordobx">
                                                <div className="customRadio" onClick={() => {$("#option-s1").addClass("active");$("#option-s2").removeClass("active");setFilterData({ ...filterData, seller_type: { ...filterData.seller_type, value: "car_dealer" } }) }}>
                                                    <input type="radio" name="power" id="option-s1" className={filterData.seller_type.value==="car_dealer"?"active":""} />
                                                    <label htmlFor="option-s1" className="option option-s1">
                                                        <div className="elricon">
                                                            <img src={slrtyp1} alt />
                                                        </div>
                                                        <p>{lan.car_dealer}</p>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="twordobx">
                                                <div className="customRadio " onClick={() => {$("#option-s2").addClass("active");$("#option-s1").removeClass("active"); setFilterData({ ...filterData, seller_type: { ...filterData.seller_type, value: "private_seller" } }) }}>
                                                    <input type="radio" name="power" defaultValue={3} id="option-s2" className={filterData.seller_type.value==="private_seller"?"active":""} />
                                                    <label htmlFor="option-s2" className="option option-s2">
                                                        <div className="elricon">
                                                            <img src={slrtyp2} alt />
                                                        </div>
                                                        <p>{lan.private_seller.toLowerCase()}</p>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn">
                                    <a href onClick={(e) => { clickOkBtn(e, "seller_type", "#typebtn") }} className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="typebtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal dashbordModal fade" id="make" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup customMake">
                                <div className="seller-type-pop bmwpop fusClor14">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="twordobx">
                                                <div className="seletfrm">
                                                    <div className="stear-icon">
                                                        <img src={stear1} alt />
                                                    </div>
                                                    <div className="chosefrm">
                                                        <i><img src={drp} alt /></i>
                                                        <select className="chosemk" value={filterData.make.value} onChange={e => {setFilterData({ ...filterData, make: { ...filterData.make, value: e.target.value },model: { ...filterData.model, value: "" }});fetchModels(e.target.value)}}>
                                                            <option hidden disabled value="">{lan.choose_make}</option>
                                                            {make.map(item => {
                                                                return <option value={item?._id}>{item?.make_name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="twordobx">
                                                <div className={filterData.make.value ? "seletfrm" : "seletfrm disabled"}>
                                                    <div className="stear-icon">
                                                        <img src={stear2} alt />
                                                    </div>
                                                    <div className="chosefrm">
                                                        <i><img src={drp} alt /></i>
                                                        <select disabled={filterData.make.value?false:true} value={filterData.model.value} className="chosemk" onChange={e => { console.log(e.target.value); setFilterData({ ...filterData, model: { ...filterData.model, value: e.target.value } }) }}>
                                                            <option hidden disabled value="">{lan.choose_model}</option>
                                                            {model.map(item => {
                                                                return <option value={item?._id}>{item?.model_name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn">
                                    <a href onClick={(e) => { clickOkBtn(e, "make", "#modelbtn") }} className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="modelbtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal dashbordModal fade" id="price" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup">
                                <div className="seller-type-pop">
                                    {/* <div class="row">
                                  <div class="col-lg-6 col-md-6 col-12">
                                      <div class="twordobx">
                                          <div class="seletfrm">
                                              <div class="stear-icon">
                                                  <img src="assets/images/stear1.png" alt="" />
                                              </div>
                                              <div class="chosefrm">
                                                  <i><img src="assets/images/drp.png" alt=""></i>
                                                  <select class="chosemk">
                                                      <option>choose make</option>
                                                  </select>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-lg-6 col-md-6 col-12">
                                      <div class="twordobx">
                                          <div class="seletfrm">
                                              <div class="stear-icon">
                                                  <img src="assets/images/stear2.png" alt="" />
                                              </div>
                                              <div class="chosefrm">
                                                  <i><img src="assets/images/drp.png" alt=""></i>
                                                  <select class="chosemk">
                                                      <option>choose model</option>
                                                  </select>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div> */}
                                    <div className="price-range-popup">
                                        <div className="pricicon">
                                            <i></i>
                                            <span><img src={pricepic} alt /></span>
                                            <p>{lan.price_range.toUpperCase()}</p>
                                        </div>
                                        <div className="priceinput">
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select value={filterData.price.from} className="chosemk" onChange={e => { console.log(e.target.value); setFilterData({ ...filterData, price: { ...filterData.price, from: e.target.value } }) }}>
                                                    <option hidden disabled value="">{lan.from}</option>
                                                    {price.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="middlearw">
                                                <i><img src={midlearw} alt /></i>
                                            </div>
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select value={filterData.price.to} className="chosemk" onChange={e => { console.log(e.target.value); setFilterData({ ...filterData, price: { ...filterData.price, to: e.target.value } }) }}>
                                                    <option hidden disabled value="">{lan.to}</option>
                                                    {price.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn" >
                                    <a href onClick={(e) => { clickPriceModal(e) }} className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="priceBtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal dashbordModal fade" id="year" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup">
                                <div className="seller-type-pop">
                                    <div className="price-range-popup">
                                        <div className="pricicon">
                                            <span><img src={year_pic} alt /></span>
                                            <p>{lan.year}</p>
                                        </div>
                                        <div className="priceinput">
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, year: { ...filterData.year, from: e.target.value } }) }} value={filterData.year.from} className="chosemk">
                                                    <option hidden disabled value="">{lan.from}</option>
                                                    {year.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                                {/* <input placeholder='from' onChange={(e)=>{setFilterData({...filterData,year:{...filterData.year,from:e.target.value}})}} value={filterData.year.from} className="chosemk"/> */}
                                            </div>
                                            <div className="middlearw">
                                                <i><img src={midlearw} alt /></i>
                                            </div>
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, year: { ...filterData.year, to: e.target.value } }) }} value={filterData.year.to} className="chosemk">
                                                    <option hidden disabled value="">{lan.to}</option>
                                                    {year.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                                {/* <input placeholder="to" onChange={(e)=>{setFilterData({...filterData,year:{...filterData.year,to:e.target.value}})}} value={filterData.year.to} className="chosemk"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn" >
                                    <a href onClick={(e) => { clickYearModal(e) }} className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="yearBtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal dashbordModal fade" id="fuel" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup">
                                <div className="seller-type-pop">
                                    <div className="price-range-popup">
                                        <div className="pricicon">
                                            <span><img src={fuel_pic} alt /></span>
                                            <p>{lan.fuel}</p>
                                        </div>
                                        <div className="priceinput">
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, fuel: { ...filterData.fuel, value: e.target.value } }) }} value={filterData.fuel.value} className="chosemk">
                                                    <option hidden disabled value={""}>{lan.choose}</option>
                                                    {fuel.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                                {/* <input placeholder='Type here..' onChange={(e)=>{setFilterData({...filterData,fuel:{...filterData.fuel,value:e.target.value}})}} value={filterData.fuel.value} className="chosemk"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn">
                                    <a href onClick={(e) => { clickOkBtn(e, "fuel", "#fuelbtn") }} className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="fuelbtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal dashbordModal fade" id="mileage" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup">
                                <div className="seller-type-pop">
                                    <div className="price-range-popup">
                                        <div className="pricicon">
                                            <span><img src={mileage_pic} alt /></span>
                                            <p>{lan.mileage}</p>
                                        </div>
                                        <div className="priceinput">
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, mileage: { ...filterData.mileage, from: e.target.value } }) }} value={filterData.mileage.from} className="chosemk">
                                                    <option hidden disabled value="">{lan.from}</option>
                                                    {mileage.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                                {/* <input placeholder='from' onChange={(e)=>{setFilterData({...filterData,mileage:{...filterData.mileage,from:e.target.value}})}} value={filterData.mileage.from} className="chosemk"/> */}
                                            </div>
                                            <div className="middlearw">
                                                <i><img src={midlearw} alt /></i>
                                            </div>
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, mileage: { ...filterData.mileage, to: e.target.value } }) }} value={filterData.mileage.to} className="chosemk">
                                                    <option hidden disabled value="">{lan.to}</option>
                                                    {mileage.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                                {/* <input placeholder="to" onChange={(e)=>{setFilterData({...filterData,year:{...filterData.year,to:e.target.value}})}} value={filterData.year.to} className="chosemk"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn" >
                                    <a href onClick={(e) => { clickMileageModal(e) }} className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="mileageBtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal dashbordModal fade" id="engine_size" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup">
                                <div className="seller-type-pop">
                                    <div className="price-range-popup">
                                        <div className="pricicon">
                                            <span><img src={engine_pic} alt /></span>
                                            <p>{lan.size}</p>
                                        </div>
                                        <div className="priceinput">
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, engine_size: { ...filterData.engine_size, from: e.target.value } }) }} value={filterData.engine_size.from} className="chosemk">
                                                    <option hidden disabled value="">{lan.from}</option>
                                                    {engineSize.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                                {/* <input placeholder='from' onChange={(e)=>{setFilterData({...filterData,engine_size:{...filterData.engine_size,from:e.target.value}})}} value={filterData.engine_size.from} className="chosemk"/> */}
                                            </div>
                                            <div className="middlearw">
                                                <i><img src={midlearw} alt /></i>
                                            </div>
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, engine_size: { ...filterData.engine_size, to: e.target.value } }) }} value={filterData.engine_size.to} className="chosemk">
                                                    <option hidden disabled value="">{lan.to}</option>
                                                    {engineSize.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                                {/* <input placeholder="to" onChange={(e)=>{setFilterData({...filterData,engine_size:{...filterData.engine_size,to:e.target.value}})}} value={filterData.year.to} className="chosemk"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn" >
                                    <a href onClick={(e) => { clickSizeModal(e) }} className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="sizeBtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal dashbordModal fade" id="engine_power" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup">
                                <div className="seller-type-pop">
                                    <div className="price-range-popup">
                                        <div className="pricicon">
                                            <span><img src={power_pic} alt /></span>
                                            <p style={{marginBottom:"0"}}>{lan.power}</p>
                                            <span style={{color:"#959b9e"}}>(kW)</span>
                                        </div>
                                        <div className="priceinput">
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, engine_power: { ...filterData.engine_power, from: e.target.value } }) }} value={filterData.engine_power.from} className="chosemk">
                                                    <option hidden disabled value="">{lan.from}</option>
                                                    {power.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                                {/* <input placeholder='from' onChange={(e)=>{setFilterData({...filterData,engine_power:{...filterData.engine_power,from:e.target.value}})}} value={filterData.engine_power.from} className="chosemk"/> */}
                                            </div>
                                            <div className="middlearw">
                                                <i><img src={midlearw} alt /></i>
                                            </div>
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, engine_power: { ...filterData.engine_power, to: e.target.value } }) }} value={filterData.engine_power.to} className="chosemk">
                                                    <option hidden disabled value="">{lan.to}</option>
                                                    {power.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                                {/* <input placeholder="to" onChange={(e)=>{setFilterData({...filterData,engine_power:{...filterData.year,to:e.target.value}})}} value={filterData.year.to} className="chosemk"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn" >
                                    <a href onClick={(e) => { clickPowerModal(e) }} className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="powerBtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal dashbordModal fade" id="transmission" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup">
                                <div className="seller-type-pop">
                                    <div className="price-range-popup">
                                        <div className="pricicon">
                                            <span><img src={trans_pic} alt /></span>
                                            <p>{lan.transmission}</p>
                                        </div>
                                        <div className="priceinput">
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, transmission: { ...filterData.transmission, value: e.target.value } }) }} value={filterData.transmission.value} className="chosemk">
                                                    <option hidden disabled value="">{lan.choose}</option>
                                                    {tranmission.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                                {/* <input placeholder='Type here..' onChange={(e)=>{setFilterData({...filterData,transmission:{...filterData.transmission,value:e.target.value}})}} value={filterData.transmission.value} className="chosemk"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn">
                                    <a href onClick={(e) => { clickOkBtn(e, "transmission", "#transmissionbtn") }} className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="transmissionbtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal dashbordModal fade" id="drive" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup">
                                <div className="seller-type-pop">
                                    <div className="price-range-popup">
                                        <div className="pricicon">
                                            <span><img src={drive_pic} alt /></span>
                                            <p>{lan.drive}</p>
                                        </div>
                                        <div className="priceinput">
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, drive: { ...filterData.drive, value: e.target.value } }) }} value={filterData.drive.value} className="chosemk">
                                                    <option hidden disabled value={""}>{lan.choose}</option>
                                                    {drive.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                                {/* <input placeholder='Type here..' onChange={(e)=>{setFilterData({...filterData,drive:{...filterData.drive,value:e.target.value}})}} value={filterData.drive.value} className="chosemk"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn">
                                    <a href onClick={(e) => { clickOkBtn(e, "drive", "#drivebtn") }} className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="drivebtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal dashbordModal fade" id="color" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup">
                                <div className="seller-type-pop">
                                    <div className="price-range-popup">
                                        <div className="pricicon">
                                            <span><img src={color_pic} alt /></span>
                                            <p>{lan.color}</p>
                                        </div>
                                        <div className="priceinput">
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, color: { ...filterData.color, value: e.target.value } }) }} value={filterData.color.value} className="chosemk">
                                                    <option hidden disabled value="">{lan.choose}</option>
                                                    {color.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                                {/* <input placeholder='Type here..' onChange={(e)=>{setFilterData({...filterData,color:{...filterData.color,value:e.target.value}})}} value={filterData.color.value} className="chosemk"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn">
                                    <a href onClick={(e) => { clickOkBtn(e, "color", "#colorbtn") }} className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="colorbtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal dashbordModal fade" id="seats" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup">
                                <div className="seller-type-pop">
                                    <div className="price-range-popup">
                                        <div className="pricicon">
                                            <span><img src={seats_pic} alt /></span>
                                            <p>{lan.seats}</p>
                                        </div>
                                        <div className="priceinput">
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, seats: { ...filterData.seats, value: e.target.value } }) }} value={filterData.seats.value} className="chosemk">
                                                    <option hidden disabled value="">{lan.choose}</option>
                                                    {seats.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                                {/* <input placeholder='Type here..' onChange={(e)=>{setFilterData({...filterData,seats:{...filterData.seats,value:e.target.value}})}} value={filterData.seats.value} className="chosemk"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn">
                                    <a href onClick={(e) => { clickOkBtn(e, "seats", "#seatsbtn") }} className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="seatsbtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal dashbordModal fade" id="doors" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup">
                                <div className="seller-type-pop">
                                    <div className="price-range-popup">
                                        <div className="pricicon">
                                            <span><img src={doors_pic} alt /></span>
                                            <p>{lan.doors}</p>
                                        </div>
                                        <div className="priceinput">
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, doors: { ...filterData.doors, value: e.target.value } }) }} value={filterData.doors.value} className="chosemk">
                                                    <option hidden disabled value="" >{lan.choose}</option>
                                                    {door.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                                {/* <input placeholder='Type here..' onChange={(e)=>{setFilterData({...filterData,doors:{...filterData.doors,value:e.target.value}})}} value={filterData.doors.value} className="chosemk"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn">
                                    <a href onClick={(e) => { clickOkBtn(e, "doors", "#doorsbtn") }} className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="doorsbtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal dashbordModal fade" id="enviroment" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup">
                                <div className="seller-type-pop">
                                    <div className="price-range-popup">
                                        <div className="pricicon">
                                            <span><img src={env_pic} alt /></span>
                                            <p>{lan.env}</p>
                                        </div>
                                        <div className="priceinput">
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, enviroment: { ...filterData.enviroment, value: e.target.value } }) }} value={filterData.enviroment.value} className="chosemk">
                                                    <option hidden disabled value="" >{lan.choose}</option>
                                                    {enviroment.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                                {/* <input placeholder='Type here..' onChange={(e)=>{setFilterData({...filterData,enviroment:{...filterData.enviroment,value:e.target.value}})}} value={filterData.enviroment.value} className="chosemk"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn">
                                    <a href onClick={(e) => { clickOkBtn(e, "enviroment", "#enviromentbtn") }} className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="enviromentbtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal dashbordModal fade" id="total_weight" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup">
                                <div className="seller-type-pop">
                                    <div className="price-range-popup">
                                        <div className="pricicon">
                                            <span><img src={weight_pic} alt /></span>
                                            <p>{lan.weight}</p>
                                        </div>
                                        <div className="priceinput">
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, total_weight: { ...filterData.total_weight, value: e.target.value } }) }} value={filterData.total_weight.value} className="chosemk">
                                                    <option hidden disabled value="">{lan.choose}</option>
                                                    {weight.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                                {/* <input placeholder='Type here..' onChange={(e)=>{setFilterData({...filterData,total_weight:{...filterData.total_weight,value:e.target.value}})}} value={filterData.total_weight.value} className="chosemk"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn">
                                    <a href onClick={(e) => { clickOkBtn(e, "total_weight", "#total_weightbtn") }} className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="total_weightbtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal dashbordModal fade" id="carrying_capcity" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup">
                                <div className="seller-type-pop">
                                    <div className="price-range-popup">
                                        <div className="pricicon">
                                            <span><img src={carry_pic} alt /></span>
                                            <p>{lan.capacity}</p>
                                        </div>
                                        <div className="priceinput">
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, carrying_capcity: { ...filterData.carrying_capcity, value: e.target.value } }) }} value={filterData.carrying_capcity.value} className="chosemk">
                                                    <option hidden disabled value="">{lan.choose}</option>
                                                    {carry.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                                {/* <input placeholder='Type here..' onChange={(e)=>{setFilterData({...filterData,carrying_capcity:{...filterData.carrying_capcity,value:e.target.value}})}} value={filterData.carrying_capcity.value} className="chosemk"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn">
                                    <a href onClick={(e) => { clickOkBtn(e, "carrying_capcity", "#carrying_capcitybtn") }} className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="carrying_capcitybtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal dashbordModal fade" id="number_of_axles" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup">
                                <div className="seller-type-pop">
                                    <div className="price-range-popup">
                                        <div className="pricicon">
                                            <span><img src={axles_pic} alt /></span>
                                            <p>{lan.axles}</p>
                                        </div>
                                        <div className="priceinput">
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, number_of_axles: { ...filterData.number_of_axles, value: e.target.value } }) }} value={filterData.number_of_axles.value} className="chosemk" >
                                                    <option hidden disabled value="">{lan.choose}</option>
                                                    {axles.map(item => {
                                                        return <option value={item?.value}>{item?.value}</option>
                                                    })}
                                                </select>
                                                {/* <input placeholder='Type here..' onChange={(e)=>{setFilterData({...filterData,number_of_axles:{...filterData.number_of_axles,value:e.target.value}})}} value={filterData.number_of_axles.value} className="chosemk"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn">
                                    <a href onClick={(e) => { clickOkBtn(e, "number_of_axles", "#number_of_axlesbtn") }} className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="number_of_axlesbtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal dashbordModal fade" id="motorcycle_atv" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup">
                                <div className="seller-type-pop">
                                    <div className="price-range-popup">
                                        <div className="pricicon">
                                            <span><img src={pricepic} alt /></span>
                                            <p>MOTORCYCLE - ATV</p>
                                        </div>
                                        <div className="priceinput">
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, motorcycle_atv: { ...filterData.motorcycle_atv, value: e.target.value } }) }} value={filterData.motorcycle_atv.value} className="chosemk">
                                                    <option disabled value="">choose</option>
                                                    <option value="MOTORCYCLE">MOTORCYCLE</option>
                                                    <option value="ATV">ATV</option>
                                                </select>
                                                {/* <input placeholder='Type here..' onChange={(e)=>{setFilterData({...filterData,motorcycle_atv:{...filterData.motorcycle_atv,value:e.target.value}})}} value={filterData.motorcycle_atv.value} className="chosemk"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn">
                                    <a href onClick={(e) => { clickOkBtn(e, "motorcycle_atv", "#motorcycle_atvbtn") }} className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="motorcycle_atvbtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal dashbordModal fade" id="cooling" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup">
                                <div className="seller-type-pop">
                                    <div className="price-range-popup">
                                        <div className="pricicon">
                                            <span><img src={cooling_pic} alt /></span>
                                            <p>{lan.COOLING}</p>
                                        </div>
                                        <div className="priceinput">
                                            <div className="chosefrm">
                                                <i><img src={drp} alt /></i>
                                                <select onChange={(e) => { setFilterData({ ...filterData, cooling: { ...filterData.cooling, value: e.target.value } }) }} value={filterData.cooling.value} className="chosemk">
                                                    <option hidden disabled value="">{lan.choose}</option>
                                                    {cool.map(item => {
                                                        return <option value={item.value}>{item.value}</option>
                                                    })}
                                                </select>
                                                {/* <input placeholder='Type here..' onChange={(e)=>{setFilterData({...filterData,cooling:{...filterData.cooling,value:e.target.value}})}} value={filterData.cooling.value} className="chosemk"/> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn">
                                    <a href onClick={(e) => { clickOkBtn(e, "cooling", "#coolingbtn") }} className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="coolingbtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal dashbordModal fade" id="location" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="selrtypoup mapfltr">
                                <div className="seller-type-pop">
                                    <div className="location-poup">
                                        <div className="pricicon">
                                            <span><img src={location_city} alt /></span>
                                            {/* <Map/> */}
                                            <p>{lan.seller_location}</p>
                                        </div>
                                        <div className="mapopbx">
                                            <div className="mapic">
                                                {/* <img src={map} alt /> */}
                                                <Map resetSelection={resetSelection} filterData={filterData} setFilterData={setFilterData} />
                                            </div>
                                            {/* <div className="midlor">
                                                <p>REGIJA ILI GRAD</p>
                                            </div> */}
                                            <div className="chosefrm">
                                                <i class="drpArrow"><img src={drp} alt /></i>
                                                <Multiselect
                                                    displayValue="city_name"
                                                    ref={multiselectRef}
                                                    emptyRecordMsg="Nepostojeći grad!"
                                                    selectedValues={filterData.location.value}
                                                    style={{
                                                        chips:{
                                                            background:"#202629",borderRadius:0
                                                        }}
                                                    }
                                                    customCloseIcon={<><span className='closeArw'><img src={cross_icon} alt /></span></>}
                                                    // onKeyPressFn={function noRefCheck(){console.log("key")}}
                                                    onRemove={function noRefCheck(selectedList, selectedItem) { setFilterData({ ...filterData, location:{...filterData.location,value:selectedList} }) }}
                                                    // onSearch={function noRefCheck(){console.log("sear")}}
                                                    onSelect={function noRefCheck(selectedList, selectedItem) {
                                                        $(".region_sec").removeClass("active"); 
                                                        setFilterData({ ...filterData, location: {...filterData.location,value:selectedList,state:[]} }) }}
                                                    placeholder="ili odaberi gradove"
                                                    options={city}
                                                />
                                                {/* <select className="chosemk" value={filterData.location.value} onChange={e=>{;setFilterData({...filterData,location:{...filterData.location,value:e.target.value}})}}>
                                                    <option value="">cities</option>
                                                    {city.map(item=>{
                                                             return   <option value={item.city_name}>{item.city_name}</option>
                                                            })}
                                                </select> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="okbtn">
                                    <a href onClick={clickLocationModal}  className="oksbmit">ok</a>
                                    <button style={{ opacity: 0 }} id="locationbtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal dashbordModal fade otherFilter" id="other_details" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt />
                                <img src={Close} alt />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="otherdetl-popup allopfiltr other-fill" >
                                <div className="text-center other-fill-box">
                                    <figure>
                                        <img src={srtfltr} alt />
                                    </figure>
                                    <h4>{lan.details}</h4>
                                </div>
                                <div className="filtrdts">
                                    <div className="row">
                                        {otherDetails&&otherDetails.length>0&&otherDetails.map((item,idx)=>{
                                            return <div className="col-lg-4 col-sm-6 col-12">
                                            <div className="customRadio ">
                                                <input type="checkbox" onClick={()=>otherDetailsClick(item)} name={"power"+idx} id={"option-0"+idx} />
                                                <label htmlFor={"option-0"+idx} className={`option${idx} option-0${idx}`}>{item.name}</label>
                                            </div>
                                        </div>
                                        })}
                                    </div>
                                </div>
                                <div className="okbtn">
                                    
                                    <a href onClick={ clickDetailsModal} className="oksbmit" >ok</a>
                                    <button style={{ opacity: 0 }} id="detailsBtn" disabled data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>

    )
}
