import React, { useEffect, useState } from 'react'
import { Markup } from 'interweave'
import Close_indow from "../images/Close_indow.png"
import Close from "../images/close_01.svg"
import { BASE_URL } from '../config'

function WhyUs({cmsData}) {
	
  return (
    <div class="modal dashbordModal ovrfHide  fade" id="blankModalCall" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content" style={{overflow:"auto"}}>
			<div class="modal-header">
				<button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close">
					<img src={Close_indow} alt="" />
					<img src={Close} alt="" />
				</button>
			</div>
			<div class="modal-body">
				<div class="blankpnl spacediv">
					{/* <figure>
						<img src={`${BASE_URL}/uploads/cms/${cmsData?.banner_image}`} alt="" />
					</figure> */}
					{cmsData&&<Markup content={cmsData?.description} />}
					{/* <h4>{cmsData.description}</h4> */}
				</div>
			</div>
		</div>
	</div>
</div>
  )
}

export default WhyUs