import { SET_USER_DATA,SET_POST_DATA,SET_CMS_DATA,SET_PAGE,SET_MORE,SET_MODE,SET_LANDSCAPE,SET_CURRENT_PRODUCT,SET_LISTING_MODE,SET_PRE_PRODUCT,SET_SORT_VALUE,SET_SORT_FIELD,SET_SEARCH_VALUE,SET_FILTERS,SET_BANNER,SET_LOADING,SET_MODAL,SET_ADD_LIST} from "./Action";

const initialState = {
    userData:"",
    postData:[],
    cmsData:"",
    mode:"",
    landscape:true,
    currentProduct:"",
    sortField:"",
    sortvalue:"",
    searchValue:"",
    preProduct:[],
    listingMode:"list",
    hasMore:true,
    pageNo:0,
    filters:{},
    banner:[],
    isLoading:true,
    modalBack:false,
    listAdding:false,
  };

  const reducer=(state=initialState,{type,payload})=>{
    switch (type){
        case SET_USER_DATA:
            return{ ...state, userData: payload };
        case SET_POST_DATA:
            return{ ...state, postData: payload };
            case SET_CMS_DATA:
            return{ ...state, cmsData: payload };
            case SET_MODE:
            return{ ...state, mode: payload };
            case SET_LANDSCAPE:
            return{ ...state, landscape: payload };
            case SET_CURRENT_PRODUCT:
            return{ ...state, currentProduct: payload };
            case SET_PRE_PRODUCT:
            return{ ...state, preProduct: payload };
            case SET_LISTING_MODE:
            return{ ...state, listingMode: payload };
            case SET_SORT_VALUE:
            return{ ...state, sortvalue: payload };
            case SET_SORT_FIELD:
            return{ ...state, sortField: payload };
            case SET_SEARCH_VALUE:
            return{ ...state, searchValue: payload };
            case SET_MORE:
                return{ ...state, hasMore: payload };
            case SET_PAGE:
            return{ ...state, pageNo: payload };
            case SET_FILTERS:
            return{ ...state, filters: payload };
            case SET_BANNER:
            return{ ...state, banner: payload };
            case SET_LOADING:
            return{ ...state, isLoading: payload };
            case SET_MODAL:
            return{ ...state, modalBack: payload };
            case SET_ADD_LIST:
            return{ ...state, listAdding: payload };
        default:
            return state;
    }
  }


  export default reducer