export const BASE_HREF = "/";
export const BASE_URL = "https://vector59862653.mobi.ba";
// export const BASE_URL = "https://www.mobi.ba:3009";
// export const BASE_URL = "http://localhost:3009"; 
// export const BASE_URL = "https://developer-beta.com:3009"; 

// export const lan = {
// password_change_success:"Password changed succesfully!"
// required_error:"all fields are required!",
//     about: "ABOUT US",
//     support: "USER SUPPORT",
//     price: "PRICING",
//     help: "HELP",
//     use_filters: "USE FILTERS",
//     vehicle_make_model: "VEHICLE MAKE AND MODEL",
//     vehicle_price: "VEHICLE PRICE",
//     year: "YEAR",
//     fuel: "FUEL",
//     mileage: "MILEAGE",
//     size: "ENGINE SIZE",
//     power: "ENGINE POWER",
//     transmission: "TRANSMISSION",
//     drive: "DRIVE",
//     env: "ENVIROMENT",
//     color: "COLOR",
//     seats: "SEATS",
//     doors: "DOORS",
//     selling: "SELLING",
//     rent: "RENT",
//     new: "NEW",
//     type: "SELLER TYPE",
//     make_mode: "MAKE & MODEL",
//     model: "MODEL",
//     make: "MAKE",
//     weight: "TOTAL WEIGHT",
//     capacity: "CARRYING CAPACITY",
//     COOLING: "COOLING",
//     axles: "NUMBER OF AXLES",
//     details: "OTHER DETAILS",
//     filters: "FILTERS",
//     sort: "SORT",
//     sort_by: "sort by...",
//     search: "search...",
//     use_filters: "USE FILTERS",
//     usser_account: "USER ACCOUNT",
//     why: "WHY",
//     settings: "SETTINGS",
//     logout: "LOGOUT",
//     credits: "CREDITS",
//     buy_credit: "BUY CREDITS",
//     new_listings: "NEW LISTING",
//     active_listings: "ACTIVE LISTINGS",
//     inactive_listings: "INACTIVE LISTINGS",
//     finish_listings: "FINISHED LISTINGS",
//     buying: "BUYING",
//     saved_listings: "SAVED LISTINGS",
//     saved_deales: "SAVED DEALERS",
//     choose_make: "choose make",
//     choose_model: "choose model",
//     enter_price: "enter price",
//     choose: "choose",
//     enter: "enter",
//     car_images: "CAR IMAGES",
//     upload: "UPLOAD",
//     add_credit: "ADD CREDITS",
//     credits_amount: "credits amount",
//     assign: "ASSIGN",
//     automatic: "AUTOMATIC ASSIGN CREDITS",
//     month: "MONTH",
//     credit: "CREDIT",
//     credit_balance: "CREDIT BALANCE",
//     publish_listings: "PUBLISH LISTING",
//     associated_credits: "TOTAL ASSOCIATED CREDITS",
//     for_vehicle: "FOR 1 VEHICLE",
//     motorcycle: "MOTORCYCLE",
//     buying_credits: "BUYING CREDITS",
//     buy: "BUY",
//     username: "username",
//     password: "password",
//     login: "LOGIN",
//     forgot_pass: "FORGOT PASSWORD?",
//     dont_account: "DON’T HAVE ACCOUNT YET?",
//     register: "REGISTER",
//     new_pass: "NEW PASSWORD",
//     otp_code: "OTP code",
//     enter_code: "ENTER CODE",
//     confirm: "CONFIRM",
//     city: "city",
//     address: "address",
//     mobile_phone: "mobile phone",
//     old_mobile_phone: "old mobile phone",
//     new_mobile_phone: "new mobile phone",
//     confirm_phone: "CONFIRM PHONE NUMBER",
//     buyer_seller: "private buyer/seller",
//     car_dealer: "car dealer",
//     relevance: "relevance",
//     price_l_h: "price – low to high",
//     price_h_l: "price – high to low",
//     mileage_l_h: "mileage – low to high",
//     mileage_h_l: "mileage – high to low",
//     year_l_h: "year – low to high",
//     year_h_l: "year – high to low",
//     listing_new: "listing – newest first",
//     listing_old: "listing – oldest first",
//     seller_location: "seller location",
//     price_range: "price range",
//     range: "RANGE",
//     date_create: "DATE OF CREATION",
//     seller_id: "SELLER ID",
//     car_listing_id: "CAR LISTING ID",
//     show: "SHOW",
//     view_profile: "VIEW PROFILE",
//     SAVE_LISTING: "SAVED LISTING",
//     SAVE_LIST: "SAVE LISTING",
//     directions: "DIRECTIONS",
//     mob_num: "MOBILE NUMBER",
//     save_seller: "SAVE SELLER",
//     detail_desc: "DETAILED DESCRIPTION",
//     other_listing: "OTHER LISTINGS FROM THIS SELLER",
//     report_seller: "REPORT SELLER",
//     car_dealer_desc: "CAR DEALER DESCRIPTION",
//     listing_option: "LISTING OPTIONS",
//     add_edit_credit: "ADD/REMOVE CREDITS",
//     edit_image: "EDIT IMAGES",
//     edit_details: "EDIT DETAILS",
//     edit_desc: "EDIT DESCRIPTION",
//     mark_sold: "MARK AS SOLD",
//     profile_option: "PROFILE OPTIONS",
//     edit_pass: "EDIT PASSWORD",
//     edit_phone: "EDIT PHONE NUMBER",
//     edit_address: "EDIT ADDRESS",
//     desc: "DESCRIPTION",
//     assign_credit: "ASSIGNED CREDITS",
//     listing_active: "LISTING IS ACTIVE",
//     listing_inactive: "LISTING IS INACTIVE",
//     please: "Please",
//     otp_error: "Please enter otp!",
//     username_error: "Please enter username!",
//     pass_error: "Please enter password!",
//     rotate_potrait: "rotate device for better view",
//     user_type: "User type",
//     logout_success: "Logged out succesfully",
//     user: "USER",
//     account: "ACCOUNT",
//     listing_save: "LISTING SAVED",
//     private_seller: "PRIVATE SELLER",
//     used: "USED",
//     location: "LOCATION",
//     new_used: "NEW-USED",
//     from: "from",
//     to: "to",
//     list:"LIST",
//     submit:"SUBMIT",
//     viber_msg:"VIBER MESSAGE",
//         call:"CALL SELLER",
//      see_other_list:"Other listings from the same seller",
//      see_all_list:"See all of the listings",
//    wishlist_add_msg:"Product added to your wishlist",
// wishlist_remove_msg:"Product removed from wishlist",
// wishlist_add_msg:"Seller added to your wishlist",
// wishlist_remove_msg:"Seller removed from wishlist",
// login_continue_msg:"Please login to continue",
// credit_add_succss:"Credit added successfully",
// regSubmit:"SUBMIT",
// no_data:"No Data Found"
// }

export const lan = {
   about: "MARKETING",
   support: "KONTAKT",
   price: "CJENOVNIK",
   help: "UPUTSTVA",
   vehicle_make_model: "MARKA I MODEL VOZILA",
   vehicle_price: "CIJENA VOZILA",
   year: "GODIŠTE",
   fuel: "GORIVO",
   mileage: "KILOMETRAŽA",
   size: "KUBIKAŽA MOTORA",
   power: "SNAGA MOTORA",
   transmission: "TRANSMISIJA",
   drive: "POGON",
   env: "EKOLOŠKA NORMA",
   color: "BOJA",
   seats: "BROJ SJEDIŠTA",
   doors: "BROJ VRATA",
   selling: "PRODAJA",
   rent: "RENTANJE",
   new: "NOVO",
   type: "TIP PRODAVCA",
   make_mode: "PROIZVOĐAČ",
   weight: "UKUPNA TEŽINA",
   capacity: "NOSIVOST",
   COOLING: "NAČIN HLAĐENJA",
   axles: "BROJ OSOVINA",
   details: "DODATNA OPREMA",
   filters: "FILTERI",
   sort: "SORTIRAJ",
   sort_by: "sortiraj oglase",
   search: "pretraga...",
   use_filters: "PRIMIJENI FILTERE",
   usser_account: "KORISNIČKI RAČUN",
   why: "ZAŠTO",
   settings: "POSTAVKE",
   logout: "ODJAVA",
   credits: "KREDITA",
   buy_credit: "DOPUNI",
   new_listings: "OBJAVI OGLAS",
   active_listings: "AKTIVNI OGLASI",
   inactive_listings: "NEAKTIVNI OGLASI",
   finish_listings: "ZAVRŠENI OGLASI",
   buying: "KUPOVINA",
   saved_listings: "SPREMLJENI OGLASI",
   saved_deales: "SPREMLJENI PRODAVCI",
   choose_make: "odaberi proizvođača",
   choose_model: "odaberi model",
   model: "MODEL",
   make: "PROIZVOĐAČ",
   enter_price: "Unesi cijenu",
   choose: "odaberi",
   enter: "Unesi",
   car_images: "SLIKE VOZILA",
   upload: "UČITAJ",
   add_credit: "DODIJELI KREDITE",
   credits_amount: "iznos kredita",
   assign: "DODIJELI",
   automatic: "AUTOMATSKI DODIJELI 1 KREDIT SVAKI MJESEC",
   month: "MJESEC",
   credit: "KREDITA",
   credit_balance: "STANJE KREDITA",
   publish_listings: "ZAVRŠI OBJAVU",
   associated_credits: "UKUPNO DODIJELJENO KREDITA",
   for_vehicle: "ZA JEDNO VOZILO",
   motorcycle: "MOTOCIKL",
   buying_credits: "DOPUNA KREDITA",
   buy: "KUPI",
   username: "korisničko ime",
   password: "lozinka",
   login: "ULOGUJ SE",
   forgot_pass: "Zaboravljena lozinka?",
   dont_account: "Još uvijek nemaš račun?",
   register: "ZAVRŠI REGISTRACIJU",
   register_login: "REGISTRUJ SE",
   new_pass: "NOVA LOZINKA",
   otp_code: "Unesi SMS kod",
   enter_code: "UNESI",
   confirm: "POTVRDI",
   city: "grad",
   address: "adresa",
   mobile_phone: "broj mobitela",
   old_mobile_phone: "stari broj mobitela",
   new_mobile_phone: "novi broj mobitela",
   confirm_phone: "POTVRDI BROJ",
   buyer_seller: "privatni prodavac",
   car_dealer: "auto salon",
   relevance: "relevantnost",
   random: "nasumično",
   price_l_h: "cijena – prvo najniža",
   price_h_l: "cijena – prvo najviša",
   mileage_l_h: "kilometraža - najniža",
   mileage_h_l: "kilometraža - najviša",
   year_l_h: "godište - najniže",
   year_h_l: "godište - najviše",
   listing_new: "oglas – prvo noviji",
   listing_old: "oglas – prvo stariji",
   seller_location: "lokacija prodavca",
   price_range: "raspon cijena",
   range: "RASPON",
   date_create: "DATUM OBJAVE",
   seller_id: "ID PRODAVCA",
   car_listing_id: "ID OGLASA",
   show: "PRIKAŽI",
   view_profile: "PREGLEDAJ PROFIL",
   SAVE_LISTING: "OGLAS SPREMLJEN",
   SAVE_LIST: "SPREMI OGLAS",
   directions: "NAVIGACIJA",
   mob_num: "BROJ MOBITELA",
   save_seller: "SPREMI PRODAVCA",
   saved_seller: "PRODAVAC SPREMLJEN",
   detail_desc: "DETALJAN OPIS",
   other_listing: "DRUGI OGLASI OD PRODAVCA",
   report_seller: "PRIJAVI KORISNIKA",
   car_dealer_desc: "O NAMA",
   listing_option: "POSTAVKE OGLASA",
   add_edit_credit: "UPRAVLJAJ S KREDITIMA",
   edit_image: "UREDI SLIKE",
   edit_details: "UREDI DETALJE",
   edit_desc: "UREDI OPIS",
   mark_sold: "ZAVRŠI OGLAS",
   profile_option: "POSTAVKE RAČUNA",
   edit_pass: "PROMIJENI LOZINKU",
   edit_phone: "PROMIJENI BROJ",
   edit_address: "PROMIJENI GRAD",
   desc: "OPIS SALONA",
   assign_credit: "DODIJELJENO OGLASA",
   listing_active: "OGLAS JE AKTIVAN",
   listing_inactive: "OGLAS JE NEAKTIVAN",
   please: "molim te",
   otp_error: "Molim te unesi SMS kod!",
   username_error: "Unesi korisničko ime!",
   rotate_potrait: "rotirajte uređaj za bolji prikaz",
   pass_error: "Unesi lozinku!",
   user_type: "Tip korisnika",
   logout_success: "Uspješno ste se odjavili!",
   user: "KORISNIČKI",
   account: "RAČUN",
   listing_save: "OGLAS SPREMLJEN",
   private_seller: "PRIVATNI PRODAVAC",
   used: "POLOVNO",
   location: "LOKACIJA",
   new_used: "STANJE VOZILA",
   from: "od",
   to: "do",
   list: "LISTA",
   submit: "POTVRDI BROJ",
   viber_msg: "VIBER PORUKA",
   call: "TELEFONSKI POZIV",
   see_other_list: "Oglasi od istog prodavca",
   see_all_list: "Vidi sve oglase",
   wishlist_add_msg: "Dodato u spremljene oglase!",
   wishlist_remove_msg: "Uklonjeno iz spremljenih oglasa!",
   dealer_remove_msg: "Uklonjeno iz spremljenih prodavaca!",
   // dealer_add_msg: "Uklonjeno iz spremljenih prodavaca!",
   dealer_add_msg: "Dodato u spremljene prodavce!",
   login_continue_msg: "Molimo da se ulogujete!",
   credit_add_succss: "Kredit je uspješno dodan!",
   credit_remove_succss: "Kredit uspješno umanjen!",
   regSubmit: "POTVRDI BROJ",
   no_data: "nema rezultata pretrage",
   required_error: "Sva polja su obavezna!",
   password_change_success: "Uspješno kreirana nova lozinka!"

}
