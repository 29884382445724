import React, { useEffect, useRef, useState } from 'react'
import Lock from "../../images/Lock.png"
import Forgotlock from "../../images/forgot_password.svg"
import Location from "../../images/Location.png"
import Smartphone from "../../images/105_mobile_phone_ylw.svg"
import Ask_Question from "../../images/Tooltip_Square_Registration.svg"
import Close_indow from "../../images/Close_indow.png"
import Close from "../../images/close_01.svg"
import address from "../../images/address.svg"
import phonpic from "../../images/105_mobile_phone.svg"
import car_pic from "../../images/54_car_images.svg"
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getPostData, getUserData, setMode, setloading } from '../../Redux/ActionCreater'
import { toast } from 'react-toastify'
import axios from 'axios'
import $ from "jquery";
import { BASE_URL, lan } from '../../config'

export default function EditProfile({setData,data,cities,fetchData,setOldimages,oldimages,images,setImages}) {
    const wrapperRef = useRef(null);
    const dispatch = useDispatch();
	const navigate = useNavigate();
    const [code, setCode] = useState("old_phone")
    const [dltimages,setDeltImages]=useState([])
    
    let [formData,setFormData]=useState({
        old_pass:"",
        new_pass:"",
        confirm_pass:"",
        city:"",
        verification_code:"",
        phone:"",
        old_phone:"",
        address:"",
        details:"",
        newimage:[]
    })
    let token = localStorage.getItem("access_token");
    const logOut=()=>{
        // setUserData("");
        dispatch(setMode(""));
        dispatch(getUserData(""));
        localStorage.removeItem("login_data");
        localStorage.removeItem("access_token");
        toast.success(lan.login_continue_msg);
        dispatch(getPostData({preData:[]}));
        navigate("/")
        $(".btnClose").click();
    }
    let onChangeHandle=(e)=>{
        e.preventDefault();
        if (e.target.name === "phone"||e.target.name === "old_phone") {
            var reg = /^[+]?\d+$/;
            if ((reg.test(e.target.value) || e.target.value === ""||e.target.value === "+")&&e.target.value.startsWith("+")) {
                setFormData({ ...formData, [e.target.name]: e.target.value });
            }
        } else {
        setFormData({...formData,[e.target.name]:e.target.value})
        }
    }

    let onSubmitPass=async(e)=>{
        try {
            e.preventDefault();
            if (formData.old_pass === "") {
                return toast.error("Unesite staru lozinku")
            }
            if (formData.new_pass === "") {
                return toast.error("Unesite novu lozinku")
            }
            if (formData.new_pass !== formData.confirm_pass) {
                return toast.error("Potvrdite lozinku i nova lozinka trebaju biti iste.")
            }
            
            await axios({
                url: BASE_URL + "/api/user/change-password",
                method: "POST",
                headers: {
                    authorization: token
                },
                data: {
                    "old_password": formData.old_pass,
                    "new_password": formData.new_pass
                },
        }).then(res => {
			if (res.data.status === 200) {
				// localStorage.setItem('login_data', JSON.stringify(res.data.data));
				// localStorage.setItem('access_token', res.data.token);
				// setUserData(res.data.data)
				// dispatch(getUserData(res.data.data));
				toast.success("Podaci uspješno promijenjeni.");
				$(".btnClose").click();
            setFormData({old_pass:"",
            new_pass:"",
            confirm_pass:"",
            city:"",
            verification_code:"",
            phone:""})
			} else {
				toast.error(res.data.message);
			}
		})} catch (error) {
                console.log(error)
                if(error.response.status===401){
                    logOut()
                  }
        }
    }

    let onSubmitPhone=async()=>{
        try {
            if (formData.verification_code === "") {
                toast.dismiss();
                return toast.error("Unesite verifikacioni kod!")
            }
            await axios({
                url: BASE_URL + "/api/user/update-phone",
                method: "POST",
                headers: {
                    authorization: token
                },
                data: {
                    "verification_code": formData.verification_code,
                    "phone": formData.phone
                },
        }).then(res => {
			if (res.data.status === 200) {
				// localStorage.setItem('login_data', JSON.stringify(res.data.data));
				// localStorage.setItem('access_token', res.data.token);
				// setUserData(res.data.data)
				// dispatch(getUserData(res.data.data));
				toast.success("Podaci uspješno promijenjeni.");
				$(".btnClose").click()
                setFormData({...formData,phone:"",
                    old_phone:"",})
                    setCode("old_phone")
                    // localStorage.setItem('login_data', JSON.stringify({...data,phone:res.data.data.phone}));
                    fetchData()
                    // setData({...data,phone:res.data.data.phone})
			} else {
				toast.error(res.data.message);
			}})

        } catch (error) {
            console.log(error)
        }
    }

    let onSubmitAddress=async()=>{
        try {
            if (data.seller_type==="car_dealer"&&formData.address === "") {
                toast.dismiss();
                return toast.error("Molimo unesite adresu!")
            }
            if (formData.city === "") {
                toast.dismiss();
                return toast.error("Molimo odaberite grad!")
            }
            await axios({
                url: BASE_URL + "/api/user/update-address",
                method: "POST",
                headers: {
                    authorization: token
                },
                data: {
                    "address": formData.address,
                    "city_id": formData.city
                },
        }).then(res => {
			if (res.data.status === 200) {
				toast.success("Podaci uspješno promijenjeni.");
				$(".btnClose").click()
                setFormData({...formData,address:res.data.data.address,city:res.data.data.city_id})
                    // localStorage.setItem('login_data', JSON.stringify({...data,address:res.data.data.address,city_id:res.data.data.city_id}));
                    fetchData()
            // setData({...data,address:res.data.data.address,city_id:res.data.data.city_id})
        }
    })

        } catch (error) {
            console.log(error)
        }
    }

    let onSubmitDetails=async()=>{
        try {
            
            if (formData.details === "") {
                toast.dismiss();
                return toast.error("Please choose a city!")
            }
            await axios({
                url: BASE_URL + "/api/user/update-address",
                method: "POST",
                headers: {
                    authorization: token
                },
                data: {
                   details:formData.details
                },
        }).then(res => {
			if (res.data.status === 200) {
				toast.success("Podaci uspješno promijenjeni.");
				$(".btnClose").click()
                setFormData({...formData,details:res.data.data.details})
                    setCode("old_phone")
            //         localStorage.setItem('login_data', JSON.stringify({...data,details:res.data.data.details}));
            // setData({...data,details:res.data.data.details})
            fetchData()
        }
    })

        } catch (error) {
            console.log(error)
        }
    }


    const phoneVarify = async (e) => {
        e.preventDefault();
        console.log(formData.phone)
        let numReg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
        if (/[a-zA-Z]/.test(formData.phone) ||formData.phone===""||formData.phone.length!==12) {
            return toast.error("Unesite važeći broj mobilnog telefona!")
        }
        try {
            await axios({
                method: "post",
                headers: {
                    authorization: token
                },
                url: `${BASE_URL}/api/user/check/update-phone-number`,
                data: {
                    phone_number: formData.phone
                }
            }).then(res => {
                if (res.data.status === 200) {
                    setCode("newOtp")
                    toast.success("Unesi SMS kod sa novog broja!");
                } else (
                    toast.error(res.data.message)
                )
            })
        } catch (error) {
            console.log(error)
        }
    }

    const oldOtpsubmit=async(e)=>{
        e.preventDefault();
        if (formData.verification_code === "") {
            toast.dismiss();
            return toast.error("Unesite verifikacioni kod!")
        }
        try {
            await axios({
                url: BASE_URL + "/api/user/check-old-phone",
                method: "POST",
                headers: {
                    authorization: token
                },
                data: {
                    "verification_code": formData.verification_code,
                    "old_phone_number": formData.old_phone
                },
        }).then(res => {
			if (res.data.status === 200) {
				toast.success("Unesi novi broj mobitela!");
                    setCode("new_phone")
                    setFormData({...formData,verification_code:""})
			} else {
				toast.error(res.data.message);
			}})
            
        } catch (error) {
            console.log(error)
        }
    }

    const oldPhonesubmit=async(e)=>{
        e.preventDefault();
        if (formData.old_phone==="") {
            toast.dismiss();
            return toast.error("Unesite broj mobilnog telefona!")
        }
        if (/[a-zA-Z]/.test(formData.old_phone) ||formData.old_phone===""||formData.old_phone.length!==12) {
            toast.dismiss();
            return toast.error("Unesite važeći broj mobilnog telefona!")
        }
        try {
            await axios({
                url: BASE_URL + "/api/user/sent-old-phone-otp",
                method: "POST",
                headers: {
                    authorization: token
                },
                data: {
                    "old_phone_number": formData.old_phone
                },
        }).then(res => {
			if (res.data.status === 200) {
				toast.success("Unesi SMS kod sa starog broja!");
                    setCode("oldOtp")
			} else {
				toast.error(res.data.message);
			}})
            
        } catch (error) {
            console.log(error)
        }
    }

    const handleMultipleImages = async(evnt) => {

        setFormData({ ...formData, newimage: evnt.target.files })
        const selectedFIles = [];
        const targetFiles = evnt.target.files;
        const targetFilesObject = [...targetFiles]
        targetFilesObject.map((file) => {

            return selectedFIles.push(URL.createObjectURL(file))
        })
        setImages(selectedFIles);
        let newData = new FormData();
        // let arr = [...formData.newimage];
        newData.append("old_image", JSON.stringify(oldimages))
        newData.append("id", data._id)
        newData.append("delete_image", JSON.stringify(dltimages))
        
     
        if (evnt.target.files.length > 0) {
            for (let i = 0; i < evnt.target.files.length; i++) {
                newData.append("image", evnt.target.files[i])
            }
        }
        await axios({
            url: BASE_URL + "/api/user/update-profile-images",
            method: "POST",
            headers: {
                authorization: token
            },
            data: newData,
        }).then(res => {
        
            if (res.data.status === 200) {
                setImages([])
                setDeltImages([])
                setData(res.data.data);
                setOldimages([...res.data.data.images])
                setFormData({...formData,newimage:[]});
                fetchData();
            } else {
                toast.error(res.data.message)
            }
        })
    }

    let deleteImg=(url)=>{
        let dltimg=dltimages;
        dltimg.push(url)
        setDeltImages(dltimg);
        let arr=oldimages;
        let idx=arr.indexOf(url)
        if(idx>-1){
            arr.splice(idx,1);
            setOldimages([...arr])
        }
    }

    let submitImage=async(e)=>{
        try {
            e.preventDefault();
            
            if (oldimages.length===0&&formData.newimage.length===0) {
                return toast.error("Molimo odaberite sliku!");
            }
            let newData = new FormData();
            // let arr = [...formData.newimage];
            newData.append("old_image", JSON.stringify(oldimages))
            newData.append("id", data._id)
            newData.append("delete_image", JSON.stringify(dltimages))
            
         
                if (formData.newimage.length > 0) {
                    for (let i = 0; i < formData.newimage.length; i++) {
                        newData.append("image", formData.newimage[i])
                    }
                }
             
                await axios({
                    url: BASE_URL + "/api/user/update-profile-images",
                    method: "POST",
                    headers: {
                        authorization: token
                    },
                    data: newData,
                }).then(res => {
                
                    if (res.data.status === 200) {
                        setImages([])
                        setDeltImages([])
                        setData(res.data.data);
                        setFormData({...formData,newimage:[]});
                        fetchData();
                        $(".btnClose").click()
                        toast.success("Podaci uspješno promijenjeni.");
                    } else {
                        toast.error(res.data.message)
                    }
                })
        } catch (error) {
            console.log(error.response.status)
                if(error.response.status===401){
                  logOut()
                }
        }
    }
   
useEffect(()=>{
    setFormData({...formData,phone:"",address:data.address,city:data.city_id,details:data.details?data.details:""})
},[data])

useEffect(()=>{
    setTimeout(() => {
        
        dispatch(setloading(false))
    }, 1000);
    function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            $(".rghttootipe").removeClass("showTooltip");
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
},[])

    return (
        <>
            <div class="modal dashbordModal fade" id="editmodal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                <img src={Close_indow} alt="" />
                                    <img src={Close} alt="" />
                                </button>
                            </div>
                        </div>
                        <div class="modal-body">
                            <div class="modalRegisterpnl">
                                <div class="usermodal registermodal">
                                    <form>
                                        <div class="usermodaltop">
                                            <div class="userLoked">
                                                <img src={Forgotlock} alt="" />
                                            </div>
                                            <div class="frmData">
                                                <i><img src={Lock} alt="" /></i>
                                                <input onFocus={(e)=>{e.target.placeholder=""}} onBlur={(e)=>{if(e.target.value===""){e.target.placeholder="stara lozinka"}}} type="password" onChange={onChangeHandle} value={formData.old_pass} name="old_pass" placeholder="stara lozinka" />
                                            </div>
                                            <div class="frmData">
                                                <i><img src={Lock} alt="" /></i>
                                                <input onFocus={(e)=>{e.target.placeholder=""}} onBlur={(e)=>{if(e.target.value===""){e.target.placeholder=lan.new_pass.toLowerCase()}}} type="password" onChange={onChangeHandle} value={formData.new_pass} name="new_pass" placeholder={lan.new_pass.toLowerCase()} />
                                            </div>
                                            <div class="frmData">
                                                <i><img src={Lock} alt="" /></i>
                                                <input onFocus={(e)=>{e.target.placeholder=""}} onBlur={(e)=>{if(e.target.value===""){e.target.placeholder="potvrdi novu lozinku"}}} type="text" onChange={onChangeHandle} value={formData.confirm_pass} name="confirm_pass" placeholder="potvrdi novu lozinku" />
                                            </div>
                                        </div>
                                        <div class="usermodalbtm">
                                            <a href="" onClick={onSubmitPass} class="registerBtn">PROMIJENI</a>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal dashbordModal fade" id="editmodal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                    <img src={Close_indow} alt="" />
                                    <img src={Close} alt="" />
                                </button>
                            </div>
                        </div>
                        <div class="modal-body">
                            <div class="modalRegisterpnl">
                                <div class="usermodal registermodal">
                                    <form>
                                        <div class="usermodaltop">
                                            <div class="userLoked">
                                                <img src={phonpic} alt="" />
                                            </div>

                                            <div class="frmData">
                                            {(code==="new_phone"||code==="old_phone")&&<i><img src={Smartphone} alt="" /></i>}
                                             { code==="new_phone"&&<input onFocus={(e)=>{if(e.target.value===""){e.target.placeholder="";setFormData({...formData,phone:"+387"})}}} onBlur={(e)=>{if(e.target.value===""||e.target.value==="+387"||e.target.value.length<4){e.target.placeholder=lan.new_mobile_phone;setFormData({...formData,phone:""})}}} type="tel" name="phone" onChange={onChangeHandle} value={formData.phone} placeholder={lan.new_mobile_phone} />}
                                              {code==="old_phone"&&<input onFocus={(e)=>{if(e.target.value===""){e.target.placeholder="";setFormData({...formData,old_phone:"+387"})}}} onBlur={(e)=>{if(e.target.value===""||e.target.value==="+387"||e.target.value.length<4){e.target.placeholder=lan.old_mobile_phone;setFormData({...formData,old_phone:""})}}} type="tel" name="old_phone" onChange={onChangeHandle} value={formData.old_phone} placeholder={lan.old_mobile_phone} />}
                                                {(code==="new_phone"||code==="old_phone")&&<div class="rghttootip rghttootipe" ref={wrapperRef}>
                                            <button class="tooltipIcon" onClick={(e) => { e.preventDefault(); $(".rghttootipe").toggleClass("showTooltip"); }}><img src={Ask_Question} alt="" /></button>
                                            <div class="rghttootipMsg" >
                                                <h4>Ispravan format broja: +38762444331 (bez početne nule i razmaka)!</h4>
                                                <p> Koristite Viber broj zbog jednostavnije komunikacije s potencijalnim kupcima.</p>
                                            </div>
                                        </div>}
                                            </div>
                                            {code==="new_phone"&&<div class="loginBtn">
                                                <input onClick={phoneVarify} type="submit" value={lan.confirm_phone} />
                                            </div>}
                                            {code==="old_phone"&&<div class="loginBtn">
                                                <input onClick={oldPhonesubmit} type="submit" value={lan.confirm_phone} />
                                            </div>}
                                        </div>
                                        {code==="newOtp"&&<div class="usermodalbtm">
                                    <div class="frmData">
                                        <input onFocus={(e)=>{e.target.placeholder=""}} onBlur={(e)=>{if(e.target.value==="")e.target.placeholder=lan.otp_code}} name="verification_code" value={formData.verification_code} onChange={onChangeHandle} type="text" placeholder={lan.otp_code} />
                                    </div>
                                    <a style={{cursor:"pointer"}} onClick={(e) => { e.preventDefault(); onSubmitPhone(e) }} class="registerBtn rgfulbtn">{lan.submit}</a>
                                </div>}
                                {code==="oldOtp"&&<div class="usermodalbtm">
                                    <div class="frmData">
                                        <input onFocus={(e)=>{e.target.placeholder=""}} onBlur={(e)=>{if(e.target.value==="")e.target.placeholder=lan.otp_code}} name="verification_code" value={formData.verification_code} onChange={onChangeHandle} type="text" placeholder={lan.otp_code} />
                                    </div>
                                    <a style={{cursor:"pointer"}} onClick={(e) => { e.preventDefault(); oldOtpsubmit(e) }} class="registerBtn rgfulbtn">{lan.submit}</a>
                                </div>}
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal dashbordModal fade" id="editmodal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                    <img src={Close_indow} alt="" />
                                    <img src={Close} alt="" />
                                </button>
                            </div>
                        </div>
                        <div class="modal-body">
                            <div class="modalRegisterpnl">
                                <div class="usermodal registermodal">
                                    <form>
                                        <div class="usermodaltop">
                                            <div class="userLoked">
                                                <img src={address} alt="" />
                                            </div>
                                            {data.seller_type==="car_dealer"&&<div class="frmData">
                                                <i><img src={Location} alt="" /></i>
                                                <input type="text" placeholder="Address" name="address"  value={formData.address} onChange={onChangeHandle}/>
                                            </div>}
                                            <div class="frmData">
                                                <i><img src={Location} alt="" /></i>
                                                <select name="city" value={formData.city} onChange={onChangeHandle}>
                                            <option value="">{lan.city}</option>
                                            {cities?.length > 0 && cities.map(item => {
                                                return <option value={item._id}>{item.city_name}</option>
                                            })}
                                        </select>
                                            </div>
                                            
                                           
                                        </div>
                                        <div class="usermodalbtm">
                                           
                                            <a href="#url" onClick={onSubmitAddress} class="registerBtn">OK</a>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal dashbordModal fade" id="editmodal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div className='hedrnxtbck'>
                                <button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                    <img src={Close_indow} alt="" />
                                    <img src={Close} alt="" />
                                </button>
                            </div>
                        </div>
                        <div class="modal-body">
                            <div class="modalRegisterpnl">
                                <div class="usermodal registermodal">
                                    <form>
                                        <div class="usermodaltop">
                                            <div class="userLoked">
                                                <img src={address} alt="" />
                                            </div>
                                            <div class="frmData">
                                                <textarea 
                                                onFocus={(e)=>{if(e.target.value===""){e.target.placeholder=""}}} 
                                                onBlur={(e)=>{if(e.target.value===""){e.target.placeholder=lan.desc}}}
                                                name='details' 
                                                value={formData.details} 
                                                onChange={onChangeHandle} 
                                                placeholder={lan.desc}></textarea>
                                            </div>
                                            
                                            
                                           
                                        </div>
                                        <div class="usermodalbtm">
                                           
                                            <a onClick={onSubmitDetails} href="#url" class="registerBtn">UREDI</a>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal dashbordModal ovrfHide fade" id="editmodal5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                        <div className='hedrnxtbck'>
                                <button type="button" class="btnClose" data-bs-dismiss="modal" aria-label="Close">
                                    <img src={Close_indow} alt="" />
                                    <img src={Close} alt="" />
                                </button>
                            </div>
                        </div>
                        <div class="modal-body">
                            <div class="selrtypoup imageUpdateModal mrtop0">
                                <div className="otherdetl-popup allrdiopop">
                                    <div className="text-center">
                                        <figure>
                                            <img src={car_pic} alt />
                                        </figure>
                                        <h4>DODAJ SLIKE SALONA</h4>
                                    </div>

                                    <div className='uplodbtn'>
                                        <form encType="multipart/form-data">
                                            <input accept="image/png, image/jpeg, image/jpeg" onChange={handleMultipleImages} id='original' type='file' multiple />
                                        </form>
                                        <span className='upldlnk'>UČITAJ</span>
                                    </div>
                                </div>

                                {oldimages&&oldimages.length>0&&<div className="row" style={{ margin: "auto", maxWidth: "500px" }}>
                                    {
                                       oldimages.length>0&&oldimages.map((url) => {
                                            return url && (
                                                <div className="col-lg-4 col-6">

                                                    <div className="cardpic oldPic">
                                                        <span class="deletePic" onClick={()=>deleteImg(url)}><img src={Close}/></span>
                                                        <img src={`${BASE_URL}/uploads/user/${url}`} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>}
                                {images&&images.length>0&&<div className="row mt-2" style={{ margin: "auto", maxWidth: "500px" }}>
                                    {
                                        images.map((url) => {
                                            return url && (
                                                <div className="col-lg-4 col-6">
                                                    <div className="cardpic">
                                                        <img src={url} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>}
                                <div class="usermodalbtm">
                                           
                                           <a onClick={submitImage} href="#url" class="registerBtn">ZAVRŠI</a>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
