import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { BASE_URL, lan } from '../config';
import axios from 'axios';
import $ from "jquery";
import Close_indow from "../images/Close_indow.png"
import Close from "../images/close_01.svg"
import Frame from "../images/car_dealer_yellow.svg"
import Add_User_Male2 from "../images/user_registration.svg"
import User from "../images/User.png"
import Lock from "../images/Lock.png"
import Location from "../images/location_city _ylw.svg"
import Smartphone from "../images/105_mobile_phone_ylw.svg"
import Ask_Question from "../images/Tooltip_Square_Registration.svg"
import Account from "../images/private_seleer_yellow.svg"
import Home from "../images/address_black_ylw.svg"
import Multiselect from 'multiselect-react-dropdown'

function Register({ toggleModal, state ,cities}) {
    const [formData, setFormData] = useState({
        userName: "",
        password: "",
        phoneNumber: "",
        role: "",
        user_role: "",
        roleName: "",
        state: "",
        city: "",
        address: "",
        verification_code: "",
        seller_type: ""
    })
    const wrapperRef = useRef(null);
    const [count, setCount] = useState(true)
    const [code, setCode] = useState(false)

    const onchange = (e) => {
        e.preventDefault();
        var reg = /^[+]?\d+$/;

        if (e.target.name === "user_role") {
            if (e.target.value === "seller") {
                setFormData({ ...formData, user_role: "seller", seller_type: "private_seller" });
            } else {
                setFormData({ ...formData, user_role: "buyer", seller_type: "" });
            }
            return
        }
        // if (e.target.name === "state") {
        //     state.map(item => {
        //         if (item._id == e.target.value) {
        //             setCity(item.cityData)
        //             setFormData({ ...formData, [e.target.name]: e.target.value });
        //         }
        //     })
        //     return
        // }
        if (e.target.name === "phoneNumber") {
            if ((reg.test(e.target.value) || e.target.value === ""||e.target.value === "+")&&e.target.value.startsWith("+")) {
                setFormData({ ...formData, [e.target.name]: e.target.value });
            }
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }

    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // let regExp = /^[a-zA-Z0-9.-]+@[a-zA-Z0-9.-]+\.[A-Za-z]+$/;
        let regExp = /^[a-zA-Z0-9.-_]+@[a-zA-Z0-9.-_]+\.[A-Za-z]+$/;
        // let numReg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
        if (formData.userName === "") {
            return toast.error(lan.required_error)

        }
        if (formData.userName === "" || !/^[a-z0-9_\-]+$/.test(formData.userName)) {
            return toast.error("Molimo unesite samo manje pismo i broj u korisničko ime bez razmaka!")

        }
        if (formData.password === "") {
            return toast.error(lan.required_error)

        }


        // if (formData.state === "") {
        //     toast.dismiss();
        //     return toast.error("Please choose a state!")
        // }
        if (formData.city === "") {
            toast.dismiss();
            return toast.error(lan.required_error)
        }
        if (formData.seller_type=== "car_dealer" && formData.address === "") {
            toast.dismiss();
            return toast.error(lan.required_error)
        }
        if (/[a-zA-Z]/.test(formData.phoneNumber)||formData.phoneNumber===""||formData.phoneNumber.length!==12) {
            toast.dismiss();
            return toast.error(lan.required_error)

        }
        if (code === "") {
            toast.dismiss();
            return toast.error(lan.required_error)
        }
        if (formData.verification_code === "") {
            toast.dismiss();
            return toast.error(lan.required_error)
        }



        var config = {
            method: 'post',
            url: `${BASE_URL}/api/user/signup`,
            data: {
                "username": formData.userName,
                "password": formData.password,
                "phone": formData.phoneNumber,
                "address": formData.address,
                // "state_id": formData.state,
                "city_id": formData.city,
                "verification_code": formData.verification_code,
                "user_role": formData.user_role,
                "seller_type": formData.seller_type
            }
        };

        await axios(config)
            .then(function (res) {
                if (res.data.status === 200) {
                    // toggleModalTwo();
                    $(".closeButtonTwo").click();
                    $(".closeButton").click();
                    $(".btnClose").click();
                    // $(".modal-backdrop").hide();
                    setFormData({
                        userName: "",
                        password: "",
                        phoneNumber: "",
                        role: "",
                        user_role: "",
                        roleName: "",
                        state: "",
                        city: "",
                        address: "",
                        verification_code: "",
                        seller_type: ""
                    })
                    toast.success(res.data.message);
                    setCode(false);
                } else {
                    toast.dismiss();
                    toast.error(res.data.message)
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const phoneVarify = async (e) => {
        e.preventDefault();
        let numReg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
        if (formData.userName === "") {
            return toast.error(lan.required_error)

        }
        if (formData.userName === "" || !/^[a-z0-9_\-]+$/.test(formData.userName)) {
            return toast.error("Molimo unesite samo manje pismo i broj u korisničko ime bez razmaka!")

        }
        if (formData.password === "") {
            return toast.error(lan.required_error)

        }
        if (formData.city === "") {
            toast.dismiss();
            return toast.error(lan.required_error)
        }
        if (formData.seller_type=== "car_dealer" && formData.address === "") {
            toast.dismiss();
            return toast.error(lan.required_error)
        }
        if (/[a-zA-Z]/.test(formData.phoneNumber)||formData.phoneNumber===""||formData.phoneNumber.length!==12) {
            toast.dismiss();
            return toast.error(lan.required_error)

        }
        // if (/[a-zA-Z]/.test(formData.phoneNumber) ||formData.phoneNumber===""||formData.phoneNumber==="+381") {
        //     return toast.error("Please enter valid mobile number!")
        // }
        try {
            await axios({
                method: "post",
                url: `${BASE_URL}/api/user/check/phone-number`,
                data: {
                    "username": formData.userName,
                    phone_number: formData.phoneNumber
                }
            }).then(res => {
                if (res.data.status === 200) {
                    setCode(true)
                    toast.success(res.data.message)
                } else (
                    toast.error(res.data.message)
                )
            })
        } catch (error) {
            console.log(error)
        }
    }


    function toggleModalTwo() {
        var modalTwo = document.querySelector(".buyerModalOpen");
        modalTwo.classList.toggle("show-modal2");
    }

    useEffect(() => {

        function windowOnClick(event) {
            var modal = document.querySelector(".modalRegister");
            if (event.target === modal) {
                toggleModal();
            }
        }
        window.addEventListener("click", windowOnClick);
        function windowOnClick1(event) {
            var modalTwo = document.querySelector(".buyerModalOpen");
            if (event.target === modalTwo) {
                toggleModalTwo();
            }
        }
        window.addEventListener("click", windowOnClick1);


      

        $(".selerDelrTopbtm li button").click(function () {
            $("body").addClass("workingFrm");
        });

        $(".closeButtonTwo").click(function () {
            $("body").removeClass("workingFrm");
        });
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                $(".rghttootipr").removeClass("showTooltip");
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };


    }, [])


    return (
        <>
            <div class="modalRegister">
                <div class="modal-content">
                    <span class="closeButton" onClick={toggleModal}>
                        <img src={Close_indow} alt="" />
                        <img src={Close} alt="" />
                    </span>
                    <div class="modalRegisterpnl">
                        <div class="selerDelrBx">
                            {/* <div class="selerDelrTop">
                                <img src={Add_User_Male_gray} alt="" />
                            </div> */}
                            <div class="selerDelrTopbtm">
                                <ul>
                                    <li>
                                        <button class="buyerModal" onClick={() => {
                                            setFormData({
                                                userName: "",
                                                password: "",
                                                phoneNumber: "",
                                                role: "buyer_seller",
                                                user_role: "seller",
                                                roleName: "",
                                                state: "",
                                                city: "",
                                                address: "",
                                                verification_code: "",
                                                seller_type: "private_seller"
                                            }); toggleModalTwo();
                                        }}>
                                            <figure><img src={Account} alt="" /></figure>
                                            <em>{lan.buyer_seller}</em>
                                        </button>
                                    </li>
                                    <li>
                                        <button class="dealerModal" onClick={() => {
                                            setFormData({
                                                userName: "",
                                                password: "",
                                                phoneNumber: "",
                                                roleName: "",
                                                state: "",
                                                city: "",
                                                address: "",
                                                verification_code: "", role: "car_dealer", user_role: "seller", seller_type: "car_dealer"
                                            }); toggleModalTwo()
                                        }}>
                                            <figure><img src={Frame} alt="" /></figure>
                                            <em>{lan.car_dealer}</em>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="buyerModalOpen">
                <div class="modal-content">
                    <span class="closeButtonTwo" onClick={() => { toggleModalTwo() }}>
                        <img src={Close_indow} alt="" />
                        <img src={Close} alt="" />
                    </span>
                    <div class="modalRegisterpnl customRegis">
                        <div class="usermodal registermodal">
                            <form>
                                <div class="usermodaltop">
                                    <div class="userLoked">
                                        <img src={Add_User_Male2} alt="" />
                                    </div>
                                    <div class="frmData">
                                        <i><img src={User} alt="" /></i>
                                        <input onFocus={(e)=>{e.target.placeholder=""}} onBlur={(e)=>{if(e.target.value===""){e.target.placeholder=lan.username;console.log("first")}}} name="userName" value={formData.userName} onChange={onchange} type="text" placeholder={lan.username} />
                                    </div>
                                    <div class="frmData">
                                        <i><img src={Lock} alt="" /></i>
                                        <input onFocus={(e)=>{e.target.placeholder=""}} onBlur={(e)=>{if(e.target.value===""){e.target.placeholder=lan.password}}} name="password" value={formData.password} onChange={onchange} type="password" placeholder={lan.password} />
                                    </div>
                                    {/* <div class="frmData">
                                        <i><img src={Location} alt="" /></i>
                                        <select name="state" value={formData.state} onChange={onchange}>
                                            <option value={""}>state</option>
                                            {state?.length > 0 && state.map(item => {
                                                return <option value={item._id}>{item.state_name}</option>
                                            })}
                                            <option>option</option>
                                            <option>option</option>
                                            <option>option</option>
                                        </select>
                                    </div> */}
                                    <div class="frmData">
                                        <i style={{zIndex:9}}><img src={Location} alt="" /></i>
                                        <Multiselect
                                                                className="deskSelect reg"
                                                            displayValue="city_name"
                                                            // ref={multiselectRef}
                                                            showArrow={true}
                                                            avoidHighlightFirstOption={true}
                                                            style={{
                                                                chips: {
                                                                    background: "transparent",
                                                                    fontSize: "27px",
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    color: "#989898"
                                                                },
                                                                searchBox: {
                                                                    padding:"0 !important",
                                                                    justifyContent: "center",
                                                                    color: "#989898"
                                                                },
                                                                inputField: {
                                                                    padding:0,
                                                                    textAlign: "center",
                                                                    fontSize: "27px",

                                                                },
                                                                option: {
                                                                    textAlign: "center",
                                                                    fontSize: "22px",

                                                                }
                                                            }
                                                            }
                                                            singleSelect={true}
                                                            // customArrow={<img src={drp} />}
                                                            // onSearch={function noRefCheck(){console.log("sear")}}
                                                            onSelect={function noRefCheck(selectedList, selectedItem) {
                                                                setFormData({ ...formData, city: selectedItem._id });
                                                            }}
                                                            placeholder={lan.city}
                                                            options={cities}
                                                        />
                                        <select className='mobileSelect' name="city" value={formData.city} onChange={onchange}>
                                            <option value="">{lan.city}</option>
                                            {cities?.length > 0 && cities.map(item => {
                                                return <option value={item._id}>{item.city_name}</option>
                                            })}
                                        </select>
                                    </div>
                                    {/* {formData.role === "buyer_seller" && <div class="frmData">
                                        <i><img src={User} alt="" /></i>
                                        <select name="user_role" value={formData.user_role} onChange={onchange}>
                                            <option value="">{lan.user_type}</option>
                                            <option value="seller">Private Seller</option>
                                            <option value="buyer">buyer</option>
                                        </select>
                                    </div>} */}
                                    {formData.seller_type === "car_dealer" && <div class="frmData">
                                        <i><img src={Home} alt="" /></i>
                                        <input onFocus={(e)=>{e.target.placeholder=""}} onBlur={(e)=>{if(e.target.value==="")e.target.placeholder=lan.address}} name="address" value={formData.address} onChange={onchange} type="text" placeholder={lan.address} />
                                    </div>}
                                    <div class="frmData">
                                        <i><img src={Smartphone} alt="" /></i>
                                        <input 
                                        onFocus={(e)=>{if(e.target.value===""){e.target.placeholder="";setFormData({...formData,phoneNumber:"+387"})}}} 
                                        onBlur={(e)=>{if(e.target.value===""||e.target.value==="+387"||e.target.value.length<4){e.target.placeholder=lan.mobile_phone;setFormData({...formData,phoneNumber:""})}}}
                                        type="tel" 
                                        name="phoneNumber" 
                                        onChange={onchange} 
                                        value={formData.phoneNumber} 
                                        placeholder={lan.mobile_phone} />
                                        <div class="rghttootip rghttootipr" ref={wrapperRef}>
                                            <button class="tooltipIcon" onClick={(e) => { e.preventDefault(); $(".rghttootipr").toggleClass("showTooltip"); }}><img src={Ask_Question} alt="" /></button>
                                            <div class="rghttootipMsg" >
                                                <h4>Ispravan format broja: +38762444331 (bez početne nule i razmaka)!</h4>
                                                <p> Koristite Viber broj zbog jednostavnije komunikacije s potencijalnim kupcima.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {!code&&<div class="loginBtn">
                                        <input type="submit"  value={lan.regSubmit} onClick={(e) => { e.preventDefault(); phoneVarify(e) }}  />
                                    </div>}
                                </div>
                                {code&&<div class="usermodalbtm">
                                    {/* {code && <div class="frmData">
                                        {code}
                                    </div>} */}
                                    <div class="frmData">
                                        <input onFocus={(e)=>{e.target.placeholder=""}} onBlur={(e)=>{if(e.target.value==="")e.target.placeholder=lan.otp_code}} name="verification_code" value={formData.verification_code} onChange={onchange} type="text" placeholder={lan.otp_code} />
                                    </div>
                                    <a style={{cursor:"pointer"}} onClick={(e) => { e.preventDefault(); handleSubmit(e) }} class="registerBtn rgfulbtn">{lan.register}</a>
                                </div>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register