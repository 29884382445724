import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'
import Arrow_Pointing_Left from "../../images/desktop_back.svg"
import { BASE_URL, lan } from '../../config';
import { useNavigate } from 'react-router-dom';
import rlok from "../../images/forgot_password_black.svg"
import carDelarRghtLst4 from "../../images/105_mobile_phone_black.svg"
import iglry from "../../images/gallery.svg"
import carDelarRghtLst1 from "../../images/location_city _black.svg"
import pencil from "../../images/Pencil.svg"
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import colorHeert from "../../images/heart_y.svg"
import userSmall from "../../images/private_seller_grey.svg"
import smallDealer from "../../images/car_dealer_grey.svg"
import grayHeart from "../../images/heart_grey.svg"
import yr from "../../images/yr.svg"
import dsl from "../../images/dsl.svg"
import milg from "../../images/milg.svg"
import auto from "../../images/auto.svg"
import lcn from "../../images/lcn.svg"
import { setPreProduct } from '../../Redux/ActionCreater';
import { toast } from 'react-toastify';
import axios from 'axios';

function MainContent({data,setSellerPost, sellerPost,addata,icon,fetchCities,setOldimages,activePost,soldPost}) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let [idx,setIdx]=useState(0);
	let [mode,setMode]=useState("active")
	const { preProduct } = useSelector(
		(state) => ({
		  preProduct: state.dataReducer.preProduct,
		}),
		shallowEqual
	  );

    const options = {
        loop: true,
        margin: 0,
        items: 1,
        autoplay: true,
        autoplaySpeed:1200,
        rewind: false,
        lazyLoad:true,
        // navRewind: true,
              autoplayTimeout:6000,
              navSpeed: 2000,
              dragEndSpeed: 2000,
              dots: true,
              nav: false
      };


	  const clickProduct = (e, item) => {
		e.preventDefault();
		let arr = preProduct
		arr.push(`/${data.username}`)
		dispatch(setPreProduct(arr));
		// navigate(`/${data.username}/${item.list_id}/${item.make.make_name}-${item.model.model_name}`)
		if(item.make){
			navigate(`/${data.username}/${item.list_id}/${item.make.make_name}-${item.model.model_name}`)
		  }else{
			navigate(`/${data.username}/${item.list_id}/${item.name.replaceAll(" ","-")}`)
		  }
		window.scrollTo({ top: 0, behavior: 'smooth' });
		
												
	  }

	const wishlistClick = async (e, item) => {
		e.preventDefault();
		let token = localStorage.getItem("access_token");
		if (token == null || token == "") {
			toast.dismiss();
			return toast.error(lan.login_continue_msg)
		}
		try {
			await axios({
				url: BASE_URL + "/api/wishlist/add-remove",
				method: "POST",
				headers: {
					authorization: token
				},
				data: {
					"post_id": item._id,
					"is_favourite": !item.wishlistData.is_favourite,

				},
			}).then(res => {
				if (res.data.status === 200) {
					sellerPost.map((i,idx)=>{
						if(i._id===item._id){
							let arr=sellerPost
							arr[idx].wishlistData.is_favourite=!item.wishlistData.is_favourite
							setSellerPost([...arr])
						}
					})
					item.wishlistData.is_favourite ? toast.success("Product added to your wishlist") :
						toast.success("Product removed from wishlist");
				} else {
					toast.error(res.data.message);
				}
			})
		} catch (error) {
			console.log(error)
		}
	}

	let gotolink=(url)=>{
		url&&window.open(url, "_blank", "noreferrer");
	  }

	  useEffect(()=>{
		if(addata.length>0){
		  let idx=Math.floor(Math.random() * addata.length)
		  setIdx(idx)
		}
	  },[addata])

  return (

    <>
    
    <div class="listingHed CAR_DEALER">
	<div class="container">
		<div class="listingHedinr">
			<a href="/" onClick={(e)=>{e.preventDefault();navigate("/")}}><img src={Arrow_Pointing_Left} alt="" /></a>
			<div>
				<h1>{data?.username}<em>
					<img src={`${BASE_URL}/uploads/seller-icon/${icon.icon}`} alt="" /></em></h1>
				<strong>{data?.seller_type=="private_seller"?lan.private_seller:lan.car_dealer.toUpperCase()}</strong>
			</div>
		</div>
	</div>
</div>
<div class="listingDtils CAR_DEALERSldrSec">
	<div class="container" >
			{/* <h4>Mobile Ad</h4> */}
	{data?.seller_type === "private_seller" &&<div class="CAR_DEALERSldrads" onClick={()=>gotolink(addata[idx].link)}>
			{addata&&addata[0]?.image&&<img src={`${BASE_URL}/uploads/banner/${addata[idx]?.mobile_image}`} alt="" />}
		</div>}
		{/* <div class="ctrgyList">
			<ul>
				<li>
					<i><img src={Location2} alt="" /></i>
					<em>CITY</em>
				</li>
				<li>
					<i><img src={Heart3} alt="" /></i>
					<em>SAVE SELLER</em>
				</li>
			</ul>
		</div> */}
		{data?.seller_type&&data?.seller_type !== "private_seller" &&
		<div class="row">
			<div class="col-lg-8">
				<div class="listingDtilslft">
					<div class="listingDtilslftSldr">
                    {data&&data.images&&data.images.length>0?<OwlCarousel class="owl-carousel owl-theme" {...options} loop  nav>
					{data.images&&data.images.length>0?data.images.map(item=>{
return <div class="item" >
<div class="carDetilsImge">
	<img src={`${BASE_URL}/uploads/user/${item}`}/>
</div>
</div>
						}):
						<div class="item" >
						<div class="carDetilsImge" style={{minHeight:'400px'}}>
							<h4>NEMA SLIKA</h4>
						</div>
					</div>
					}
							
						</OwlCarousel>:null}
					</div>
				</div>
			</div>
			<div class="col-lg-4">
				<div class="listingDtilsRt">					
					<div class="detailedDescrip">
						<h4><em> O NAMA</em></h4>
						<p>{data.details}</p>
					</div>
				</div>
			</div>
		</div>}

		{data?.seller_type === "private_seller" && <div class="desktopAds">
						<div class="container" style={{padding:"0px"}}>
							<div class="desktopAdspnl" style={{marginTop: "20px" }} onClick={()=>gotolink(addata[idx].link)}>
								{/* <h1>Desktop Ad</h1> */}
								{addata&&addata[idx]?.image&&<img src={`${BASE_URL}/uploads/banner/${addata[idx]?.image}`} alt="" />}
							</div>
						</div>
					</div>}

					{data?.seller_type!=="private_seller"&&<div class="ctrgyList mblprfdl">
			<ul>
				{data?.seller_type!=="private_seller"&&<li data-bs-toggle="modal" data-bs-target="#editmodal1">
					<i><img src={rlok} alt="" /></i>
					<em>{lan.edit_pass}</em>
				</li>}
				<li data-bs-toggle="modal" data-bs-target="#editmodal2" >
					<i><img src={carDelarRghtLst4} alt="" /></i>
					<em>{lan.edit_phone}</em>
				</li>
			</ul>
			
		</div>}
		{data?.seller_type!=="private_seller"&&<div class="ctrgyList mblprfdl mblprfdl">
			<ul>
				<li data-bs-toggle="modal" data-bs-target="#editmodal3" onClick={fetchCities}>
					<i><img src={carDelarRghtLst1} alt="" /></i>
					<em>{lan.edit_address}</em>
				</li>
				{data?.seller_type!=="private_seller"&&<li data-bs-toggle="modal" data-bs-target="#editmodal4">
					<i><img src={pencil} alt="" /></i>
					<em>{lan.desc}</em>
				</li>}
				
			</ul></div>}
			{data?.seller_type!=="private_seller"&&<div class="ctrgyList mblprfdl">
			<ul>
			{data?.seller_type!=="private_seller"&&<li data-bs-toggle="modal" data-bs-target="#editmodal5" onClick={()=>data.images&&setOldimages(data.images)}>
					<i><img src={iglry} alt="" /></i>
					<em>SLIKE SALONA</em>
				</li>}
			</ul></div>}

			{data?.seller_type==="private_seller"&&<div class="ctrgyList mblprfdl">
			<ul>
			<li data-bs-toggle="modal" data-bs-target="#editmodal3" onClick={fetchCities}>
					<i><img src={carDelarRghtLst1} alt="" /></i>
					<em>{lan.edit_address}</em>
				</li>
				<li data-bs-toggle="modal" data-bs-target="#editmodal2" >
					<i><img src={carDelarRghtLst4} alt="" /></i>
					<em>{lan.edit_phone}</em>
				</li>
			</ul></div>}
			{data?.seller_type==="private_seller"&&<div class="ctrgyList mblprfdl">
			<ul>
			<li data-bs-toggle="modal" data-bs-target="#editmodal1">
					<i><img src={rlok} alt="" /></i>
					<em>{lan.edit_pass}</em>
				</li>
			</ul></div>}

			<div class="row activeListing">
						<div class="col-lg-8 col-md-6 col-6">
							<div style={{background:mode==="active"?"#202629":"#fff"}} class="ACTIVE_LISTINGS" onClick={()=>{setMode("active");setSellerPost(activePost)}}>
								<h3 style={{color:mode==="active"?"#fff":"#989898"}}>{lan.active_listings}</h3>
								{/* <i><img src={FilledCircle} alt="" /></i> */}
							</div>
						</div>
						<div class="col-lg-4 col-md-6 col-6">
							<div style={{background:mode==="finished"?"#202629":"#fff"}} class="selerListinghed" onClick={()=>{setMode("finished");setSellerPost(soldPost)}}>
								<h3 style={{color:mode==="finished"?"#fff":"#989898"}}>{lan.finish_listings}</h3>
							</div>
						</div>
			</div>
			<div className="productSec list-view-filter">
				<div className="container" style={{padding:0}}>
					<div className="row">
						{sellerPost && sellerPost.length > 0 && sellerPost.map((item) => {
							return <div className="col-lg-3 col-md-6 col-sm-6">
								<div className="productbx">
									<div className="productbxtop">
										<div className="productbxtopimg">
											<a href="#url" className="userSmall"><img src={item.user?.seller_type==="private_seller"?userSmall:smallDealer} alt /></a>
											{item.image && JSON.parse(item.image).length > 0 && 
											<img 
											onClick={(e) => {
												clickProduct(e,item)
											 }} 
												src={`${BASE_URL}/uploads/post/${JSON.parse(item.image)[0]}`} 
											alt="" />}
											<a href="" onClick={(e) => {wishlistClick(e,item) }} class="grayHeart">{item.wishlistData?.is_favourite ? <img src={colorHeert} alt="" /> : <img src={grayHeart} alt="" />}</a>
										</div>
										<div className="productbxtoprtlst">
											<ul>
											<li><span><img src={yr}/></span> {item.year}</li>
												<li><span><img src={dsl}/></span>{item.fuel?item.fuel:"BENZIN"}</li>
												<li><span><img src={milg}/></span>{item.mileage}</li>
												<li className='trnmbl'><span><img src={auto}/></span>{item.transmission}</li>
												<li><span><img src={lcn}/></span>{item.location?.city_name}</li>
											</ul>
										</div>
									</div>
									<div class="productbxbtm" onClick={(e) => { clickProduct(e,item) }}>
										<h3>{item.name}</h3>
										<strong><span>KM</span> {item.price}</strong>
									</div>
								</div>
							</div>
						})}
					</div>
				</div>
			</div>
	</div>
</div>
    </>
  )
}

export default MainContent