import React from 'react'
import CloseTwo from "../../images/close_01_yellow.svg"
import Frame from "../../images/car_dealer_yellow.svg"
import carDelarRghtLst1 from "../../images/location_city _ylw.svg"
import carDelarRghtLst2 from "../../images/address_black_ylw.svg"
import carDelarRghtLst3 from "../../images/104_directions_ylw.svg"
import carDelarRghtLst4 from "../../images/105_mobile_phone_ylw.svg"
import carDelarRghtLst5 from "../../images/heart_y.svg"
import Account from "../../images/private_seleer_yellow.svg"
import Heart from "../../images/heart_trans.svg"
import {  lan } from '../../config'

function SideRight({data,saveDealerData,saveSeller}) {
  return (
    <div class="filterRght carDelarRght" >
	<a href="#url" class="closeMnuTwo"><img src={CloseTwo} alt="" /></a>
	<div class="filterRghtTop" >
		<div class="media">
			<figure>
				<img src={data?.seller_type=="private_seller"?Account:Frame} alt="" />
			</figure>
			<div class="media-object">
				<h3>{data.username?data.username:data.full_name}</h3>
			</div>
		</div>
	</div>
	<div class="carDelarRghtLst" style={{border:"none"}}>
		<ul>
			<li >
				<i><img src={carDelarRghtLst1} alt="" /></i>
				{/* <span>{lan.city}</span> */}
				<span>{data?.city_id?.city_name}</span>
				{/* <em>{data?.city_id?.city_name}</em> */}
			</li>
			{data&&data?.seller_type!=="private_seller"&&<li >
				<i><img src={carDelarRghtLst2} alt="" /></i>
				{/* <span>{lan.address}</span> */}
				<span>{data?.address}</span>
				{/* <em>{data?.address}</em> */}
			</li>}
			{data&&data?.seller_type!=="private_seller"&&<li >
				<a href={data?.direction_link} target="_blank"><i><img src={carDelarRghtLst3} alt="" /></i>
				<span>{lan.directions}</span></a>
			</li>}
			<li class="hverShow">
				<i><img src={carDelarRghtLst4} alt="" /></i>
				<span>{lan.mob_num}</span>
				<em>{data?.phone}</em>
			</li>
			<li  onClick={saveSeller}>
				{/* <i><img src={saveDealerData?.is_favourite ? carDelarRghtLst5:grayHeart} alt="" /></i> */}
				<i><img src={saveDealerData?.is_favourite ? carDelarRghtLst5:Heart} alt="" /></i>
				<span>{saveDealerData?.is_favourite?lan.saved_seller:lan.save_seller}</span>
			</li>
		</ul>
	</div>
	{/* <div class="carDelarRghtBtmMsg">
		<span>{lan.report_seller}</span>
	</div> */}
</div>
  )
}

export default SideRight